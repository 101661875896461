import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import axiosClient from "../../../axios-client";

ChartJS.register(ArcElement, Tooltip, Legend);

const AllCategoriesChart = ({ filters }) => {
  const [chartData, setChartData] = useState([]);

  const categoriesColors = [
    {
      backgroundColor: "rgba(56, 44, 129, .5)",
      borderColor: "rgba(56, 44, 129, 1)",
    },
    {
      backgroundColor: "rgba(237, 41, 133, .5)",
      borderColor: "rgba(237, 41, 133, 1)",
    },
    {
      backgroundColor: "rgba(234, 179, 8, .5)",
      borderColor: "rgba(234, 179, 8, 1)",
    },
    {
      backgroundColor: "rgba(140, 62, 173, .5)",
      borderColor: "rgba(140, 62, 173, 1)",
    },
    {
      backgroundColor: "rgba(255, 140, 0, .5)",
      borderColor: "rgba(255, 140, 0, 1)",
    },
    {
      backgroundColor: "rgba(0, 191, 255, .5)",
      borderColor: "rgba(0, 191, 255, 1)",
    },
    {
      backgroundColor: "rgba(255, 0, 0, .5)",
      borderColor: "rgba(255, 0, 0, 1)",
    },
    {
      backgroundColor: "rgba(240, 161, 191, .5)",
      borderColor: "rgba(240, 161, 191, 1)",
    },
    {
      backgroundColor: "rgba(89, 194, 130, .5)",
      borderColor: "rgba(89, 194, 130, 1)",
    },
  ]

  const data = {
    labels: chartData.map((category) => category.label),
    datasets: [
      {
        label: "Prix de vente",
        data: chartData.map((category) => {
          return category.totalPrixVente;
        }),
        backgroundColor: chartData.map((category) => category.backgroundColor),
        borderColor: chartData.map((category) => category.borderColor),
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 2.5,
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 24,
      }
    },
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          font: {
            family: "Montserrat, sans-serif",
          },
          usePointStyle: true,
          pointStyle: "circle",
          padding: 20,
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.dataset.label} : ${context.formattedValue}€`;
          },
        },
      },
    },
  };

  useEffect(() => {
    // Récupère les catégories en fonction des filtres quand ceux-ci sont mis à jour
    const getChartData = () => {
      axiosClient
        .get(`/administration/graphiques/allcategories`, { params: filters })
        .then((res) => {
          setChartData(() => {
            const newChartData = res.data.categories.map((category, i) => {
              const totalPrixVente = category.projects.reduce(
                (acc, projet) =>
                  parseFloat(acc) + parseFloat(projet.sell_price),
                0
              );
              if (totalPrixVente > 0) {
                category.totalPrixVente = totalPrixVente;
              }

              category.backgroundColor = 
                categoriesColors[i].backgroundColor;
              category.borderColor =
                categoriesColors[i].borderColor;

              return category;
            });

            return newChartData.filter(
              (category) => category?.totalPrixVente > 0
            );
          });
        });
    };

    getChartData();
  }, [filters]);

  return (
    <div className="w-[40%] bg-white py-6 border rounded-lg border-gray-150">
      <h2 className="text-center">Prix de vente par catégorie</h2>
      <div>
        <Doughnut data={data} width="300px" height="300px" options={options} />
      </div>
    </div>
  );
};

export default AllCategoriesChart;
