import React, { useContext, useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import Table from "../../components/Table/Table";
import ActionEmployes from "./ActionEmployes";
import Dropdown from "../../components/Dropdown/Dropdown";
import DropdownListCheckbox from "../../components/Dropdown/DropdownListCheckbox";
import { NotificationContext } from "../../contexts/NotificationContext";

const Employes = () => {
  const { addNotification } = useContext(NotificationContext);

  const [employes, setEmployes] = useState([]);
  const [employeData, setEmployeData] = useState(false);

  // Status popup modal ajout
  const [showAddModal, setShowAddModal] = useState(false);

  // Option d'affichage des employé selectionné
  const [crtFilterEmployes, setCrtFilterEmployes] = useState("show_all");

  const displayEmployes = async (options) => {
    await axiosClient
      .get(`/employes${options ? `/filter/${options}` : ""}`, {params:{order: 'asc'}})
      .then((res) => {
        res.data.employes.map((employe) => {
          const isActive = parseInt(employe.active);

          employe.visibleAction = isActive ?
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 hover:text-rose-500 cursor-pointer" onClick={() => handleChangeActiveEmployee(employe.id, isActive)}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
            :
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 hover:text-rose-500 cursor-pointer" onClick={() => handleChangeActiveEmployee(employe.id, !isActive)}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
            </svg>

         
        })
        setEmployes(res.data.employes);
      });
  };

  const handleChangeActiveEmployee = async (id, active) => {
    await axiosClient.post(`/employes/${id}/active`, {active: !parseInt(active)}).then((res) => {
      handleAfterSubmitForm({success: res.data.success, message: res.data.message})
    });
  }

  // Modifie un employé
  const handleEdit = async (id) => {
    await axiosClient.get(`/employes/${id}`).then((res) => {
      setEmployeData(res.data.employe);
      setShowAddModal(true);
    });
  };

  useEffect(() => {
    displayEmployes();
  }, []);

  const handleAfterSubmitForm = ({success, message}) => {
    if(!success) addNotification({message: message.errorMsg, list: message.errorList}, 'danger')

    if(success){
      setShowAddModal(false);
      addNotification(message, 'success')
      displayEmployes();
    }
  }

  // Filtre affichage employé actif/inactif
  const onChangeFiltreEmploye = async (event) => {
    const value = event.target.value;
    setCrtFilterEmployes(value);
    if (event.target.value === "show_all") return displayEmployes();
    displayEmployes(value);
  };

  return (
    <div>
      {/* Bouton ajouter un employé */}
      <button
        className={`inline-flex items-center text-white focus:outline-none focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 ml-auto bg-darkblue-600 hover:bg-darkblue-700 focus:ring-darkblue-700 border-darkblue-700`}
        onClick={() => {
          setShowAddModal(true);
          setEmployeData(false);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 mr-2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        Ajouter un employé
      </button>

      {/* Modal pour ajouter/éditer un employé */}
      <ActionEmployes
        showAddModal={showAddModal}
        setShowAddModal={setShowAddModal}
        handleAfterSubmitForm={handleAfterSubmitForm}
        employe={employeData ? employeData : false}
      />

      <div className="mt-4 relative">
        <Table
          mockData={employes}
          header={[
            {
              Header: "Nom",
              accessor: "last_name",
            },
            {
              Header: "Prenom",
              accessor: "first_name",
            },
            {
              Header: "Salaire (par heure)",
              accessor: "hourly_price",
            },
            {
              Header: "Actif/Inactif",
              accessor: "visibleAction"
            }
          ]}
          action={true}
          afficherSupprimer={true}
          apiUrl="employes"
          afficherEditer={true}
          editer={handleEdit}
          search={true}
          handleAfterSubmitForm={handleAfterSubmitForm}
        >
          <Dropdown
            buttonTitle="Afficher"
            buttonClass="flex items-center text-white focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 ml-auto bg-rose-600 hover:bg-rose-700 border-rose-700"
            menuClass="w-64 right-0"
            className="ml-auto"
          >
            <DropdownListCheckbox
              label="Tous les employés"
              value="show_all"
              name="display_employes"
              type="radio"
              onChange={onChangeFiltreEmploye}
              checked={crtFilterEmployes === "show_all"}
            />
            <DropdownListCheckbox
              label="Les employés actifs"
              value="show_actif"
              name="display_employes"
              type="radio"
              onChange={onChangeFiltreEmploye}
              checked={crtFilterEmployes === "show_actif"}
            />
            <DropdownListCheckbox
              label="Les employés désactivés"
              value="show_inactive"
              name="display_employes"
              type="radio"
              onChange={onChangeFiltreEmploye}
              checked={crtFilterEmployes === "show_inactive"}
            />
          </Dropdown>
        </Table>
      </div>
    </div>
  );
};

export default Employes;
