import React, { useContext, useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import Table from "../../components/Table/Table";
import ActionPermissions from "./ActionPermissions";
import { NotificationContext } from "../../contexts/NotificationContext";

const Permissions = () => {
  const { addNotification } = useContext(NotificationContext);
  const [permissions, setPermissions] = useState([]);
  const [permissionData, setPermissionData] = useState(false);

  // Status popup modal ajout
  const [showAddModal, setShowAddModal] = useState(false);

  const displayPermissions = async () => {
    await axiosClient.get("/permissions").then((res) => {
      setPermissions(res.data.permissions);
    });
  };

  // Modifie une permission
  const handleEdit = async (id) => {
    await axiosClient.get(`/permissions/${id}`).then((res) => {
      setPermissionData(res.data.permission);
      displayPermissions();
      setShowAddModal(true);
    });
  };

  useEffect(() => {
    displayPermissions();
  }, []);

  const handleAfterSubmitForm = ({success, message}) => {
    if(!success) addNotification({message: message.errorMsg, list: message.errorList}, 'danger')

    if(success){
      setShowAddModal(false);
      addNotification(message, 'success')
      displayPermissions();
    }
  }

  return (
    <div>
      {/* Bouton ajouter une permission */}
      <button
        className={`inline-flex items-center text-white focus:outline-none focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 ml-auto bg-darkblue-600 hover:bg-darkblue-700 focus:ring-darkblue-700 border-darkblue-700`}
        onClick={() => {setShowAddModal(true); setPermissionData(false)}}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 mr-2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        Ajouter une permission
      </button>

      {/* Modal pour ajouter/éditer une permission */}
      <ActionPermissions
        showAddModal={showAddModal}
        setShowAddModal={setShowAddModal}
        permission={(permissionData) ? permissionData : false}
        handleAfterSubmitForm={handleAfterSubmitForm}
      />

      <div className="mt-4">
        <Table
          mockData={permissions}
          header={[
            {
              Header: "Nom",
              accessor: "name",
            },
          ]}
          action={true}
          afficherSupprimer={true}
          apiUrl="permissions"
          afficherEditer={true}
          editer={handleEdit}
          handleAfterSubmitForm={handleAfterSubmitForm}
        />
      </div>
    </div>
  );
};

export default Permissions;
