import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Legend,
  BarElement,
  LineController,
} from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from "react-chartjs-2";
import {
  getMonthBeforeEndOfYear,
  getYearDiff,
  getYearOfFormatedDate,
} from "../../../components/Utils/Dates";
import axiosClient from "../../../axios-client";
import { numberWithSpaces } from "../../../components/Utils/CalculROI";
import Dropdown from "../../../components/Dropdown/Dropdown";
import DropdownListCheckbox from "../../../components/Dropdown/DropdownListCheckbox";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  LineController,
  Legend,
);

const CAChart = ({ filters }) => {
  const [monthlyObjective, setMonthlyObjective] = useState(0);
  const [chartDatasets, setChartDatasets] = useState([]);
  const [showMonthlyObjective, setShowMonthlyObjective] = useState(false);

  const chartLineColors = [
    "#382c81",
    "#ED2985",
    "#239E5A",
    "#8C3EAD",
    "#FF8C00",
    "#00BFFF",
    "#FF0000",
    "#40E0D0",
    "#808000",
    "#6D071A",
  ];

  const skipped = (ctx, value, previIndex) => {
    return previIndex.includes(ctx.p1DataIndex + 1) ? value : undefined;
  };

  const options = {
    responsive: true,
    aspectRatio: 2.5,
    radius: 0,
    layout: {
      padding: {
        left: 24,
        right: 24,
      },
    },
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            family: "Montserrat, sans-serif",
          },
          usePointStyle: true,
          pointStyle: "line",
        },
      },
      monthlyObjectiveLine: {
        linePosition: monthlyObjective,
        show: showMonthlyObjective
      },
      datalabels: {
        font: {
          family: "Montserrat, sans-serif",
        },
        display: (ctx) => {
          // Affiche les valeurs si il y'a moins de 3 lignes
          return ctx.chart.data.datasets.length > 3 ? false : true
        },
        clamp: true,
        // align: 'top',
        align: function(ctx) {
          var idx = ctx.dataIndex;
          var val = ctx.dataset.data[idx];
          var datasets = ctx.chart.data.datasets;
          var min, max, i, ilen, ival;
      
          min = max = val;
      
          for (i = 0, ilen = datasets.length; i < ilen; ++i) {
              if (i === ctx.datasetIndex) {
                  continue;
              }
      
              ival = datasets[i].data[idx];
              min = Math.min(min, ival);
              max = Math.max(max, ival);

              if (val > min && val < max) {
                  return 'center';
              }
          }
      
          return val <= min ? 'start' : 'end';
        },
        offset: 5,
        backgroundColor: 'rgba(255, 255, 255, .75)',
        formatter: (value, ctx) => {
          return numberWithSpaces(Math.round(value / 100) * 100)
        }
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          beginAtZero: false,
          fontSize: 8,
          font: {
            family: "Montserrat, sans-serif",
          },
        },
      },
      y: {
        min: () => {
          const minValue = Math.min(...chartDatasets.map((chartDataset) => Math.min(...chartDataset.data))) - 10000;
          return minValue < 0 ? 0 : Math.round(minValue / 10000) * 10000;
        },
        ticks: {
          beginAtZero: false,
          fontSize: 8,
          stepSize: 10000,
          font: {
            family: "Montserrat, sans-serif",
          },
        },
      },
    },
    datasets: {
      line: {
        pointLabelFontSize: 4,
        borderWidth: 2,
        fill: false,
        // tension: .3,
        borderDashOffset: 0.0,
        borderJoinStyle: "bevel",
        pointBorderWidth: 1,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 2,
        pointHoverBackgroundColor: "rgba(220,220,220,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointRadius: 4,
        pointHitRadius: 10,
        spanGaps: false,
      },
    },
  };

  const labels = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  const data = {
    labels,
    datasets: chartDatasets,
  };

  const monthlyObjectiveLine = {
    id: "monthlyObjectiveLine",
    beforeDatasetsDraw: (chart, args, plugins) => {
      const {
        ctx,
        scales: { x, y },
        chartArea: { left, right },
      } = chart;
      ctx.save();

      const drawLine = (thickness, color, yCoor) => {
        ctx.beginPath();
        ctx.strokeStyle = color;
        ctx.lineWidth = thickness;

        ctx.moveTo(left, y.getPixelForValue(yCoor));
        ctx.lineTo(right, y.getPixelForValue(yCoor));
        ctx.stroke();
      };

      if(plugins.show)
        drawLine(5, "#EAB308", plugins.linePosition);
    },
  };


  useEffect(() => {
    const getChartData = () => {
      const { dates } = filters;
      const todayDate = new Date();
      const startDateYear = getYearOfFormatedDate(dates.debut); // Récupère l'année de la date de début
      const endDateYear = getYearOfFormatedDate(dates.fin); // Récupère l'année de la date de début
      const yearsDiff = getYearDiff(dates.debut, dates.fin);

      // Generate Datasets
      
      var yearsList = [];
      for (let i = 0; i <= yearsDiff; i++) {
        yearsList = [...yearsList, startDateYear + i];
      }

      axiosClient
        .get(`/administration/graphiques/sellprice`, { params: {...filters, dates: {debut: `${startDateYear}-01-01`, fin: `${endDateYear}-12-01`}} })
        .then((res) => {
          const { sellPrices } = res.data;

          const datasets = yearsList.map((year, i) => {
            var monthsPrevi = [];

            if (year === todayDate.getFullYear())
              monthsPrevi = getMonthBeforeEndOfYear(todayDate);
            else if (year > todayDate.getFullYear())
              monthsPrevi = getMonthBeforeEndOfYear(`${year}-01-01`);

            if(sellPrices[year])
              return {
                label: `CA ${year}`,
                data: Object.values(sellPrices[year]),
                borderColor: chartLineColors[i],
                pointBorderColor: chartLineColors[i],
                pointBackgroundColor: chartLineColors[i],
                segment: {
                  borderDash: (ctx) => skipped(ctx, [6, 6], monthsPrevi),
                },
                type: "line",
                datalabels: {
                  color: chartLineColors[i]
                }
              };
              
            return false
          })

          setChartDatasets(datasets.filter(dataset => dataset));
        })
    };

    getChartData();
  }, [filters]);

  return (
    <div className="w-[100%] bg-white py-6 border rounded-lg border-gray-15 relative">
      <h2 className="text-center">CA Réalisé et prévisionnel</h2>

      <div className="absolute right-4 top-4">
          <Dropdown buttonTitle={
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 transition duration-150 ease-in-out">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5" />
              </svg>
            }
            buttonClass="p-2  text-gray-400 hover:text-gray-500 rounded-lg cursor-pointer hover:bg-gray-50"
            menuClass="right-0 w-fit p-4"
            showArrow={false}
            closeOnClick={false}
          >
            <div className="w-fit">
              <div className="flex items-center">
                <DropdownListCheckbox
                  label={`Afficher l'objectif mensuel`}
                  value={showMonthlyObjective}
                  name="showMonthlyObjectiveInput"
                  id="showMonthlyObjectiveInput"
                  closeOnCLick={false}
                  key="{employe.id}"
                  onChange={(e) => setShowMonthlyObjective(e.target.checked)}
                  checked={showMonthlyObjective}
                  labelClassName="whitespace-nowrap ml-2 text-xs font-medium uppercase text-gray-900"
                  classNameContainer={'flex items-center justify-between'}
                />
              </div>
              <input
                  type="number"
                  step={1000}
                  onChange={(e) => setMonthlyObjective(e.target.value)}
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-rose-500 focus:border-rose-500 block px-2.5 ml-auto w-24 mt-2 ${showMonthlyObjective ? 'block' : 'hidden'}`}
                  value={monthlyObjective}
                  min={0}
                />
              </div>
          </Dropdown>
          
      </div>

      <Bar options={options} data={data} plugins={[monthlyObjectiveLine, ChartDataLabels]} />
    </div>
  );
};

export default CAChart;
