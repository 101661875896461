import React, { useContext, useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import Table from "../../components/Table/Table";
import ActionClients from "./ActionClients";
import { NotificationContext } from "../../contexts/NotificationContext";

const Clients = () => {
  const { addNotification } = useContext(NotificationContext);
  const [clients, setClients] = useState([]);
  const [clientData, setClientData] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState(false);

  // Status popup modal ajout
  const [showAddModal, setShowAddModal] = useState(false);

  const displayClients = async () => {
    await axiosClient.get("/clients").then((res) => {
      const clients = res.data.clients;

      // Met les libellé des clients en majuscule
      clients.map((client) => client.label = client.label.toUpperCase())

      setClients(clients);
    });
  };

  // Modifie un client
  const handleEdit = async (id) => {
    await axiosClient.get(`/clients/${id}`).then((res) => {
      setClientData(res.data.client);
      displayClients();
      setShowAddModal(true);
    });
  };

  useEffect(() => {
    displayClients();
  }, []);

  useEffect(() => {
    if (errors || success) {
      setShowAddModal(false);

      if(errors)
        addNotification({message: errors.message, list: errors.errorsList}, 'danger')

      if(success)
        addNotification(success, 'success')

      displayClients();
    }
  }, [errors, success]);

  const handleAfterSubmitForm = ({success, message}) => {
    if(!success) addNotification({message: message.errorMsg, list: message.errorList}, 'danger')

    if(success){
      addNotification(message, 'success')
      setShowAddModal(false);
      displayClients();
    }
  }

  return (
    <>
      {/* Bouton ajouter un client */}
      <button
        className={`inline-flex items-center text-white focus:outline-none focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 ml-auto bg-darkblue-600 hover:bg-darkblue-700 focus:ring-darkblue-700 border-darkblue-700`}
        onClick={() => {
          setShowAddModal(true);
          setClientData(false);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 mr-2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        Ajouter un client
      </button>

      {/* Modal pour ajouter/éditer un client */}
      <ActionClients
        showModal={showAddModal}
        setShowModal={setShowAddModal}
        client={clientData ? clientData : false}       
        handleAfterSubmitForm={handleAfterSubmitForm}
      />

      <div className="mt-4">
        <Table
          mockData={clients}
          header={[
            {
              Header: "Nom",
              accessor: "label",
            },
          ]}
          action={true}
          afficherSupprimer={true}
          apiUrl="clients"
          setSuccess={setSuccess}
          afficherEditer={true}
          editer={handleEdit}
        />
      </div>
    </>
  );
};

export default Clients;
