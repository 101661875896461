const slugify = (str) => {
    var chars = [['a', 'áàãäâ'], ['e','éèëê'], ['i','íìïî'], ['o','óòõöô'], ['u','úùüû']];

    // Create slug
    let slug = str.toLowerCase().replace(/[^\w-áàãäâéèëêíìïîóòõöôúùüû]+/g, '-'); // Transform any character with not alphanumeric and transform on -
    for (var i in chars) slug = slug.replace(new RegExp('[' + chars[i][1] + ']', 'g'), chars[i][0]); // Convert accent to letter without accent

    return slug;
}

export {slugify}