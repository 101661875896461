import React, { useEffect, useState } from "react";
import Form from "../../../components/Form/Form";
import axiosClient from "../../../axios-client";

const EditProjet = ({ projetValues, handleAfterSubmitForm }) => {
  const [categories, setCategories] = useState([]);

  const initialValues = {
    project_id: projetValues.projetId,
    customer_id: projetValues.data.customer_id,
    label: projetValues.data.label,
    sell_price: projetValues.data.sell_price,
    project_date: projetValues.data.project_date,
    category_id: projetValues.data.category_id,
    monthly: projetValues.data.monthly,
    forecast: projetValues.data.forecast
  };

  const fields = [
    { name: "project_id", type: "hidden" },
    { name: "customer_id", type: "hidden" },
    { group: [
      { name: "label", label: "Nom du projet" },
      { name: "sell_price", label: "Prix de vente", type: "number" },
      { name: "project_date", label: "Date", type: "date"},
      { name: "category_id", label: "Catégorie", type: "select", options: categories,},
    ], groupClassName: "grid gap-4 sm:grid-cols-2 mt-0"},
    { group: [
      {name: 'monthly', label: 'Projet mensuel', type: 'toggle'},
      {name: 'forecast', label: 'Projet prévisionnel', type: 'toggle'},
    ]}
  ];

  const getCategoriesList = async() => {
    await axiosClient.get("/categories").then((res) => {
      setCategories(res.data.categories.map(category => ({
        label: category.label,
        value: category.id,
      })))
    });
  };

  // Initialise la liste des catégories
  useEffect(() => {
    getCategoriesList();
  }, [])

  return (
    <Form
      method="put"
      apiUrl={`projets/${projetValues.data.id}`}
      fields={fields}
      initialValues={initialValues}
      onSubmit={handleAfterSubmitForm}
      buttonTitle="Modifer"
    />
  );
};

export default EditProjet;
