import React, { useState } from "react";
import Input from "./Input";
import Repeater from "./Repeater";
import axiosClient from "../../axios-client";
;

const Form = ({ method, apiUrl, fields, initialValues, onSubmit, buttonTitle }) => {
  const [formData, setFormData] = useState(initialValues ? initialValues : {});
  const [errorsList, setErrorsList] = useState([]);

  const handleFieldChange = (name, value, isMultiple = false) => {
    // Mettre à jour le champ modifié
    if(name){
      setFormData((prevFormData) => {
        // Si on retire une donnée du formData avec undefined ou null
        if (value === undefined || value === null) {
          // Supprimer la clé name de l'objet prevFormData
          if (!isMultiple) {
            const { [name]: valueToRemove, ...updatedFormData } = prevFormData;
            return updatedFormData;
          } else {
            // Supprimer la valeur du champ multiple
            const updatedMultipleValues = prevFormData[name]?.filter((v, index) => index !== (isMultiple - 1)) || [];
            return { ...prevFormData, [name]: updatedMultipleValues };
          }
        } else {
          if (isMultiple) {
            const multipleArray = prevFormData[name] || [];
            multipleArray[isMultiple - 1] = value;

            return { ...prevFormData, [name]: multipleArray };
          } else {
            return { ...prevFormData, [name]: value };
          }
        }
      });
    }
  };

  const isFieldVisible = (field) => {
    if (!field.dependentOn) {
      return true; // Si le champ n'a pas de dépendance, il est toujours visible
    }

    // Vérifier la valeur du champ dépendant pour décider de la visibilité
    const dependentFieldValue = formData[field.dependentOn];
    return dependentFieldValue === true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    for (var [key, value] of Object.entries(formData)) {
      if(value === undefined)
        formData[key] = initialValues[key]
    }

    await axiosClient({
        method: method, 
        url: apiUrl, 
        data: formData
    }).then(response => {
        // Si la requête retourne un succès, on retourne les données et un message de succès
        if(response.data.success) {
            onSubmit({
                success: true,
                message: response.data.message,
                data: response.data.data,
            })
        // Sinon on retourne un message d'erreur
        }else{
            setErrorsList(Object.keys(response.data.data))
            onSubmit({
                success: false,
                message: {
                    errorMsg: response.data.message,
                    errorList: response.data.data,
                    formData: formData
                },
                debug: formData
            })
        }
    }).catch(error => {
        // Traitez les erreurs d'appel API ici
        console.error("Une erreur s'est produite lors de l'envoi des données à l'API :", error);
        console.log('Données du formulaire envoyées:', formData)
    })
  };

  return (
    <form onSubmit={handleSubmit} className={`space-y-4`}>
      {fields.map((field, fieldIndex) => {
        if (!isFieldVisible(field)) {
          if (formData[field.name]) handleFieldChange(field.name, null); // Retire la valeur dans le formData si elle existé
          return null; // Ne pas afficher le champ s'il n'est pas visible
        }
        
        // Inputs normal
        if (field.type != "repeater" && field.type != "dynamic")
          return (
            <div key={fieldIndex} hidden={field.type === 'hidden' ? true : false}>
              {field.group ? (
                <div className={field.groupClassName || ""}>
                  {field.group.map((groupField, groupIndex) => {
                    // Génère la valeur de l'input, si elle est entré dans le formData on la met, sinon si elle à une valeur dynamic (utilisé pour les
                    // inputs hidden) on lui met
                    if (!isFieldVisible(groupField)) {
                      if (formData[groupField.name])
                        handleFieldChange(groupField.name, null); // Retire la valeur dans le formData si elle existé
                      return null;
                    }
                    return (
                      <div
                        className={`${groupField.width && `w-${groupField.width}`} ${groupField.customClass && `${groupField.customClass}`}`}
                        key={groupIndex}
                      >
                        <Input
                          key={groupField.name}
                          type={groupField.type}
                          name={groupField.name}
                          label={groupField.label}
                          value={formData[groupField.name] || ""}
                          options={groupField.options}
                          changeFormDataValue={handleFieldChange}
                          onChange={groupField.onChange}
                          disabled={groupField.disabled}
                          isMultiple={groupField.isMultiple ? groupIndex : null}
                          hasError={errorsList.includes(groupField.name)}
                          {...groupField}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className={field.type === 'hidden' ? '' : 'mb-6'}>
                  <Input
                    key={field.name}
                    type={field.type}
                    name={field.name}
                    label={field.label}
                    value={formData[field.name] || ""}
                    options={field.options}
                    changeFormDataValue={handleFieldChange}
                    onChange={field.onChange}
                    disabled={field.disabled}
                    isMultiple={field.isMultiple ? fieldIndex : null}
                    hasError={errorsList.includes(field.name)}
                    
                    {...field}
                  />
                </div>
              )}
            </div>
          );

        // Répéteur
        return (
          <div className={field.className} key={fieldIndex}>
            <Repeater
              key={field.name}
              name={field.name}
              label={field.label}
              inputsList={field.inputs}
              formData={formData}
              changeFormDataValue={handleFieldChange}
              onChange={field.onChange}
              errorsList={errorsList}
              {...field}
            />
          </div>
        );
      })}
      <button
        className={`inline-flex items-center text-white focus:outline-none focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 ml-auto bg-rose-600 hover:bg-rose-700 focus:ring-rose-700 border-rose-700`}
        type="submit"
      >
        {buttonTitle || "Ajouter"}
      </button>
    </form>
  );
};

export default Form;
