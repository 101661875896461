import React, { useEffect, useState } from "react";
import Modal from "../../components/Modal/Modal";
import ModalHeader from "../../components/Modal/ModalHeader";
import ModalBody from "../../components/Modal/ModalBody";
import Form from "../../components/Form/Form";
import { formatDate } from "../../components/Utils/Dates";
import axiosClient from "../../axios-client";

const ActionCoutsFixes = ({
  showModal,
  setShowModal,
  handleAfterSubmitForm,
  coutsFixes = false,
}) => {
  const [categories, setCategories] = useState([]);

  const displayCategories = async () => {
    await axiosClient.get("/categories-couts").then((res) => {
      setCategories(
        res.data.categoriesCouts.map((category) => ({
          label: category.label,
          value: category.id,
        }))
      );
    });
  };

  const today = new Date();
  today.setDate(1);

  /** FORMULAIRE */
  const initialValues = {
    label: coutsFixes ? coutsFixes.label : undefined,
    cost: coutsFixes ? coutsFixes.cost : undefined,
    cost_date: coutsFixes
      ? coutsFixes.cost_date
      : formatDate(today, "yyyy-mm-dd"),
    category_fixed_cost_id: coutsFixes
      ? coutsFixes.category_fixed_cost_id
      : undefined,
  };

  const field = () => {
    const labelNCost = {
      group: [
        { name: "label", label: "Nom" },
        { name: "cost", label: "Montant", type: "number" },
      ],
      groupClassName: "grid gap-4 sm:grid-cols-2 mt-0 mb-6",
    };

    const recurrence = {
      group: [
        { name: "recurrent", label: "Récurrence du coût fixe", type: "number" },
      ],
      groupClassName: "mt-0 mb-6",
    };

    const dateNCategory = {
      group: [
        { name: "cost_date", label: "Date", type: "date" },
        {
          name: "category_fixed_cost_id",
          label: "Catégorie",
          type: "select",
          options: categories,
        },
      ],
      groupClassName: "grid gap-4 sm:grid-cols-2 mt-0 mb-6",
    };

    if (!coutsFixes)
      return [{ ...labelNCost }, { ...recurrence }, { ...dateNCategory }];
    else return [{ ...labelNCost }, { ...dateNCategory }];
  };

  useEffect(() => {
    displayCategories();
  }, []);

  return (
    <Modal show={showModal} onClose={() => setShowModal(false)}>
      <ModalHeader
        title={`${coutsFixes ? "Modifier" : "Ajouter"} un coût fixe`}
        modalHeaderContainerclassName="p-0 pb-4"
      />

      <ModalBody modalBodyContainerclassName="px-0 pt-4 pb-0">
        <Form
          buttonTitle={coutsFixes ? "Modifier" : "Ajouter"}
          method={coutsFixes ? "put" : "post"}
          apiUrl={coutsFixes ? `couts-fixes/${coutsFixes.id}` : `couts-fixes`}
          fields={field()}
          initialValues={initialValues}
          onSubmit={handleAfterSubmitForm}
        />
      </ModalBody>
    </Modal>
  );
};

export default ActionCoutsFixes;
