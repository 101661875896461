import React, { useEffect, useState } from 'react';
import Modal from '../../components/Modal/Modal';
import ModalBody from '../../components/Modal/ModalBody';
import Form from '../../components/Form/Form';

const DeleteCoutsFixes = ({ coutsFixes, showModal, setShowModal , handleAfterSubmitForm }) => {

    const initialValues = { 
        coutfixeid: coutsFixes.map((item) => { return item.id})
    }

    const fields = [
        {
          group: [
            { name: 'coutfixeid', type: 'hidden' },
          ], groupClassName: "grid gap-4 sm:grid-cols-2 mt-0 mb-6"
        },
       ];


    return (
        <div>
            <Modal
                show={showModal}
                onClose={() => setShowModal(false)}
                modalContainerclassName="p-4 sm:p-5">
                <ModalBody>
                    <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                            <svg
                                className="h-6 w-6 text-red-600"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                                />
                            </svg>
                        </div>
                        <div className="mt-3 text-left sm:mt-0 sm:ml-4">
                            <h3
                                className="text-lg font-medium leading-6 text-gray-900"
                                id="modal-title"
                            >
                                Supprimer les éléments
                            </h3>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    Êtes-vous sûre de vouloir supprimer ces éléments ? Toutes les
                                    opérations liées à celle-ci seront automatiquement supprimées.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-around w-1/2 m-auto items-end'>
                        <Form
                            buttonTitle={<><svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-6 h-6 text-white"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                />
                            </svg>Supprimer</>
                            }
                            method={'post'}
                            apiUrl={'delete-cout-fixes'}
                            buttonClass="mt-0"
                            fields={fields}
                            initialValues={initialValues}
                            onSubmit={handleAfterSubmitForm}
                            className="space-y-0 md:space-y-0">
                        </Form>
                        <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={() => setShowModal(false)}
                        >
                            Annuler
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default DeleteCoutsFixes;