import React, { useEffect, useState } from "react";
import Input from "./Input";

const Repeater = ({ name, label, inputsList, formData, changeFormDataValue, errorsList, handleDeleteField }) => {
  const [fields, setFields] = useState([]);

  const handleAddField = () => {
    setFields((prevFields) => {
      const prevFieldData = prevFields || [];
      // Si il n'y pas de champs, on commence à 1
      const newFieldData =
        prevFieldData.length === 0
          ? 1
          : prevFieldData[prevFieldData.length - 1] + 1;

      return [...prevFields, newFieldData];
    });

    // Ajouter la valeur du champs caché dans le formData
    changeFormDataValue(name, 1)
  };

  const handleRemoveField = (field) => {
    // Supprime les champs de le form
    setFields(fields.filter((input) => input !== field));

    // Supprime les valeurs dans le formData
    if((fields.length - 1) === 0) changeFormDataValue(name, undefined)
    inputsList.map((input) => {
      changeFormDataValue(input.name, undefined, field)});
    
    if(handleDeleteField) handleDeleteField(field)
  };

  useEffect(() => {
    if(formData.add_employee || formData.add_benefit){
      const inputName = inputsList[0].name;
      for(var i = 0; i < formData[inputName]?.length; i++){
        handleAddField();
      }
    }
  }, []);

  return (
    <>
      <div
        className="inline-flex items-center flex-end hover:text-rose-500 cursor-pointer"
        onClick={handleAddField}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-4 h-4 mr-1"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 6v12m6-6H6"
          ></path>
        </svg>
        {label}
      </div>

      {/* Génère un champs caché */}
      {fields?.length > 0 && (
        <Input type="hidden" key={`${name}_hidden`} name={name} value={1} />
      )}

      {/* Génère les inputs */}
      {fields.length > 0 &&
        fields.map((index, i) => (
          <div key={index} className="flex !mt-2 mb-4 space-x-4">
            {inputsList.map((field) => {

              if (field.type === 'dynamic'){
                return field.value(formData, index - 1)
              }
              
              const input = (formData[field.name] && formData[field.name][i]) ?
                <Input
                  key={`${field.name}_${index}`}
                  type={field.type}
                  name={field.name}
                  value={formData[field.name][index - 1]}
                  label={field.label}
                  options={field.options}
                  changeFormDataValue={changeFormDataValue}
                  onChange={field.onChange}
                  disabled={field.disabled}
                  containerClass={field.containerClass}
                  isMultiple={field.isMultiple ? index : null}
                  min={field.min}
                  hasError={errorsList.includes(field.name)}
                />
                :
                <Input
                  key={`${field.name}_${index}`}
                  type={field.type}
                  name={field.name}
                  label={field.label}
                  value=""
                  options={field.options}
                  changeFormDataValue={changeFormDataValue}
                  onChange={field.onChange}
                  disabled={field.disabled}
                  containerClass={field.containerClass}
                  isMultiple={field.isMultiple ? index : null}
                  hasError={errorsList.includes(field.name)}
                  min={field.min}
                />;

              return input
            })}
            <div
              className="flex items-end mb-1"
              onClick={() => handleRemoveField(index)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-8 h-8 rotate-45  hover:text-rose-500 cursor-pointer"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 6v12m6-6H6"
                ></path>
              </svg>
            </div>
          </div>
        ))}
    </>
  );
};

export default Repeater;
