import React, { useContext, useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import Table from "../../components/Table/Table";
import ActionRole from "./ActionRole";
import { NotificationContext } from "../../contexts/NotificationContext";

const Roles = () => {
  const { addNotification } = useContext(NotificationContext);
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [roleData, setRoleData] = useState(false);

  // Status popup modal ajout
  const [showAddModal, setShowAddModal] = useState(false);

  // Récupère les roles
  const displayRoles = async () => {
    await axiosClient.get("/roles").then((res) => {
      setRoles(res.data.roles);
    });
  };

  // Récupère les permissions pour les afficher dans les popups
  const displayPermissions = async () => {
    await axiosClient.get("/permissions").then((res) => {
      setPermissions(res.data.permissions);
    });
  };

  const handleEdit = async (id) => {
    await axiosClient.get(`/roles/${id}`).then((res) => {
      setRoleData(res.data.role);
      displayPermissions();
      setShowAddModal(true);
    });
  };

  // Affiche la liste des roles
  useEffect(() => {
    displayRoles();
  }, []);

  // Récupère les permissions quand ouvre le modal d'ajout/modification
  useEffect(() => {
    if (showAddModal) {
      displayPermissions();
    }
  }, [showAddModal]);

  const handleAfterSubmitForm = ({success, message}) => {
    if(!success) addNotification({message: message.errorMsg, list: message.errorList}, 'danger')

    if(success){
      addNotification(message, 'success')
      setShowAddModal(false);
      displayRoles();
    }
  }

  return (
    <div>
      {/* Bouton ajouter un role */}
      <button
        className={`inline-flex items-center text-white focus:outline-none focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 ml-auto bg-darkblue-600 hover:bg-darkblue-700 focus:ring-darkblue-700 border-darkblue-700`}
        onClick={() => {
          setShowAddModal(true);
          setRoleData(false);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 mr-2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        Ajouter un role
      </button>

      {/* Modal pour ajouter/éditer un role */}
      <ActionRole
        showAddModal={showAddModal}
        setShowAddModal={setShowAddModal}
        handleAfterSubmitForm={handleAfterSubmitForm}
        permissions={permissions}
        role={roleData ? roleData : false}
      />

      <div className="mt-4">
        <Table
          mockData={roles}
          header={[
            {
              Header: "Nom",
              accessor: "name",
            },
          ]}
          action={true}
          afficherSupprimer={true}
          apiUrl="roles"
          afficherEditer={true}
          editer={handleEdit}
          className={"bg-darkblue"}
          handleAfterSubmitForm={handleAfterSubmitForm}
        />
      </div>
    </div>
  );
};

export default Roles;
