import React, { useEffect, useState } from 'react'
import Dropdown from '../../components/Dropdown/Dropdown'
import InputDate from '../../components/Form/InputDate'
import DropdownListCheckbox from '../../components/Dropdown/DropdownListCheckbox';
import axiosClient from '../../axios-client';
import { formatDate, parseDate } from '../../components/Utils/Dates';

const Filters = ({filters, setFilters, isTableShowed, handleSubmitFilter}) => {
    const [resetFilters, setResetFilters] = useState(false);

    /** ---- FILTRE CATÉGORIES ---- */
    // Récupère les catégories
    const [categories, setCategories] = useState();
    const displayCategories = () => {
        axiosClient
          .get(`/categories`)
          .then((res) => {
            setCategories(res.data.categories);
          });
    }
 
    useEffect(() => {
        displayCategories();
    }, [])

    // Lorsque l'on filtre par catégorie
    const handleChangeCategoryFilter = async (event) => {
        const {value, checked} = event.target;

        await setFilters(crtFilter => {
            const selectedIds = crtFilter.categories || [];
            const newSelectedIds = checked
              ? [...selectedIds, parseInt(value)]
              : selectedIds.filter((selectedId) => selectedId !== parseInt(value))
            ;

            return {...crtFilter, categories: newSelectedIds}
        });      
    }

    /** ---- FILTRE PRÉVISIONNEL ---- */
    const handleChangeFiltrePrevisionnel = async (event) => {
        const {value} = event.target;
        await setFilters(crtFilter => ({...crtFilter, previsionnel: value}))
    }

    /** ---- FILTRE RECURRENCE */
    const handleChangeFiltreRecurrence = async (event) => {
        const {value} = event.target;
        await setFilters(crtFilter => ({...crtFilter, recurrence: value}))
    }

    /** ---- FILTRE DATE ---- */
    const [selectedDate , setSelectedDate] = useState({});
    const [changedDate , setChangedDate] = useState(false);
    const [reinitializeDate , setReinitializeDate] = useState(false);

    // Change le filtre date
    const handleChangeDate = async () => {
        await setFilters((crtFilter) => {
            const dateDebut = parseDate(selectedDate.debut, 'dd/mm/yyyy', 'yyyy-mm-dd') 
            const dateFin = parseDate(selectedDate.fin, 'dd/mm/yyyy', 'yyyy-mm-dd')
            return {...crtFilter, dates: {debut: dateDebut, fin: dateFin}}
        })
    }

    // Quand la date est mise à jour par le composant InputDate
    useEffect(() => {
        if(changedDate){
            handleChangeDate();
            setChangedDate(false);
            setReinitializeDate(false);
        }
    }, [selectedDate])

    useEffect(() => {
        if(reinitializeDate)
            setReinitializeDate(false)
    }, [reinitializeDate])

    // Reset les filtres
    const handleResetFilter = async () => {
        const todayDate = new Date();
        todayDate.setDate(1);
        const sixMonthsLater = new Date(todayDate);
        sixMonthsLater.setMonth(sixMonthsLater.getMonth() + 6);
        await setFilters({dates: {debut: formatDate(todayDate, 'yyyy-mm-01'), fin: formatDate(sixMonthsLater, 'yyyy-mm-01')}, previsionnel: 'all', recurrence: 'all'})
        setReinitializeDate(true);
        setResetFilters(true);
    }

    useEffect(() => {
        if(resetFilters){
          handleSubmitFilter();
          setResetFilters(false)
        }
    }, [resetFilters])

    const handleShowNextMonth = (monthToAdd, oneMonth = false) => {
        const todayDate = new Date();
        todayDate.setDate(1);

        const nextDate = new Date(todayDate);
        nextDate.setMonth(nextDate.getMonth() + monthToAdd);

        const startDate = formatDate(oneMonth ? nextDate : todayDate, 'yyyy-mm-01');
        const endDate = formatDate(nextDate, 'yyyy-mm-01');

        setFilters((crtFilter) => {
            return {...crtFilter, dates: {debut: startDate, fin: endDate}}
        })

        setReinitializeDate(true);
    }
 
  return (
    <div className="p-4 pt-3 mb-4 rounded-lg bg-gray-200 relative w-9/12">
        <span className="uppercase text-muted text-[12px] font-bold ">
          Filtrer par
        </span>


        <div className="flex justify-between align-center mt-2 mb-4">
            <div className="flex space-x-4 items-center">
                <Dropdown
                buttonTitle="Catégories"
                buttonClass="inline-flex items-center text-white rounded-lg p-2.5 bg-darkblue-600 hover:bg-darkblue-700 text-xs"
                menuClass="!mt-2 shadow-md p-4"
                closeOnClick={false}
                >
                    {categories && categories.map((category) => (
                        <DropdownListCheckbox 
                            label={category.label}
                            key={category.id}
                            classNameContainer="px-0 flex items-center justify-between py-1"
                            value={category.id}
                            closeOnCLick={false}
                            type="checkbox"
                            onChange={handleChangeCategoryFilter}
                            checked={filters.categories?.includes(category.id)}
                        />
                    ))}
                </Dropdown>
                
                {/* Filtre dates */}
                <div className="flex justify-between gap-2">
                    <div className="flex">
                        <span className="bg-darkblue-100 p-2 text-white rounded-tl-md rounded-bl-md select-none text-xs flex items-center">Du</span>
                       <InputDate 
                            setSelectedDate={(data) => setSelectedDate((crtDate) => ({...crtDate, debut: data}))}
                            onChange={() => setChangedDate(true)}
                            defaultDate={filters.dates.debut}
                            dashboard={true}
                            reinitialize={reinitializeDate}
                        />
                    </div>
                    <div className="flex">
                        <span className=" bg-darkblue-100 p-2 text-white rounded-tl-md rounded-bl-md select-none text-xs flex items-center">Au</span>
                        <InputDate 
                            setSelectedDate={(data) => setSelectedDate((crtDate) => ({...crtDate, fin: data}))}
                            onChange={() => setChangedDate(true)}
                            defaultDate={filters.dates.fin}
                            dashboard={true}
                            reinitialize={reinitializeDate}
                        />
                    </div>
                </div>

                {/* Dropdown type projets (prévisonnel/vendus) */}
                <Dropdown
                    buttonTitle="CA"
                    buttonClass="inline-flex items-center text-white rounded-lg p-2.5 bg-darkblue-600 hover:bg-darkblue-700 text-xs"
                    menuClass="!mt-2 shadow-md p-4"
                >
                    <DropdownListCheckbox
                        label="Tout afficher"
                        classNameContainer="px-0 flex items-center justify-between py-1"
                        value="all"
                        type="radio"
                        onChange={handleChangeFiltrePrevisionnel}
                        checked={filters.previsionnel === "all"}
                    />

                    <DropdownListCheckbox
                        label="CA vendu"
                        classNameContainer="px-0 flex items-center justify-between py-1"
                        value="vendu"
                        type="radio"
                        onChange={handleChangeFiltrePrevisionnel}
                        checked={filters.previsionnel === "vendu"}
                    />

                    <DropdownListCheckbox
                        label="CA prévisionnel"
                        classNameContainer="px-0 flex items-center justify-between py-1"
                        value="previsionnel"
                        type="radio"
                        onChange={handleChangeFiltrePrevisionnel}
                        checked={filters.previsionnel === "previsionnel"}
                    />
                </Dropdown>

                {/* Dropdown type récurrent (mensuel/oneshot) */}
                {!isTableShowed 
                    ? (
                        <Dropdown
                            buttonTitle="Récurrent"
                            buttonClass="inline-flex items-center text-white rounded-lg p-2.5 bg-darkblue-600 hover:bg-darkblue-700 text-xs"
                            menuClass="!mt-2 shadow-md p-4"
                        >
                            <DropdownListCheckbox
                                label="Tout afficher"
                                classNameContainer="px-0 flex items-center justify-between py-1"
                                value="all"
                                type="radio"
                                onChange={handleChangeFiltreRecurrence}
                                checked={filters.recurrence === "all"}
                            />

                            <DropdownListCheckbox
                                label="Oneshot"
                                classNameContainer="px-0 flex items-center justify-between py-1"
                                value="0"
                                type="radio"
                                onChange={handleChangeFiltreRecurrence}
                                checked={filters.recurrence === "0"}
                            />

                                <DropdownListCheckbox
                                label="Mensuel"
                                classNameContainer="px-0 flex items-center justify-between py-1"
                                value="1"
                                type="radio"
                                onChange={handleChangeFiltreRecurrence}
                                checked={filters.recurrence === "1"}
                                />
                        </Dropdown>
                    ) : ""
                }
                
            </div>

            
        </div>

        <div className='flex space-x-2'>
            <button className={`inline-flex items-center text-white rounded-lg p-2.5 bg-rose-600 hover:bg-rose-700 text-xs`}
            onClick={() => handleShowNextMonth(0, true)}>
                Afficher le mois actuel
            </button>
            <button className={`inline-flex items-center text-white rounded-lg p-2.5 bg-rose-600 hover:bg-rose-700 text-xs`}
            onClick={() => handleShowNextMonth(1, true)}>
                Afficher le prochain mois
            </button>
            <button className={`inline-flex items-center text-white rounded-lg p-2.5 bg-rose-600 hover:bg-rose-700 text-xs`}
            onClick={() => handleShowNextMonth(6)}>
                Afficher les 6 prochains mois
            </button>

            {/* Réinitialiser les filtres */}
            <div className="ml-auto w-fit flex items-center flex-1 gap-4">
                <span
                    className="uppercase text-[12px] !ml-auto !mr-0 w-fit block cursor-pointer hover:underline flex-0 basis-auto text-right"
                    onClick={handleResetFilter}
                >
                    Réinitialiser les filtres
                </span>
                <button className="inline-flex items-center text-white font-bold uppercase cursor-pointer rounded-lg p-2.5 bg-darkblue-600 hover:bg-darkblue-700 text-xs"
                onClick={handleSubmitFilter}>Filtrer</button>
            </div>
        </div>

       

    </div>
  )
}

export default Filters