import React, { useContext, useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import Table from "../../components/Table/Table";
import ActionCategoriesCouts from "./ActionCategoriesCouts";
import { NotificationContext } from "../../contexts/NotificationContext";

const CategoriesCouts = () => {
  const { addNotification } = useContext(NotificationContext);
  const [categoriesCouts, setCategoriesCouts] = useState([]);
  const [categorieCoutData, setcategorieCoutData] = useState(false);

  // Status popup modal ajout
  const [showAddModal, setShowAddModal] = useState(false);

  const displayCategoriesCouts = async () => {
    await axiosClient.get("/categories-couts").then((res) => {
      setCategoriesCouts(res.data.categoriesCouts);
    });
  };

  const handleEdit = async (id) => {
    await axiosClient.get(`/categories-couts/${id}`).then((res) => {
      setcategorieCoutData(res.data.categorieCout);
      setShowAddModal(true);
    });
  };

  useEffect(() => {
    displayCategoriesCouts();
  }, []);

  const handleAfterSubmitForm = ({success, message}) => {
    if(!success) addNotification({message: message.errorMsg, list: message.errorList}, 'danger')

    if(success){
      setShowAddModal(false);
      addNotification(message, 'success')
      displayCategoriesCouts();
    }
  }
  return (
    <>
      {/* Bouton ajouter un role */}
      <button
        className={`inline-flex items-center text-white focus:outline-none focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 ml-auto bg-darkblue-600 hover:bg-darkblue-700 focus:ring-darkblue-700 border-darkblue-700`}
        onClick={() => {
          setShowAddModal(true);
          setcategorieCoutData(false);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 mr-2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        Ajouter une catégorie
      </button>

      {/* Modal pour ajouter/éditer un role */}
      <ActionCategoriesCouts
        showModal={showAddModal}
        setShowModal={setShowAddModal}
        categoriesCouts={categorieCoutData ? categorieCoutData : undefined}
        handleAfterSubmitForm={handleAfterSubmitForm}
      />

      <div className="mt-4">
        <Table
          mockData={categoriesCouts}
          header={[
            {
              Header: "Libelle",
              accessor: "label",
            },
          ]}
          action={true}
          afficherSupprimer={true}
          apiUrl="categories-couts"
          afficherEditer={true}
          editer={handleEdit}
          className={"bg-bleu"}
          handleAfterSubmitForm={handleAfterSubmitForm}
        />
      </div>
    </>
  );
};

export default CategoriesCouts;
