import { createBrowserRouter } from "react-router-dom";
import DashboardLayout from "./layouts/DashboardLayout";
import GuestLayout from "./layouts/GuestLayout";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import Administration from "./pages/Administration/Administration";
import Profile from "./pages/Profil/Profile";
import Categories from "./pages/Categories/Categories";
import Clients from "./pages/Clients/Clients";
import Employes from "./pages/Employes/Employes";
import Roles from "./pages/Roles/Roles";
import AdministrationLayout from "./layouts/AdministrationLayout";
import Permissions from "./pages/Permissions/Permissions";
import CoutsFixes from "./pages/CoutsFixes/CoutsFixes";
import CategoriesCouts from "./pages/CategoriesCouts/CategoriesCouts";
import Management from "./pages/Management/Management";
import ProfilLayout from "./layouts/ProfilLayout";
import Projet from "./pages/Profil/Projet";
import Poles from "./pages/Poles/Poles";

const router = createBrowserRouter([
  {
    element: <DashboardLayout />,
    children: [
      { index: true, path:"/", element: <Dashboard />, name: "dashboard" },
      { element: <AdministrationLayout />, path: "/administration", children: [
        { index: true, path: "", element: <Administration />, name: "administration.index" },
        { path: "roles", element: <Roles />, name: "roles.index"},
        { path: "categories", element: <Categories />, name: "categories.page"},
        { path: "clients", element: <Clients />, name: "clients.page" },
        { path: "employes", element: <Employes />, name: "employes.page" },
        { path: "poles", element: <Poles />, name: "poles.index" },
        { path: "permissions", element: <Permissions />, name: "permissions.index" },
        { path: "couts-fixes", element: <CoutsFixes />, name: "couts-fixes.index" },
        { path: "couts-fixes/categories", element: <CategoriesCouts />, name: "categories-couts.index" },
      ] },
      { element: <ProfilLayout />, path: "/profil", children: [
        { path: "", element: <Profile />, name: "profil"},
        { path: "projets", element: <Projet />, name: "profil"},
      ] },
      { path: "/management", element: <Management />, name: "management.index"},
    ],
  },
  {
    path: "/",
    element: <GuestLayout />,
    children: [{ path: "/login", element: <Login /> }],
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

router.routes[0].element = <DashboardLayout routes={router.routes[0].children} />;

export default router;
