import React from "react";
import Modal from "../../components/Modal/Modal";
import ModalHeader from "../../components/Modal/ModalHeader";
import ModalBody from "../../components/Modal/ModalBody";
import Form from "../../components/Form/Form";

const ActionPermissions = ({
  showAddModal,
  setShowAddModal,
  permission = false,
  handleAfterSubmitForm,
}) => {
  /** FORMULAIRE */
  const initialValues = {
    name: permission ? permission.name : undefined
  }

  const fields = [
    { name: 'name', label: 'Nom' },
  ]

  return (
    <Modal
      show={showAddModal}
      onClose={() => setShowAddModal(false)}
      modalContainerclassName="p-4 sm:p-5"
    >
      <ModalHeader
        title={`${permission ? "Modifier" : "Ajouter"} une permission`}
        modalHeaderContainerclassName="p-0 pb-4"
      />

      <ModalBody modalBodyContainerclassName="px-0 pt-4 pb-0">
        <Form
            buttonTitle={(permission) ? "Modifier" : "Ajouter"}
            method={(permission) ? "put" : "post"}
            apiUrl={(permission) ? `permissions/${permission.id}` : `permissions`}
            fields={fields}
            initialValues={initialValues}
            onSubmit={handleAfterSubmitForm}
        />
      </ModalBody>
    </Modal>
  );
};

export default ActionPermissions;
