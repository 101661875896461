import React, { useContext, useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import Table from "../../components/Table/Table";
import ActionCategories from "./ActionCategories";
import { NotificationContext } from "../../contexts/NotificationContext";

const Categories = () => {
  const { addNotification } = useContext(NotificationContext);
  const [categories, setCategories] = useState([]);
  const [categorieData, setCategorieData] = useState([]);

  // Status popup modal ajout
  const [showAddModal, setShowAddModal] = useState(false);

  useEffect(() => {
    displayCategories();
  }, []);

  const displayCategories = async () => {
    await axiosClient.get("/categories").then((res) => {
      setCategories(res.data.categories);
    });
  };

  const handleEdit = async (id) => {
    await axiosClient.get(`/categories/${id}`).then((res) => {
      setCategorieData(res.data.categorie);
      setShowAddModal(true);
    });
  };

  const handleAfterSubmitForm = ({success, message}) => {
    if(!success) addNotification({message: message.errorMsg, list: message.errorList}, 'danger')

    if(success){
      setShowAddModal(false);
      addNotification(message, 'success')
      displayCategories();
    }
  }

  return (
    <>
      {/* Bouton ajouter une catégorie */}
      <button
        className={`inline-flex items-center text-white focus:outline-none focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 ml-auto bg-darkblue-600 hover:bg-darkblue-700 focus:ring-darkblue-700 border-darkblue-700`}
        onClick={() => {
          setShowAddModal(true);
          setCategorieData(false)
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 mr-2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        Ajouter une catégorie
      </button>

      {/* Modal pour ajouter/éditer un role */}
      <ActionCategories
        showAddModal={showAddModal}
        setShowAddModal={setShowAddModal}
        handleAfterSubmitForm={handleAfterSubmitForm}
        categories={categorieData ? categorieData : false}
      />

      <div className="mt-4">
        <Table
          mockData={categories}
          header={[
            {
              Header: "Libelle",
              accessor: "label",
            },
          ]}
          action={true}
          afficherSupprimer={true}
          apiUrl="categories"
          afficherEditer={true}
          editer={handleEdit}
          className={"bg-darkblue"}
          handleAfterSubmitForm={handleAfterSubmitForm}
        />
      </div>
    </>
  );
};

export default Categories;
