import React, { useContext, useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import Table from "../../components/Table/Table";
import ActionCoutsFixes from "./ActionCoutsFixes";
import Accordion from "../../components/Accordion/Accordion";
import AccordionTab from "../../components/Accordion/AccordionTab";
import Dropdown from "../../components/Dropdown/Dropdown";
import DropdownListCheckbox from "../../components/Dropdown/DropdownListCheckbox";
import InputDate from "../../components/Form/InputDate";
import Loader from "../../components/Loader/NumbersLoarder";
import { NotificationContext } from "../../contexts/NotificationContext";
import { parseDate } from "../../components/Utils/Dates";
import DuplicateCoutsFixes from "./DuplicateCoutsFixes";
import DeleteCoutsFixes from "./DeleteCoutsFixes";
import { numberWithSpaces } from "../../components/Utils/CalculROI";

const CoutsFixes = () => {
  const { addNotification } = useContext(NotificationContext);
  const [coutsFixes, setCoutsFixes] = useState([]);
  const [categoriesCouts, setCategoriesCouts] = useState([]);
  const [coutsFixesData, setCoutsFixesData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const [changedDate, setChangedDate] = useState(false);
  const [reinitializeDate, setReinitializeDate] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [ShowModalDuplicate, setShowModalDuplicate] = useState(false);
  const [ShowModalDelete, setShowModalDelete] = useState(false);
  const [coutsFixesDuplicate , setCoutsFixesDuplicate] = useState([]);
  const [dateDuplicate , setDateDuplicate] = useState();

  const todayDate = new Date();
  var DateFinMonth = todayDate.getMonth() + 6 > 12 ? (todayDate.getMonth() + 6) - 12 : todayDate.getMonth() + 6 ;
  var DateFinYear = todayDate.getMonth() + 6 > 12 ? todayDate.getFullYear() + 1 : todayDate.getFullYear() ;

  const [filters, setFilters] = useState({
    dates: {
      debut: `${todayDate.getFullYear()}-${(
        "0" +
        (todayDate.getMonth() + 1)
      ).slice(-2)}-01`,
      fin: `${DateFinYear}-${(
        "0" +
        (DateFinMonth)
      ).slice(-2)}-01`,
    },
  });

  const monthsName = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  //Récupère les couts Fixes
  const displayCoutsFixes = async () => {
    setIsLoading(true);
    await axiosClient.get("/couts-fixes", { params: filters }).then((res) => {
      generateCoutsList(res.data.coutsFixes);
    });
  };

  // Récupère les Catégories
  const displayCategories = async () => {
    await axiosClient.get("/categories-couts").then((res) => {
      setCategoriesCouts(res.data.categoriesCouts.map(category => ({
        label: category.label,
        value: category.id,
      })))
    });
  };

  // Méthode appelé pour éditer
  const handleEdit = async (id) => {
    await axiosClient.get(`/couts-fixes/${id}`).then((res) => {

      setCoutsFixesData(res.data.coutFixe);
      setShowAddModal(true);
    });
  };


  // Méthode appelée pour dupliquer un mois de couts fixes
  const handleDuplicate = async (month , year) => {
    let insertYear = month + 2 <= 12 ? year : year + 1;
    let insertMonth = month + 2 <= 12 ? (month + 2) : ((month + 2) - 12);
    setDateDuplicate(insertYear+ '-' + insertMonth +  '-01' );

    coutsFixes.filter((coutFixe) => coutFixe.month ==  month && coutFixe.year == year ).map((coutFixe) => {
      setCoutsFixesDuplicate(coutFixe.coutsFixes);
    })

    setShowModalDuplicate(true);
  }

  // Méthode appeler pour supprimer un mois de couts fixes
  const handleDelete = async (month , year) => {

    coutsFixes.filter((coutFixe) => coutFixe.month ==  month && coutFixe.year == year ).map((coutFixe) => {
      setCoutsFixesDuplicate(coutFixe.coutsFixes);
    })

    setShowModalDelete(true);
  }

  // Générète au bon format les données des couts fixes
  const generateCoutsList = async (data) => {
    setCoutsFixes([]);
    if(data.length > 0)
      await data.map((coutFixe) => {
        // Récupère le mois du cout
        const { cost_date } = coutFixe;
        const date = new Date(cost_date).getMonth();
        const dateY = new Date(cost_date).getFullYear();
        coutFixe.cost_date = `${("0" + (date + 1)).slice(-2)}/${dateY}`;  // Format de date à afficher
        coutFixe.costFormat = numberWithSpaces(coutFixe.cost)  // Format de nom à afficher

        setCoutsFixes((existingValues) => {
          let exist = false;
          existingValues.map((item, i) => {
            if (item?.month == date && item?.year == dateY) {
              // Si il existe alors on lui ajoute les nouvelles valeurs
              item.coutsFixes = [...item.coutsFixes, coutFixe];
              exist = true;
            }
          });

          // Si l'object existe, on l'a modifié on remplace
          if (exist) return existingValues;
          // Sinon on ajoute le nouvel objet
          return [
            ...existingValues,
            { month: date, year: dateY, coutsFixes: [coutFixe]},
          ];
        });
      });

      setIsLoading(false);
  };

  // Change l'état du filtre Catégorie quand une catégorie est sélectionnée
  const handleChangeCategoryFiltre = async (event) => {
    const { value, checked } = event.target;
    setFilters((prevState) => {
      const selectedIds = prevState.categories || [];
      const newSelectedIds = checked
        ? [...selectedIds, parseInt(value)]
        : selectedIds.filter((selectedId) => selectedId !== parseInt(value));
      return { ...prevState, categories: newSelectedIds };
    });
    displayCoutsFixes();
  };

  // Changement du filtre date
  const handleChangeDate = async () => {
    await setFilters((crtFilter) => {
      const dateDebut = parseDate(selectedDate.debut, 'dd/mm/yyyy', 'yyyy-mm-dd') 
      const dateFin = parseDate(selectedDate.fin, 'dd/mm/yyyy', 'yyyy-mm-dd')
      return { ...crtFilter, dates: { debut: dateDebut, fin: dateFin } };
    });
  };

  // Méthode pour reset les filtres
  const handleResetFilter = async () => {
    const todayDate = new Date();
    await setFilters(
      {
      dates: {
        debut: `${todayDate.getFullYear()}-${(
          "0" +
          (todayDate.getMonth() + 1)
        ).slice(-2)}-01`,
        fin: `${DateFinYear}-${(
          "0" +
          (DateFinMonth)
        ).slice(-2)}-01`,
      },
    });
    setReinitializeDate(true);
  };

  // Quand la date est mise à jour par le composant InputDate
  useEffect(() => {
    if (changedDate) {
      handleChangeDate();
      setChangedDate(false);
      setReinitializeDate(false);
    }
  }, [selectedDate]);

  useEffect(() => {
    displayCategories();
  }, []);

  //met à jour les couts fixes quand les fitres sonts mis à jour.
  useEffect(() => {
    displayCoutsFixes();
  }, [filters]);

  // Récupère les catégories quand ouvre le modal d'ajout/modification
  useEffect(() => {
    if (showAddModal) {
      displayCategories();
    }
  }, [showAddModal]);

  const handleAfterSubmitForm = ({success, message}) => {

    if(!success) addNotification({message: message.errorMsg, list: message.errorList}, 'danger')

    if(success){
      addNotification(message, 'success')

      setShowAddModal(false);
      setShowModalDuplicate(false);
      setShowModalDelete(false);

      axiosClient.get("/couts-fixes", { params: filters }).then((res) => {
        generateCoutsList(res.data.coutsFixes);
      });
    }
  }

  return (
        <div>
          {/* Bouton ajouter un Cout Fixe */}
          <button
            className={`inline-flex items-center text-white focus:outline-none focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 ml-auto bg-darkblue-600 hover:bg-darkblue-700 focus:ring-darkblue-700 border-darkblue-700`}
            onClick={() => {
              setCoutsFixesData(false);
              setShowAddModal(true);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            Ajouter un coût fixe
          </button>

          {/* Modal pour ajouter/éditer un Cout Fixe */}
          <ActionCoutsFixes
            showModal={showAddModal}
            setShowModal={setShowAddModal}
            coutsFixes={coutsFixesData}
            handleAfterSubmitForm={handleAfterSubmitForm}
          />

          <div className="p-4 pt-3 mt-4 rounded-lg bg-gray-200 relative">
            <span className="uppercase text-muted text-[12px] font-bold ">
              Filtrer par
            </span>

            <div className="flex">
              {/* Filtre catégorie */}
              <Dropdown
                buttonTitle="Catégorie"
                buttonClass="inline-flex items-center text-white rounded-lg p-2.5 bg-darkblue-600 hover:bg-darkblue-700 text-sm"
                menuClass="!mt-2 shadow-md"
                className="mt-2 mr-4"
              >
                {categoriesCouts.map((category, i) => {
                  return (
                    <DropdownListCheckbox
                      label={category.label}
                      value={category.id}
                      name={category.name}
                      closeOnCLick={false}
                      key={category.value}
                      onChange={handleChangeCategoryFiltre}
                      checked={filters.categories?.includes(category.id)}
                    />
                  );
                })}
              </Dropdown>

              {/* Filtre Date */}
              <div className="flex justify-between gap-2 mt-2">
                <div className="flex mr-4">
                  <span className="bg-darkblue-100 p-2 text-white rounded-tl-md rounded-bl-md select-none text-sm flex items-center">
                    Du
                  </span>
                  <InputDate
                    setSelectedDate={(data) =>
                      setSelectedDate((crtDate) => ({
                        ...crtDate,
                        debut: data,
                      }))
                    }
                    onChange={() => setChangedDate(true)}
                    defaultDate={filters.dates.debut}
                    dashboard={true}
                    customSelectClass={"!text-sm p-2.5"}
                    reinitialize={reinitializeDate}
                  />
                </div>
                <div className="flex">
                  <span className=" bg-darkblue-100 p-2 text-white rounded-tl-md rounded-bl-md select-none text-sm flex items-center">
                    Au
                  </span>
                  <InputDate
                    setSelectedDate={(data) =>
                      setSelectedDate((crtDate) => ({
                        ...crtDate,
                        fin: data,
                      }))
                    }
                    onChange={() => setChangedDate(true)}
                    defaultDate={filters.dates.fin}
                    dashboard={true}
                    customSelectClass={"!text-sm p-2.5"}
                    reinitialize={reinitializeDate}
                  />
                </div>
              </div>

              <span
                className="uppercase text-[12px] mt-4 ml-auto w-fit block cursor-pointer hover:underline"
                onClick={handleResetFilter}
              >
                Réinitialiser les filtres
              </span>
            </div>
          </div>

          
          {isLoading ?
          <div className="w-full flex justify-center my-2">
            <Loader />
          </div>
          : <>{(coutsFixes && coutsFixes.length > 0) ?
              coutsFixes.map((coutFixe, index) => {
                let totalCost = 0;

                return (
                  coutFixe.coutsFixes && coutFixe.coutsFixes.map((item) => {
                    if(parseInt(item.category.is_spent)) {
                      totalCost = parseFloat(totalCost , 2) + parseFloat(item.cost , 2);
                    } else {
                      totalCost = parseFloat(totalCost , 2) - parseFloat(item.cost , 2);
                    }

                  }),
                  <Accordion className="mt-4" key={index}>
                    
                    <AccordionTab
                      label={`${monthsName[coutFixe.month]} ${coutFixe.year}`}
                      buttonClassName="text-4xl font-bold border-b border-gray-200 p-2"
                      tabClassName="p-0"
                      className="my-10"
                      id={`coutsfixes-month-${index}`}
                      coutFixe={numberWithSpaces(totalCost.toFixed(2))}
                      duplicate={() => handleDuplicate(coutFixe.month,coutFixe.year)}
                      deleteCoutFixe={() => handleDelete(coutFixe.month,coutFixe.year)}
                    >                
                    <Table
                        mockData={coutFixe.coutsFixes.sort((a, b) => {
                          if (a.label < b.label) {
                            return -1;
                          }
                          if (a.label > b.label) {
                            return 1;
                          }
                          return 0;
                        })}
                        header={[
                          {
                            Header: "Désignation",
                            accessor: "label",
                            className: "p-2 text-uppercase",
                          },
                          {
                            Header: "Date",
                            accessor: "cost_date",
                            className: "p-2 text-uppercase",
                          },
                          {
                            Header: "Catégorie",
                            accessor: "category.label",
                            className: "p-2 text-uppercase",
                          },
                          {
                            Header: "Montant",
                            accessor: "costFormat",
                            className: "p-2 text-uppercase",
                          }
                        ]}
                        action={true}
                        afficherSupprimer={true}
                        afficherEditer={true}
                        editer={handleEdit}
                        apiUrl="couts-fixes"
                        handleAfterSubmitForm={handleAfterSubmitForm}
                        TheadClassName="bg-transparent font-regular text-black"
                      />
                    </AccordionTab>
                  </Accordion>
                );
              })
              : <div><p className="p-4 pt-3 mt-4 rounded-lg bg-gray-100 relative text-center">Aucun résultat ne correspond à votre recherche</p></div>
          }
            </>
        }
        
        <DuplicateCoutsFixes coutsFixes={coutsFixesDuplicate} showModal={ShowModalDuplicate} setShowModal={setShowModalDuplicate} date={dateDuplicate} handleAfterSubmitForm={handleAfterSubmitForm}/>
        <DeleteCoutsFixes coutsFixes={coutsFixesDuplicate} showModal={ShowModalDelete} setShowModal={setShowModalDelete} handleAfterSubmitForm={handleAfterSubmitForm}/>
        </div>
  );
};

export default CoutsFixes;
