import React from "react";
import Modal from "../../components/Modal/Modal";
import ModalHeader from "../../components/Modal/ModalHeader";
import ModalBody from "../../components/Modal/ModalBody";
import ModalSupprimer from "../../components/Modal/ModalSupprimer";
import EditProjet from "./Form/EditProjet";
import CopyProjet from "./Form/CopyProjet";
import AddProjetWithClient from "./Form/AddProjetWithClient";

const ActionProjets = ({ modalData, showModal, setShowModal, handleAfterSubmitForm }) => {
  const typesList = {
    editProjet: <EditProjet projetValues={modalData} handleAfterSubmitForm={handleAfterSubmitForm} />,
    copyProjet: <CopyProjet projetValues={modalData} handleAfterSubmitForm={handleAfterSubmitForm} />,
    // addWithClient: <AddProjetWithClient projetValues={modalData} handleAfterSubmitForm={handleAfterSubmitForm} />,
  }

  return modalData.delete ? (
    <ModalSupprimer 
      showModal={showModal}
      setShowModal={setShowModal}
      apiUrl={modalData.apiUrl}
      id={modalData.id}
      handleAfterSubmitForm={handleAfterSubmitForm}
    />
  ) : showModal ?
    (
      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <ModalHeader
          title={modalData.title}
          modalHeaderContainerclassName="p-0 pb-4"
        />

        <ModalBody modalBodyContainerclassName="px-0 pt-4 pb-0">
          {typesList[modalData.type]}
        </ModalBody>
      </Modal>
    )
    : '';
};

export default ActionProjets;