import React from "react";
import { Link, useLocation } from "react-router-dom";
import axiosClient from "../axios-client";
import { useStateContext } from "../contexts/ContextProvider";
import Dropdown from "../components/Dropdown/Dropdown";
import Logo from "../assets/images/logo_Aioli.png";

const Header = ({ user }) => {
  const { setUser, setAuthToken } = useStateContext();

  //   Récupère la route actuelle
  const pathname = useLocation().pathname;

  //   Déconnect l'utilisateur
  const onLogout = (evt) => {
    evt.preventDefault();

    axiosClient.post("/logout").then(() => {
      setUser({});
      setAuthToken(null);
    });
  };

  return (
    <header id="header" className="relative z-50">
      <div className="px-8 mx-auto xl:px-5 container">
        <div className="flex items-center justify-between h-20 border-b-2 border-gray-100 md:justify-start md:space-x-6">
          <div className="inline-flex">
            <Link to="/">
              <img src={Logo} alt="Logo aïoli" className="w-20" />
            </Link>
          </div>

          <div className="flex h-full md:flex-1">
            <div className="flex-1 hidden h-full space-x-8 md:flex">
              <Link
                to="/"
                className={`inline-flex items-center px-1 pt-1 text-sm font-medium leading-5 transition duration-150 ease-in-out focus:outline-none border-b-2 text-gray-900 focus:border-darkblue-700 ${
                  pathname === "/"
                    ? "border-darkblue-700"
                    : "border-transparent"
                } `}
              >
                Accueil
              </Link>

              {(user.role.name === "admin" || user.role.name === "manager") && (
                <Link
                  to="/management"
                  className={`inline-flex items-center px-1 pt-1 text-sm font-medium leading-5 transition duration-150 ease-in-out focus:outline-none border-b-2 text-gray-900 focus:border-darkblue-700 ${
                    pathname === "/management"
                      ? "border-darkblue-700"
                      : "border-transparent"
                  } `}
                >
                  Management
                </Link>
              )}

              {user.role.name === "admin" && (
                <Link
                  to="/administration"
                  className={`inline-flex items-center px-1 pt-1 text-sm font-medium leading-5 transition duration-150 ease-in-out focus:outline-none border-b-2 text-gray-900 focus:border-darkblue-700 ${
                    pathname === "/administration"
                      ? "border-darkblue-700"
                      : "border-transparent"
                  } `}
                >
                  Synthèse
                </Link>
              )}

              {user.role.name === "admin" && (
                <Link
                  to="/administration/couts-fixes"
                  className={`inline-flex items-center px-1 pt-1 text-sm font-medium leading-5 transition duration-150 ease-in-out focus:outline-none border-b-2 text-gray-900 focus:border-darkblue-700 ${
                    pathname === "/administration/couts-fixes"
                      ? "border-darkblue-700"
                      : "border-transparent"
                  } `}
                >
                  Coûts Fixes
                </Link>
              )}

              {/* Administration */}
              {user.role.name === "admin" && (
                <div className={`flex sm:ml-6 sm:items-center relative px-1 pt-1 transition duration-150 ease-in-out focus:outline-none border-b-2 focus:border-darkblue-700 ${
                      pathname.startsWith("/administration/") && pathname != "/administration/couts-fixes"
                        ? "border-darkblue-700"
                        : "border-transparent"
                    } `}>
                  <Dropdown buttonTitle="Administration" menuClass="right-0 w-56" buttonClass={`flex items-center text-sm font-medium leading-5 text-gray-900`}>
                    <div className="p-2">
                      <span className="hidden px-2 pt-3 pb-1 text-xs font-semibold leading-4 tracking-wider text-gray-500 uppercase md:block">
                        Projets
                      </span>
                      <Link
                        to="/administration/clients"
                        className="block px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700"
                      >
                        Clients
                      </Link>
                      <Link
                        to="/administration/categories"
                        className="block px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700"
                      >
                        Catégories
                      </Link>
                      <Link
                        to="/administration/employes"
                        className="block px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700"
                      >
                        Employés
                      </Link>
                      <Link
                        to="/administration/poles"
                        className="block px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700"
                      >
                        Pôles
                      </Link>
                    </div>

                    <hr />

                    <div className="p-2">
                      <span className="hidden px-2 pt-3 pb-1 text-xs font-semibold leading-4 tracking-wider text-gray-500 uppercase md:block">
                        Coûts Fixes
                      </span>
                      <Link
                        to="/administration/couts-fixes"
                        className="block px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700"
                      >
                        Coûts Fixes
                      </Link>
                      <Link
                        to="/administration/couts-fixes/categories"
                        className="block px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700"
                      >
                        Catégories
                      </Link>
                    </div>

                    <hr />

                    <div className="p-2">
                      <span className="hidden px-2 pt-3 pb-1 text-xs font-semibold leading-4 tracking-wider text-gray-500 uppercase md:block">
                        Role & Permission
                      </span>
                      <Link
                        to="/administration/roles"
                        className="block px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700"
                      >
                        Rôles
                      </Link>
                      <Link
                        to="/administration/permissions"
                        className="block px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700"
                      >
                        Permissions
                      </Link>
                    </div>
                  </Dropdown>
                </div>
              )}
            </div>

            <div className="flex sm:ml-6 sm:items-center relative">
              <Dropdown buttonTitle={user.first_name} menuClass="right-0 w-56">
                <div className="p-2">
                  <Link
                    to="/profil"
                    className="block px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700"
                  >
                    Profil
                  </Link>
                  <Link
                    to="/profil/projets"
                    className="block px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700"
                  >
                    Projets
                  </Link>
                </div>
                <hr />

                <div className="p-2">
                  <button
                    className="block px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700"
                    onClick={onLogout}
                  >
                    Se déconnecter
                  </button>
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
