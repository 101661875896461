import React, { useContext, useEffect, useState } from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import { NotificationContext } from "../../contexts/NotificationContext";
import Form from "../../components/Form/Form";

const Profile = () => {
  const { user, getUser } = useStateContext();
  const { addNotification } = useContext(NotificationContext);

  /** FORMULAIRE */
  const handleAfterSubmitForm = ({success, message, data, debug}) => {
    if(!success) addNotification({message: message.errorMsg, list: message.errorList}, 'danger')

    if(success){
      addNotification(message, 'success')
      getUser();
    }
  };
  
  // Valeur par défaut du formulaire
  const initialValues = {
    last_name: user.last_name,
    first_name: user.first_name,
    email: user.email,
  }

  // Définit les champs du formulaire
  const fields = [
    { group: [
      { name: "last_name", label: "Nom", disabled: true },
      { name: "first_name", label: "Prénom", disabled: true },
      { name: "email", label: "Email", type: "email", disabled: true },
      { name: "password", label: "Mot de passe", type: "password" },
    ], groupClassName: "grid gap-4 sm:grid-cols-2 mt-0 mb-6"},
  ];

  return (
    <div id="profile" className="flex">
      {/* Profile form */}
      <div className="w-full relative px-6 py-3 mb-6 bg-white border rounded-lg border-gray-150">
        <h1 className="text-3xl mb-4 font-bold">Modifier mon profil</h1>

        <Form
          buttonTitle="Modifier"
          method="post"
          apiUrl={`profile/edit/${user.id}`}
          fields={fields}
          initialValues={initialValues}
          onSubmit={handleAfterSubmitForm}
        />
      </div>

      
    </div>
  );
};

export default Profile;
