import React, { useEffect, useState } from "react";
import Modal from "../../components/Modal/Modal";
import ModalHeader from "../../components/Modal/ModalHeader";
import ModalBody from "../../components/Modal/ModalBody";
import Form from "../../components/Form/Form";
import axiosClient from "../../axios-client";

const ActionEmployes = ({
  showAddModal,
  setShowAddModal,
  handleAfterSubmitForm,
  employe = false,
}) => {
  const [roles, setRoles] = useState([]);

  const displayRoles = async () => {
    await axiosClient.get("/roles").then((res) => {
      setRoles(res.data.roles.map(role => {
        return {...role, label: role.name, value: role.id};
      }));
    });
  }
  const [poles, setPoles] = useState([]);

  const displayPoles = async () => {
    await axiosClient.get("/poles").then((res) => {
      setPoles(res.data.poles.map(pole => {
        return {...pole, label: pole.name, value: pole.id};
      }));
    });
  }

  // Récupère les roles
  useEffect(() => {
    if(showAddModal){
      displayRoles(); 
      displayPoles();
    }
  }, [showAddModal])

  /** FORMULAIRE */
  const initialValues = {
    last_name: employe ? employe.last_name : undefined,
    first_name: employe ? employe.first_name : undefined,
    email: employe ? employe.email : undefined,
    password: employe ? employe.password : undefined,
    hourly_price: employe ? employe.hourly_price : undefined,
    number_hours: 35,
    role_id: employe ? employe.role_id : undefined,
    pole_id: employe ? employe.pole_id : undefined,
    active: employe ? parseInt(employe.active) : true,
  }

  const fields = [
    {group: [
      { name: 'last_name', label: 'Nom' },
      { name: 'first_name', label: 'Prénom' },
      { name: 'email', label: 'Email', type: 'email' },
      { name: 'password', type:'password', label: 'Mot de passe' },
      { name: 'hourly_price', label: 'Prix horaire', type: 'number' },
      { name: 'number_hours', type: 'hidden', customClass: 'hidden' },
      { name: 'role_id', label: 'Rôle', type: "select", options: roles},
      { name: 'pole_id', label: 'Pôle', type: "select", options: poles},
    ], groupClassName: "grid gap-4 sm:grid-cols-2 mt-0 mb-6"},
    { name: 'active', label: "Employé actif", type: "toggle" },
  ]

  return (
    <Modal
      show={showAddModal}
      onClose={() => setShowAddModal(false)}
      modalContainerclassName="p-4 sm:p-5"
    >
      <ModalHeader
        title={`${employe ? "Modifier" : "Ajouter"} un employé`}
        modalHeaderContainerclassName="p-0 pb-4"
      />

      <ModalBody modalBodyContainerclassName="px-0 pt-4 pb-0">
        <Form
            buttonTitle={(employe) ? "Modifier" : "Ajouter"}
            method={(employe) ? "put" : "post"}
            apiUrl={(employe) ? `employes/${employe.id}` : `employes`}
            fields={fields}
            initialValues={initialValues}
            onSubmit={handleAfterSubmitForm}
        />
      </ModalBody>
    </Modal>
  );
};

export default ActionEmployes;
