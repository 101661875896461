import React, { useEffect, useState } from "react";
import Table from "../../components/Table/Table";
import axiosClient from "../../axios-client";
import Filters from "./Filters";
import { getMonthDifferenceBetweenDates, generateTableHeader, checkHasFilters, months  } from "../../components/Utils/Filters";
import { formatDate, getMonthDiff, parseDate } from "../../components/Utils/Dates";
import Sidebar from "../../components/Sidebar";
import { numberWithSpaces } from "../../components/Utils/CalculROI";
import CategorieChart from "./Graphiques/CategorieChart";
import AllCategoriesChart from "./Graphiques/AllCategoriesChart";
import ROIChart from "./Graphiques/ROIChart";
import CAChart from "./Graphiques/CAChart";

const Administration = () => {
  const todayDate = new Date();
  todayDate.setDate(1); // Permet de partir du début du mois
  // Calcul la date dans 6 mois
  const sixMonthsLater = new Date(todayDate);
  sixMonthsLater.setMonth(sixMonthsLater.getMonth() + 6);
  const defaultFilters = {dates: {debut: formatDate(todayDate, 'yyyy-mm-01'), fin: formatDate(sixMonthsLater, 'yyyy-mm-01')}, previsionnel: 'all', recurrence: 'all', categories: []};
  const [filters, setFilters] = useState(defaultFilters);
  const [tableData, setTableData] = useState();
  const [tableHeader, setTableHeader] = useState([]);
  const [coutsFixes, setCoutsFixes] = useState([]);

  const [isTableShowed, setIsTableShowed] = useState(true);

  const displayCoutFixe = async () => {
    await axiosClient
      .get("/couts-fixes/roi", {
        params: 
          {
            datedebut: filters.dates.debut,
            datefin: filters.dates.fin,
          }
        ,
      })
      .then((res) => {
        const {coutsFixes} = res.data;
        const dateDebut = parseDate(filters.dates.debut)
        const monthsDiff  = getMonthDiff(filters.dates.debut, filters.dates.fin)
        let monthsCF = [];

        for(let i = 0; i <= monthsDiff; i++) {
          const crtDate = new Date(filters.dates.debut);
          crtDate.setMonth(crtDate.getMonth() + i);
          const formatedDate = formatDate(crtDate, 'yyyy-mm-01');

          const listCF = coutsFixes.filter((coutFixe) => coutFixe.cost_date === formatedDate);
          const sommeCF = listCF.reduce((acc, coutFixe) => {
            const is_spent = coutFixe.category.is_spent;

            return (is_spent) 
              ? parseFloat(acc) + parseFloat(coutFixe.cost)
              : parseFloat(acc) - parseFloat(coutFixe.cost)
          }, 0)

          monthsCF[i] = sommeCF
        }        
        setCoutsFixes(monthsCF);
      });
  };

  // Récupère et socke la liste des donées
  const getAllData = () => {
    const hasFilters = checkHasFilters(filters, defaultFilters, ['dates']);

      axiosClient.get("/administration", {params: {...filters, hasFilter: hasFilters, order: 'asc'}}).then((res) => {
        const {clients} = res.data;

        if(clients.length > 0) {
          clients.map((client) => {
              client.months.map((crtMonth) => {
                if(crtMonth.projects.length > 0){
                  // Calcul la somme des coûts des prestations et employés
                  const sommeCoutTotal = crtMonth.projects.reduce((acc, crtValue) => {
                    const coutPrestations = (crtValue.benefits.length > 0) ? crtValue.benefits.reduce((prestaAccumulator, prestaValue) => prestaAccumulator + parseFloat(prestaValue.cost), 0) : 0;
                    const coutEmployes = (crtValue.time_employees.length > 0) ? crtValue.time_employees.reduce((tempsAccumulator, tempsValue) => tempsAccumulator + (tempsValue.employee_hour * tempsValue.cost_per_hour), 0) : 0;
                    
                    // return acc + parseFloat(coutPrestations) + parseFloat(coutEmployes)
                    return acc + parseFloat(coutPrestations)
                  }, 0);
    
                  const sommePrixVente = crtMonth.projects.reduce((acc, crtValue) => acc + parseFloat(crtValue.sell_price), 0)

                  crtMonth.prixVente = sommePrixVente;
                  crtMonth.coutTotal = sommeCoutTotal;
                }else{
                  crtMonth.prixVente = 0;
                  crtMonth.coutTotal = 0;
                }
              })

              // Met le libellé du client en majuscule
              client.label = client.label.toUpperCase();
          })

          var totalRow = {label: 'TOTAL', isTotal: true, months: []};
          const dateDifference = getMonthDifferenceBetweenDates(filters.dates.debut, filters.dates.fin);
          
          for(let i = 0; i <= dateDifference; i++) {
            const totalPv = clients.reduce((acc, crtValue) => acc + crtValue.months[i].prixVente, 0);
            const totalCout = clients.reduce((acc, crtValue) => acc + crtValue.months[i].coutTotal, 0) + coutsFixes[i];

            const totalRoi = ((totalCout / totalPv) * 100).toFixed(2)

            totalRow.months[i] = {prixVente: numberWithSpaces(totalPv, 2), roi: (totalCout > 0) ? totalRoi : 0};
          }
          
          setTableData([totalRow].concat(clients));
        }else{
          setTableData(clients);
        }

      });
    };
  
    useEffect(() => {
      getAllData();
    }, [coutsFixes])

    // useEffect(() => {
    //   displayCoutFixe();
    //   setTableData();
    //   setTableHeader(generateTableHeader(filters.dates.debut, filters.dates.fin));
    // }, [filters])

  // Lorsque que l'on clique sur filtrer on remet à jour les données
  const handleSubmitFilter = () => {
      displayCoutFixe();
      setTableData();
      setTableHeader(generateTableHeader(filters.dates.debut, filters.dates.fin));
  }

  // Initialiser les données lors du chargement de la page
  useEffect(() => {
    displayCoutFixe();
      setTableData();
      setTableHeader(generateTableHeader(filters.dates.debut, filters.dates.fin));
  }, [])

  return (
    <>
      <div className="flex gap-8">
        <div className="w-3/12">
          <Sidebar
            className="!mb-0"
            containerClassName="!mb-0"
            list={[
              {
                name: "Synthèse",
                content: [
                  {
                    name: "Tableau",
                    onClick: () => setIsTableShowed(true),
                    isActive: isTableShowed ? true : false,
                    icon: (
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="flex-shrink-0 w-5 h-5 mr-3 -ml-1 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-500 group-focus:text-gray-500">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />
                      </svg>
                    ),
                  },
                  {
                    name: "Graphique",
                    onClick: () => setIsTableShowed(false),
                    isActive: isTableShowed ? false : true,
                    icon: (
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="flex-shrink-0 w-5 h-5 mr-3 -ml-1 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-500 group-focus:text-gray-500">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
                      </svg>
                    ),
                  },
                ],
              },
            ]}
          />
        </div>
        <Filters filters={filters} setFilters={setFilters} isTableShowed={isTableShowed} handleSubmitFilter={handleSubmitFilter}/>
      </div>

      <div className="relative overflow-x-auto sm:rounded-lg pt-8">
        {
          isTableShowed 
            ? tableData && (
                <Table
                  mockData={tableData}
                  header={tableHeader}
                />
              )
            : (
              <div>
                <div className="flex gap-8 flex-wrap">
                  <ROIChart filters={filters} />
                  <AllCategoriesChart filters={filters} />
                  <CAChart filters={filters} />
                  <CategorieChart />
                </div>
            </div>
            )
        }
      </div>
    </>
  );
};

export default Administration;