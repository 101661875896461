import React from "react";
import Modal from "../../components/Modal/Modal";
import ModalHeader from "../../components/Modal/ModalHeader";
import ModalBody from "../../components/Modal/ModalBody";
import Form from "../../components/Form/Form";
import { slugify } from "../../components/Utils/Slug";

const ActionClients = ({
  showModal, setShowModal, handleAfterSubmitForm, client= false
}) => {
   /** FORMULAIRE */
   const initialValues = {
    label: client ? client.label: undefined,
    name: client ? client.name : undefined,
   }

   const fields = [
    {group: [
        { name: 'label', label: 'Nom', updateWhenInputChange: {name: 'name', value: ({value}) => slugify(value)}},
        { name: 'name', type: 'hidden' },
    ]}
  ]
  
  return(
      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <ModalHeader
          title={`${client ? "Modifier" : "Ajouter"} un client`}
          modalHeaderContainerclassName="p-0 pb-4"
        />
        
        <ModalBody modalBodyContainerclassName="px-0 pt-4 pb-0">
          <Form
            buttonTitle={(client) ? "Modifier" : "Ajouter"}
            method={(client) ?"put" : "post"}
            apiUrl={(client) ? `clients/${client.id}` : `clients`}
            fields={fields}
            initialValues={initialValues}
            onSubmit={handleAfterSubmitForm}
            
          />
        </ModalBody>
      </Modal>
    )
};

export default ActionClients;
