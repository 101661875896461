import React, { useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import { formatDate, parseDate } from "../../components/Utils/Dates";
import { useStateContext } from "../../contexts/ContextProvider";
import InputDate from "../../components/Form/InputDate";

const Projet = () => {
  const { user, setUser } = useStateContext();

  const [crtDateSelected, setCrtDateSelected] = useState();

  const date = new Date();
  const listeMois = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Sept.",
    "Octobre",
    "Nov.",
    "Déc.",
  ];

  // Appel API pour récupérer les informations de l'utilisateur ainsi que ses projets en fonction de la date
  const changeSelectedMonthData = async () => {
    const parameters = crtDateSelected
      ? { date: formatDate(crtDateSelected, "yyyy-mm-dd") }
      : {};
    await axiosClient.get("/user", { params: parameters }).then(({ data }) => {
      setUser(data);
      // user = data;
    });
  };

  useEffect(() => {
    changeSelectedMonthData();
  }, [crtDateSelected]);

  // Affiche le mois et l'année en fonction de la date sélectionné
  const getDateLabel = () => {
    const month = crtDateSelected
      ? listeMois[crtDateSelected.getMonth()]
      : listeMois[date.getMonth()];
    const year = crtDateSelected
      ? crtDateSelected.getFullYear()
      : date.getFullYear();

    return month + " " + year;
  };

  return (
    <div>
      {/* Profile stats */}
      <div className="flex-1 mx-4 sm:mx-auto sm:pl-6 lg:pl-8 max-w-full">

        <div className="flex justify-between border-b border-gray-150 mb-4">
            <h2 className="text-3xl mb-4 font-bold">
              Projets - {getDateLabel()}
            </h2>

            <InputDate
              setSelectedDate={(data) =>
                setCrtDateSelected(parseDate(data, "dd/mm/yyyy"))
              }
            />
        </div>

        <div className="pb-2">
          <div className="sm:flex sm:space-x-4">
            <div className="inline-block align-bottom bg-rose-100 rounded-lg text-left overflow-hidden transform transition-all mb-4 w-full sm:w-1/2">
              <div className="p-5">
                <div className="sm:flex sm:items-start">
                  <div className="text-center sm:mt-0 sm:ml-2 sm:text-left">
                    <h3 className="text-sm leading-6 font-medium text-gray-100">
                      Total heures du mois
                    </h3>
                    <p className="text-3xl font-bold text-white">
                      {parseFloat(user?.statistics.totalHours)}h
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="inline-block align-bottom bg-yellow-500 rounded-lg text-left overflow-hidden transform transition-all mb-4 w-full sm:w-1/2">
              <div className="p-5">
                <div className="sm:flex sm:items-start">
                  <div className="text-center sm:mt-0 sm:ml-2 sm:text-left">
                    <h3 className="text-sm leading-6 font-medium text-gray-100">
                      Total projets du mois
                    </h3>
                    <p className="text-3xl font-bold text-white">
                      {user?.statistics.totalProjects}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <table className="w-full text-left border-collapse">
              <thead>
                <tr>
                  <th className="sticky z-10 top-0 text-sm leading-6 font-semibold text-slate-700 p-0 w-1/3">
                    <div className="py-2 pr-2 border-b border-slate-200">
                      Projet
                    </div>
                  </th>
                  <th className="sticky z-10 top-0 text-sm leading-6 font-semibold text-slate-700 p-0">
                    <div className="py-2 pl-2 border-b border-slate-200">
                      Heures
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="align-baseline">
                {user?.projects.map((projet, i) => (
                  <tr key={i}>
                    <td
                      translate="no"
                      className={`py-2 pr-2 font-medium text-xs leading-6 text-darkblue-500 whitespace-nowrap ${
                        i > 0 ? "border-t border-slate-100" : ""
                      }`}
                    >
                      {projet.customer} - {projet.label}
                    </td>
                    <td
                      translate="no"
                      className={`py-2 pl-2 text-xs leading-6 text-rose-600 whitespace-pre ${
                        i > 0 ? "border-t border-slate-100" : ""
                      }`}
                    >
                      {parseFloat(projet.totalHeures)}h
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projet;
