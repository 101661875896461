import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import axiosClient from "../../../axios-client";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ROIChart = ({ filters }) => {
  const [chartData, setChartData] = useState([]);

  const options = {
    indexAxis: "x",
    responsive: true,
    aspectRatio: 2.5,
    layout: {
      padding: {
        left: 24,
        right: 24,
        top: 24,
      }
    },

    plugins: {
      legend: {
        position: "right",
        labels: {
          font: {
            family: "Montserrat, sans-serif",
          },
          usePointStyle: true,
          pointStyle: "circle",
          padding: 20
        },
      },

      
    },
    scales: {
      y: {
        ticks: {
          maxTicksLimit: 5,
          font: {
            family: "Montserrat, sans-serif",
            weight: "400",
          },
        },
        border: {
          display: false
        }
      },

      x: {
        ticks: {
          font: {
            family: "Montserrat, sans-serif",
            weight: "400",
          },
        },
        grid: {
          display: false
        }
      },
    },
    elements: {
      bar: {
        borderRadius: {
          topLeft: 100,
          topRight: 100,
        },
        borderSkipped: false,
      },
    },
  };

  const data = {
    labels: chartData.labels,
    datasets: [
      {
        label: "Prix de vente",
        data: chartData.prixVente,
        backgroundColor: "rgba(56, 44, 129, 1)",
        categoryPercentage: 0.5,
        barPercentage: 0.75,
      },
      {
        label: "Coûts",
        data: chartData.cout,
        backgroundColor: "rgb(237, 41, 133)",
        categoryPercentage: 0.5,
        barPercentage: 0.75,
      },
    ],
  };

  const calculTotal = (array, dataToSum, isMontly) => {
    return array.reduce((acc, item) => {
      if (item.monthly === isMontly)
        return parseFloat(acc) + parseFloat(item[dataToSum]);

      return acc;
    }, 0);
  };

  useEffect(() => {
    // Récupère les catégories en fonction des filtres quand ceux-ci sont mis à jour
    const getChartData = () => {
      axiosClient
        .get(`/administration/graphiques/roi`, { params: filters })
        .then((res) => {
          const { coutsFixes, projets } = res.data;

          if (projets.length > 0) {
            // Calcul le total des coûts fixes
            const totalCoutsFixes = coutsFixes.reduce((acc, coutFixe) => {
              return (
                acc +
                (coutFixe.category.is_spent
                  ? parseFloat(coutFixe.cost)
                  : -parseFloat(coutFixe.cost))
              );
            }, 0);

            const mensuel = {
              prixVente: calculTotal(projets, "sell_price", 0),
              cout: calculTotal(projets, "benefits_cost", 0) + totalCoutsFixes,
              coutSansCoutFixe: calculTotal(projets, "benefits_cost", 0),
            };

            const oneshot = {
              prixVente: calculTotal(projets, "sell_price", 1),
              cout: calculTotal(projets, "benefits_cost", 1) + totalCoutsFixes,
              coutSansCoutFixe: calculTotal(projets, "benefits_cost", 1),
            };

            const total = {
              prixVente: mensuel.prixVente + oneshot.prixVente,
              cout:
                mensuel.coutSansCoutFixe +
                oneshot.coutSansCoutFixe +
                totalCoutsFixes,
            };

            const labels = [
              [
                "Projets Mensuel",
                `ROI: ${((mensuel.cout / mensuel.prixVente) * 100).toFixed(
                  2
                )}%`,
              ],
              [
                "Projets Oneshot",
                `ROI: ${((oneshot.cout / oneshot.prixVente) * 100).toFixed(
                  2
                )}%`,
              ],
              [
                "Total",
                `ROI: ${((total.cout / total.prixVente) * 100).toFixed(2)}%`,
              ],
            ];

            setChartData({
              labels,
              prixVente: [
                mensuel.prixVente,
                oneshot.prixVente,
                total.prixVente,
              ],
              cout: [mensuel.cout, oneshot.cout, total.cout],
              total: total,
            });
          } else {
            setChartData({ empty: true });
          }
        });
    };

    getChartData();
  }, [filters]);

  return (
    <div className="flex-1 bg-white py-6 border rounded-lg border-gray-150">
      <h2 className="text-center">Projets ROI</h2>
      {chartData.empty ? (
        <span>Pas assez de données pour afficher le graphique</span>
      ) : (
        <Bar options={options} data={data} />
      )}
    </div>
  );
};

export default ROIChart;
