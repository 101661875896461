import React from "react";

const AccordionTab = ({
  label,
  className,
  buttonClassName,
  tabClassName,
  children,
  onClick,
  open = false,
  hideArrow = false,
  coutFixe,
  duplicate,
  deleteCoutFixe,
}) => {
  return (
    <div className={className}>
      <h2>
        <button
          type="button"
          className={`flex items-center justify-between w-full cursor-pointer ${buttonClassName
            ? buttonClassName
            : "flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 hover:bg-gray-100"
            }`}
          onClick={onClick}
        >
          <span className="flex-1 text-left">{label}</span>
          {coutFixe && <span className=" font-medium text-2xl mr-4">{coutFixe} €</span>}
          {coutFixe &&
            <div className="flex justify-around items-center mx-4">

              {/* Dupliquer */}
              <span className="cursor-pointer mr-1 outline-none focus:outline-none supprimer" onClick={duplicate}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor" className="w-5 h-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 8.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v8.25A2.25 2.25 0 006 16.5h2.25m8.25-8.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-7.5A2.25 2.25 0 018.25 18v-1.5m8.25-8.25h-6a2.25 2.25 0 00-2.25 2.25v6" />
                </svg>
              </span>

              {/* Supprimer */}
              <span className="cursor-pointer mr-1 outline-none focus:outline-none supprimer" onClick={deleteCoutFixe}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>
              </span>
            </div>}

          {!hideArrow &&
            <svg
              className={`w-6 h-6 ${open ? "rotate-180 " : " "}shrink-0`}
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          }
        </button>
      </h2>
      <div className={open ? "" : "hidden"}>
        <div className={tabClassName ? tabClassName : "p-5"}>{children}</div>
      </div>
    </div>
  );
};

export default AccordionTab;
