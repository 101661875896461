import React from 'react';

const Loader = () => {
    return (
        <>

            <div className="loader">
                <div className='absolute w-full h-full bg-white'></div>
                <div className="container-loader">
                    <div className="container-box">
                        <div className="box1"></div>
                        <div className="box2"></div>
                        <div className="box3"></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Loader;