import { numberWithSpaces } from "./CalculROI";

const months = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];

/** ---- GLOBAL ---- */

const checkHasFilters = (
  filters,
  defaultFilters,
  excludeProperties = false
) => {
  return Object.keys(filters).some((key) => {
    if (excludeProperties && excludeProperties.includes(key)) {
      return false; // Ignorer la/les propriétées spécificées dans le paramètre
    }

    if (typeof filters[key] === "object" && filters[key] !== null) {
      return (
        JSON.stringify(filters[key]) !== JSON.stringify(defaultFilters[key])
      );
    }
    return filters[key] !== defaultFilters[key];
  });
};

/** ---- VUE ADMINISTRATION ---- */
const getMonthDifferenceBetweenDates = (debut, fin) => {
  const firstDate = new Date(debut);
  const endDate = new Date(fin);

  return (
    endDate.getFullYear() * 12 +
    endDate.getMonth() -
    (firstDate.getFullYear() * 12 + firstDate.getMonth())
  );
};

const generateTableHeader = (debut, fin) => {
  const dateDifference = getMonthDifferenceBetweenDates(debut, fin);
  const firstDate = new Date(debut).getMonth();

  var tableHeader = [{ Header: "Nom", accessor: "label" }];
  for (let i = 0; i <= dateDifference; i++) {
    const monthIndex = (firstDate + i) % 12;

    tableHeader = [
      ...tableHeader,
      {
        Header: months[monthIndex],
        accessor: `months[${i}]`,
        Cell: ({ row }) => {
          const roiValue =
            row.original.months[i].roi <= 37.0
              ? "text-green-500"
              : "text-rose-500";
          return (
            <>
              {numberWithSpaces(row.original.months[i].prixVente)} €
              {row.original.isTotal && (
                <>
                  <br />{" "}
                  <span className={roiValue}>
                    {row.original.months[i].roi}%
                  </span>
                </>
              )}
            </>
          );
        },
      },
    ];
  }
  return tableHeader;
};

export {
  months,
  getMonthDifferenceBetweenDates,
  generateTableHeader,
  checkHasFilters,
};
