import React, { useEffect, useState } from 'react'
import Tableau from './Tableau'
import axiosClient from '../../axios-client';
import Loader from '../../components/Loader/Loader';
import Filters from './Filters';
import { formatDate } from '../../components/Utils/Dates';

const Management = () => {
    const todayDate = new Date();
    todayDate.setDate(1); // Permet de partir du début du mois
    // Calcul la date dans 3 mois
    const sixMonthsLater = new Date(todayDate);
    sixMonthsLater.setMonth(sixMonthsLater.getMonth() + 3);

    const [afficherTableauGlobal, setAfficherTableauGlobal] = useState(false);
    const [filters, setFilters] = useState({dates: {debut: formatDate(todayDate, 'yyyy-mm-01'), fin: formatDate(sixMonthsLater, 'yyyy-mm-01')}, projetsType: 'all', pole: 'all'});
    const [isLoading, setIsLoading] = useState(false);
    
    // Récupère et socke la liste des donées
    const [data, setData] = useState([]);
    const getAllData = async () => {
        await axiosClient.get("/management", {params: filters}).then((res) => {
            setData({listeClients: res.data.listeClients, listeDonnees: res.data.liste, listeEmployes: res.data.listeEmployes});
            setIsLoading(true)
            
          });
      };

    // Lorsque que l'on clique sur filtrer on remet à jour les données
    const handleSubmitFilter = () => {
      getAllData();
    }

    // Initialiser les données lors du chargement de la page
    useEffect(() => {
      getAllData();
    }, [])

  return (
    <div>
        
        {isLoading 
            ? <> 
                <Filters setFilters={setFilters} filters={filters} setAfficherTableauGlobal={setAfficherTableauGlobal} tableauGlobal={afficherTableauGlobal} handleSubmitFilter={handleSubmitFilter}/> 
                <Tableau listeClients={data.listeClients} listeDonnees={data.listeDonnees} listeEmployes={data.listeEmployes} afficherTableauGlobal={afficherTableauGlobal} filtersDate={{debut: filters.dates.debut, fin: filters.dates.fin}}/> 
              </>
            : <Loader /> 
        }
        
        
    </div>

  )
}

export default Management