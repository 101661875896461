import React, { useContext, useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import Table from "../../components/Table/Table";
import { NotificationContext } from "../../contexts/NotificationContext";
import ActionsPoles from "./ActionsPoles";

const Poles = () => {

    const { addNotification } = useContext(NotificationContext);
    const [poles, setPoles] = useState([]);
    const [poleData, setPoleData] = useState([]);

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        displayPoles();
    }, []);

    const displayPoles = async () => {
        await axiosClient.get('/poles').then((res) => {
            setPoles(res.data.poles);
        })
    };

    const handleEdit = async (id) => {
        await axiosClient.get(`/poles/${id}`).then((res) => {
            setPoleData(res.data.pole);
            setShowModal(true);
        });
    };

    const handleAfterSubmitForm = ({ success, message }) => {
        if (!success) addNotification({ message: message.errorMsg, list: message.errorList }, 'danger')

        if (success) {
            setShowModal(false);
            addNotification(message, 'success')
            displayPoles();
        }
    };

    return (
        <>
            {/* Bouton ajouter un pole */}
            <button
                className={`inline-flex items-center text-white focus:outline-none focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 ml-auto bg-darkblue-600 hover:bg-darkblue-700 focus:ring-darkblue-700 border-darkblue-700`}
                onClick={() => {
                    setShowModal(true);
                    setPoleData(false)
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 mr-2"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                </svg>
                Ajouter un pôle
            </button>

            <ActionsPoles
                showAddModal={showModal}
                setShowAddModal={setShowModal}
                handleAfterSubmitForm={handleAfterSubmitForm}
                poles={poleData ? poleData : false}
            />

            <div className="mt-4">
                <Table
                    mockData={poles}
                    header={[
                        {
                            Header: "Libelle",
                            accessor: "label",
                        },
                    ]}
                    action={true}
                    afficherSupprimer={true}
                    apiUrl="poles"
                    afficherEditer={true}
                    editer={handleEdit}
                    className={"bg-darkblue"}
                    handleAfterSubmitForm={handleAfterSubmitForm}
                />
            </div>
        </>
    );
};

export default Poles;