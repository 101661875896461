import React, { useState } from "react";
import AccordionTab from "./AccordionTab";

const Accordion = ({ children, className, canOpenMultipleTab = true }) => {
  const [openedAccordion, setOpenedAccordion] = useState([]);

  const handleClickTab = (id) => {
    setOpenedAccordion((prevState) => {
      if (prevState.includes(id) && canOpenMultipleTab)
        return prevState.filter((word) => word != id);
      if (prevState.includes(id) && !canOpenMultipleTab) return [];

      if (!canOpenMultipleTab) return [id];
      return [...prevState, id];
    });
  };

  return (
    <div>
      <div id="accordion-collapse" className={className}>
        {React.Children.map(children, (child) => {
          if (!React.isValidElement(child) || child.type !== AccordionTab)
            return child;
          return React.cloneElement(child, {
            ...child.props,
            onClick: () => handleClickTab(child.props.id),
            open: openedAccordion.includes(child.props.id),
          });
        })}
      </div>
    </div>
  );
};

export default Accordion;
