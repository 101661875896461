import React from 'react';
import Form from '../../components/Form/Form';
import Modal from '../../components/Modal/Modal';
import ModalBody from '../../components/Modal/ModalBody';
import ModalHeader from '../../components/Modal/ModalHeader';

const DuplicateCoutsFixes = ({coutsFixes, showModal, setShowModal , date , handleAfterSubmitForm }) => {

    const initialValues = {
        label: coutsFixes.map((item) => { return item.label}),
        cost: coutsFixes.map((item) => { return item.cost}),
        cost_date: date,
        category_fixed_cost_id: coutsFixes.map((item) => { return item.category_fixed_cost_id}),
       }

    const fields = [
        {
          group: [
            { name: 'label', type: 'hidden' },
            { name: 'cost',  type: 'hidden'},
          ], groupClassName: "grid gap-4 sm:grid-cols-2 mt-0 mb-6"
        },
        {
          group: [
            { name: 'cost_date', type: "date"},
            { name: 'category_fixed_cost_id',
              type: 'hidden'
            },
          ]
        }
       ];

    return (
        <div>
            <Modal
              show={showModal}
              onClose={() => setShowModal(false)}
              modalContainerclassName="p-4 sm:p-5"
            >
              <ModalHeader
                title="Dupliquer l'élément"
                modalHeaderContainerclassName="p-0 pb-4"
              />

              <ModalBody modalBodyContainerclassName="px-0 pt-4 pb-0">
                <div className="bg-white p-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <svg
                        className="h-6 w-6 text-red-600"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                        />
                      </svg>
                    </div>
                    <div className="mt-3 text-left sm:mt-0 sm:ml-4">
                      <h3
                        className="text-lg font-medium leading-6 text-gray-900"
                        id="modal-title"
                      >
                        Êtes vous sur de vouloir dupliquer ces coûts fixes à cette date ?
                      </h3>

                      <Form
                        buttonTitle='Dupliquer'
                        method={'post'}
                        apiUrl={'duplicate-coutfixes'}
                        fields={fields}
                        initialValues={initialValues}
                        onSubmit={handleAfterSubmitForm}
                        className="space-y-0 md:space-y-0" 
                      />
                    </div>
                  </div>
                </div>
              </ModalBody>
            </Modal>

        </div>
    );
};

export default DuplicateCoutsFixes;