import React, { useEffect, useState } from "react";
import TableProjets from "../pages/Projets/Table/TableProjets";
import { useStateContext } from "../contexts/ContextProvider";
import axiosClient from "../axios-client";
import { CalculTotalROI, numberWithSpaces } from "../components/Utils/CalculROI";
import Dropdown from "../components/Dropdown/Dropdown";
import DropdownListCheckbox from "../components/Dropdown/DropdownListCheckbox";
import NumbersLoarder from "../components/Loader/NumbersLoarder";
import InputDate from "../components/Form/InputDate";
import { formatDate, parseDate } from "../components/Utils/Dates";

const Dashboard = () => {
  const todayDate = new Date();
  todayDate.setDate(1); // Permet de partir du début du mois
  // Calcul la date dans 3 mois
  const threeMonthsLater = new Date(todayDate);
  threeMonthsLater.setMonth(threeMonthsLater.getMonth() + 2);


  const { user } = useStateContext();
  const [clients, setClients] = useState([]);
  const [originalClients, setOriginalClients] = useState([]);
  const [clientsROI, setClientsROI] = useState([]);
  const [categories, setCategories] = useState([]);
  const [listeEmployes, setListeEmployes] = useState({}); // Liste employés pour le filtre
  const [selectedCategorie, setSelectedCategorie] = useState([]);
  const [selectedForecast, setSelectedForecast] = useState("show_all");
  const [selectedDates, setSelectedDates] = useState({});
  const [selectedDateDebut, setSelectedDateDebut] = useState(formatDate(todayDate, 'yyyy-mm-01'));
  const [selectedDateFin, setSelectedDateFin] = useState(formatDate(threeMonthsLater, 'yyyy-mm-01'));
  const [selectedEmployes, setSelectedEmployes] = useState([]);
  const [searchedValue, setSearchedValue] = useState();
  const [changedDate, setChangedDate] = useState(false);
  const [selectedRecurrent, setSelectedRecurrent] = useState("show_all");
  const [reinitializeDate, setReinitializeDate] = useState(false);

  const [totalHeuresEmploye, setTotalHeuresEmploye] = useState()

  const [isLoading, setIsLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [formatDateFin, setFormatDateFin] = useState();
  const [formatDateDebut, setFormatDateDebut] = useState();

  const [resetFilters, setResetFilters] = useState(false);

  // CALCUL DU ROI
  const [ROITotal, setROITotal] = useState();
  const [prixVenteTotal, setPrixVenteTotal] = useState();
  const [ROITotalVendu, setROITotalVendu] = useState();
  const [prixVenteTotalVendu, setPrixVenteTotalVendu] = useState();
  // const [coutsFixes, setCoutsFixes] = useState([]);

  // Définis les colonnes
  const [tableHeader, setTableHeader] = useState(() => {
    const Libelle = [{ Header: "Libelle", accessor: "label" }];
    const TotalHeures = (user.role.name === "admin" || user.role.name === "manager")  ? [{ Header: '', accessor: "totalHeuresEmployeByClient" }] : [];
    const RoiOrPrix =
      user.role.name === "admin" ? [{ Header: "ROI", accessor: "roi" }] : [];
    const Prix =
      user.role.name === "admin" || user.role.name === "manager"
        ? [{ Header: "Prix de vente", accessor: "sell_price" }]
        : [];
    const addProjet = user.role.name === "admin" ? [{ Header: "", accessor: "addProjet" }] : [];

    return [...Libelle, ...TotalHeures, ...RoiOrPrix, ...Prix, ...addProjet];
  });

  const displayClients = async () => {
    setTableLoading(true);
    await axiosClient
      .get("/projets", {
        params: {
          datedebut: selectedDateDebut,
          datefin: selectedDateFin,
          category: selectedCategorie,
          forecast: selectedForecast,
          monthly: selectedRecurrent,
          employes: selectedEmployes,
          order: 'asc'
        },
      })
      .then((res) => {
        res.data.projets.map((projet) => {
          // Calcul total des prix de ventes des projets
          projet.sell_price = projet.projects.reduce(
            (n, { sell_price }) =>
              (parseFloat(n) + parseFloat(sell_price)).toFixed(2),
            0
          );

          projet.roi = CalculTotalROI(projet);

          if(selectedEmployes && selectedEmployes.length === 1 ){
            var totalClient = 0; // Initialisation de la variable à 0
            projet.projects.map((project) =>{ // on passe dans le tableau des projets dans les clients
              project.time_employees.filter( // on passe dans le tableau des temps d'employé par projet 
                employe => employe.employee_id == selectedEmployes[0] // que nous filtrons en fonction de l'id de l'employé séléctionné dans les filtres
              ).map((employe) => { 
                totalClient = parseFloat(totalClient) + parseFloat(employe.employee_hour);
                projet.totalHeuresEmployeByClient = totalClient;
              })
            })

            projet.totalHeuresEmploye = totalHeuresEmploye;
          } else {
            setTotalHeuresEmploye(0);
            projet.totalHeuresEmployeByClient = 0;
          }

          return projet;
        });

        if(searchedValue){
          setClients(() => {
            const newList = res.data.projets.map((customer) => {
              // Chercher si le nom du client correspond à la valeur écrite
              // si c'est le cas alors on retour le client
              const customerLabel = customer.label.toLowerCase();
              if(customerLabel.includes(searchedValue.toLowerCase()))
                return customer;
    
              // Cherche si un nom de projet correspond à la valeur écrite
              const newProjects = customer.projects.filter((project) => {
                const projectLabel = project.label.toLowerCase();
                // Filtre les projets et le clients si il contient la valeur du champs
                return projectLabel.includes(searchedValue.toLowerCase())
              });
    
              // Retourne le client avec les projets qui correspond à la valeur
              if(newProjects && newProjects.length > 0) return {...customer, projects: newProjects}
    
              return undefined;
            })
    
            return [...newList.filter(function( element ) {
                return element !== undefined;
              })
            ];
          })
        }else{
          setClients(res.data.projets);
        }

        calculTotalHeures(res.data.projets);
        setOriginalClients(res.data.projets);
        setTableLoading(false);
        
        
        displayTotalsCA();
      });
  };

  const displayTotalsCA = () => {
    if (user.role.name === "admin") {
      displayCoutFixe();
    }
  }

  const displayCategories = async () => {
    await axiosClient.get("./categories").then((res) => {
      setCategories(res.data.categories);
    });
  };

  const displayEmployes = async () => {
    await axiosClient.get("./employes", {
      params: {
        order: 'asc'
      }
    }).then((res) => {
      setListeEmployes({active: res.data.employes.filter((employe) => parseInt(employe.active) && employe.role.name != 'admin' ), inactive: res.data.employes.filter((employe) => !parseInt(employe.active))})
    });
  };

  const displayCoutFixe = async () => {
    await axiosClient
      .get("/couts-fixes/roi", {
        params: {
          datedebut: selectedDateDebut,
          datefin: selectedDateFin,
        },
      })
      .then((res) => {
        // setCoutsFixes(res.data.coutsFixes);
        displayClientsForRoi(res.data.coutsFixes)
      });
  };

  const displayClientsForRoi = async (coutsFixes) => {
    setIsLoading(true);
    await axiosClient
      .get("/projets", {
        params: {
          datedebut: selectedDateDebut,
          datefin: selectedDateFin,
          category: selectedCategorie,
          forecast: selectedForecast,
          monthly: selectedRecurrent,
        },
      })
      .then((res) => {
        let total_sell_price = 0;
        let total_sell_price_vendu = 0;
        let cout_projet = 0;
        let cout_projet_vendu = 0;
        let sell_price = 0;
        let total_cout_benefits = 0;
        let total_cout_benefits_vendu = 0;
        let total_cout_employes = 0;
        let total_cout_employes_vendu = 0;
        let coutFixe = 0;

        setFormatDateDebut(selectedDateDebut);
        setFormatDateFin(selectedDateFin);

        coutsFixes.map((item) => {
          if (item.category.is_spent == 1) {
            coutFixe = parseFloat(coutFixe) + parseFloat(item.cost);
          } else {
            coutFixe = parseFloat(coutFixe) - parseFloat(item.cost);
          }
        });

        res.data.projets.map((projet) => {
          

          // Calcul total des prix de ventes des projets
          sell_price = projet.projects.reduce(
            (n, { sell_price }) =>
              (parseFloat(n) + parseFloat(sell_price)).toFixed(2),
            0
          );

          projet.projects.map((itemProjet) => {
            // Si un projet est un projet vendu
            if (itemProjet.forecast == 0) {
              // Prestations pour les projets vendus
              itemProjet.benefits.map((benefit) => {
                total_cout_benefits_vendu += parseFloat(benefit.cost);
              });

              // Côut employés
              itemProjet.time_employees.map((employe) => {
                total_cout_employes_vendu +=
                  parseFloat(employe.employee_hour) *
                  parseFloat(employe.cost_per_hour);
              });

              cout_projet_vendu = parseFloat(total_cout_benefits_vendu);

              //prix de Vente des projets vendu
              total_sell_price_vendu =
                parseFloat(total_sell_price_vendu) +
                parseFloat(itemProjet.sell_price);
            }

            // Prestations
            itemProjet.benefits.map((benefit) => {
              total_cout_benefits =
                parseFloat(total_cout_benefits) + parseFloat(benefit.cost);
            });

            // Côut employés
            itemProjet.time_employees.map((employe) => {
              total_cout_employes +=
                parseFloat(employe.employee_hour) *
                parseFloat(employe.cost_per_hour);
            });
          });

          if (selectedCategorie.length > 0) {
            cout_projet = total_cout_benefits + total_cout_employes;
            cout_projet_vendu =
              total_cout_benefits_vendu + total_cout_employes_vendu;
          } else {
            cout_projet = total_cout_benefits + coutFixe;
            cout_projet_vendu = total_cout_benefits_vendu + coutFixe;
            if (
              parseDate(selectedDateDebut) < new Date("2022-12-01") &&
              parseDate(selectedDateFin) < new Date("2022-12-01")
            ) {
              // Ancienne règle de calcul (prend en compte les coûts fixes et les coûts employés)
              cout_projet += total_cout_employes;
              cout_projet_vendu += total_cout_employes_vendu;
            }
          }

          total_sell_price =
            parseFloat(total_sell_price) + parseFloat(sell_price);


          return (
            cout_projet,
            total_sell_price,
            cout_projet_vendu,
            total_sell_price_vendu
          );
        });

        // ROI et Prix de vente total des sprojets vendu et prévisionnels
        setROITotal((cout_projet / total_sell_price) * 100);
        setPrixVenteTotal(total_sell_price);

        // ROI et Prix de vente total des sprojets vendu
        setROITotalVendu((cout_projet_vendu / total_sell_price_vendu) * 100);
        setPrixVenteTotalVendu(total_sell_price_vendu);

        setClientsROI(res.data.projets);
        setIsLoading(false);
      });
  };

  // Calcul le total d'heure d'un employé
  const calculTotalHeures = async (listeClients) => {
    var total = 0;

    listeClients.map((client) => {
      client.projects.map((project) => {
        project.time_employees.filter(employe => employe.employee_id == selectedEmployes[0]).map((employe) => {
           total = parseFloat(total) + parseFloat(employe.employee_hour) ;
        })
      })
    })
    setTotalHeuresEmploye(total);
  }

  // Quand on selectionne une catégorie
  const handleChangeCategorie = async (e) => {
    const {value, checked} = e.target;
    await setSelectedCategorie((crtSelected) => {
      const selectedIds = crtSelected || [];
      const newSelectedIds = checked
        ? [...selectedIds, parseInt(value)]
        : selectedIds.filter((selectedId) => selectedId !== parseInt(value));
        
        return newSelectedIds
    })
  };

  // Filtre Employés
  // Méthode permettant de faire une recherche d'un employé parmis la liste
  const [employeSearchValue, setEmployeSearchValue] = useState(false);
  const handleSearchEmploye = async (event) => {
      const {value} = event.target
      if(value)
          await setEmployeSearchValue(value);
      else
          await setEmployeSearchValue(false);
  }


  const handleChangeEmployeFiltre = async (e) => {
    const {value, checked} = e.target;
    await setSelectedEmployes((crtSelected) => {
      const selectedIds = crtSelected || [];
      const newSelectedIds = checked
        ? [...selectedIds, parseInt(value)]
        : selectedIds.filter((selectedId) => selectedId !== parseInt(value));
        
        return newSelectedIds
    })
  };

  // Méthode pour sélectionner/deselectionner tous les employés
  const [allEmployeeSelected, setAllEmployeeSelected] = useState({active: false, inactive: false});
  const handleSelectAll = async (isActiveEmployee) => {
      const btnSelect = isActiveEmployee ? 'active' : 'inactive';

      if(allEmployeeSelected[btnSelect]){
          await listeEmployes[btnSelect].map(employe => handleChangeEmployeFiltre({target: {value: employe.id, checked: false}}))
          await setAllEmployeeSelected((data) => ({...data, [btnSelect]: false}));
      }else{
          await listeEmployes[btnSelect].map(employe => handleChangeEmployeFiltre({target: {value: employe.id, checked: true}}))
          await setAllEmployeeSelected((data) => ({...data, [btnSelect]: true}));
      }

      // displayClients();
  }

  // Quand on selectionne dans les filtres si un projet est prévisionnel ou non
  const handleChangeForecast = (e) => {
    setSelectedForecast(e.target.value);
  };

  // Quand on selectionne dans les filtres si un projet est recurrent ou non
  const handleChangeRecurrent = (e) => {
    setSelectedRecurrent(e.target.value);
  };

  // Méthode pour reset les filtres
  const handleResetFilter = async () => {
    setSelectedCategorie([]);
    setSelectedEmployes([]);
    setSelectedForecast("show_all");
    setSelectedDateDebut(formatDate(todayDate, 'yyyy-mm-01'));
    setSelectedDateFin(formatDate(threeMonthsLater, 'yyyy-mm-01'));
    setSelectedDates({
      debut: parseDate(selectedDateDebut, "yyyy-mm-dd", "dd/mm/yyyy"),
      fin: parseDate(selectedDateFin, "yyyy-mm-dd", "dd/mm/yyyy"),
    });
    setSelectedRecurrent("show_all");
    setSearchedValue('');
    setReinitializeDate(true);

    setResetFilters(true);
  };

  // Quand la date est mise à jour par le composant InputDate
  useEffect(() => {
    if (changedDate) {
      setSelectedDateDebut(
        parseDate(selectedDates.debut, "dd/mm/yyyy", "yyyy-mm-dd")
      );
      setSelectedDateFin(
        parseDate(selectedDates.fin, "dd/mm/yyyy", "yyyy-mm-dd")
      );
      setChangedDate(false);
      setReinitializeDate(false);
    }
  }, [selectedDates]);


  // Filtrer par nom de projet
  const handleFiltreParNom = (e) => {
    const {value} = e.target;
    setSearchedValue(value);

      setClients(() => {
        const newList = originalClients.map((customer) => {
          // Chercher si le nom du client correspond à la valeur écrite
          // si c'est le cas alors on retour le client
          const customerLabel = customer.label.toLowerCase();
          if(customerLabel.includes(value.toLowerCase()))
            return customer;

          // Cherche si un nom de projet correspond à la valeur écrite
          const newProjects = customer.projects.filter((project) => {
            const projectLabel = project.label.toLowerCase();
            // Filtre les projets et le clients si il contient la valeur du champs
            return projectLabel.includes(value.toLowerCase())
          });

          // Retourne le client avec les projets qui correspond à la valeur
          if(newProjects && newProjects.length > 0) return {...customer, projects: newProjects}

          return undefined;
        })

        return [...newList.filter(function( element ) {
            return element !== undefined;
          })
        ];
      })
  }

  const voirProchainsMois = async () => {
      const nextMonth =
        todayDate.getMonth() + 3 > 12
          ? todayDate.getMonth() + 3 - 12
          : todayDate.getMonth() + 3;
      const nextYear =
        todayDate.getMonth() + 3 > 12
          ? todayDate.getFullYear() + 1
          : todayDate.getFullYear(); 
      setSelectedDateDebut(`${todayDate.getFullYear()}-${("0" + (todayDate.getMonth() + 1)).slice(-2)}-01`);
      setSelectedDateFin(`${nextYear}-${("0" + nextMonth).slice(-2)}-01`);
  
      setReinitializeDate(true);
  };

  const voirMoisActuel = async () => {
    setSelectedDateDebut(
      `${todayDate.getFullYear()}-${("0" + (todayDate.getMonth() + 1)).slice(
        -2
      )}-01`
    );
    setSelectedDateFin(
      `${todayDate.getFullYear()}-${("0" + (todayDate.getMonth() + 1)).slice(
        -2
      )}-01`
    );
    setReinitializeDate(true);
  };

  const voirMoisProchain = async () => {
    const todayDate = new Date();
    todayDate.setDate(1);

    const nextDate = new Date(todayDate);
    nextDate.setMonth(nextDate.getMonth() + 1);

    const startDate = formatDate(nextDate, 'yyyy-mm-01');
    const endDate = formatDate(nextDate, 'yyyy-mm-01');
    
    setSelectedDateDebut(startDate);
    setSelectedDateFin(endDate);

    setReinitializeDate(true);
  };

  const handleSubmitFilter = () => {
    displayClients();
    displayCategories();
    displayEmployes();
  }

  useEffect(() => {
    displayClients();
    displayCategories();
    displayEmployes();
  }, []);

  useEffect(() => {
    if(resetFilters){
      handleSubmitFilter();
      setResetFilters(false)
    }
  }, [resetFilters])

  useEffect(() => {
    if (reinitializeDate) setReinitializeDate(false);
  }, [reinitializeDate]);

  return (
    <div>
      <div className="flex sticky top-0 h-fit z-40 bg-neutral-100 rounded py-2">
        {/* FILTRES */}
        <div className="w-9/12 text-sm">
          <div className="w-full justify-around bg-neutral-100 rounded border-r border-gray-200 px-6 py-4 items-center self-start">
            <div className="space-y-2 flex justify-start items-center gap-3 flex-wrap">
              {/* DROPDOWN Projets par catégories */}
              <Dropdown
                buttonTitle="Catégorie"
                buttonClass="inline-flex items-center text-white rounded-lg p-2.5 bg-darkblue-600 hover:bg-darkblue-700 text-xs"
                menuClass="!mt-2 shadow-md"
                className="!mt-0"
                closeOnClick={false}
              >
                {categories.map((item, i) => {
                  
                  return (
                    <DropdownListCheckbox
                      label={item.label ? item.label : item.name}
                      value={item.id}
                      name={item.id}
                      closeOnCLick={false}
                      key={item.id}
                      onChange={handleChangeCategorie}
                      checked={selectedCategorie?.includes(item.id)}
                      labelClassName="text-xs"
                    />
                  );
                })}
              </Dropdown>

              {/* DROPDOWN employes */}
              <Dropdown
                buttonTitle="Employés"
                buttonClass="inline-flex items-center text-white rounded-lg p-2.5 bg-darkblue-600 hover:bg-darkblue-700 text-xs"
                menuClass="!mt-2 shadow-md pt-4 max-h-[25rem] overflow-auto"
                className="!mt-0"
                closeOnClick={false}
                >
                    <div>
                        <div className='px-3 mb-2'>
                            <input type="text" 
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-rose-500 focus:border-rose-500 block w-full px-2.5 py-1.5" 
                                placeholder='Rechercher un employé'
                                onChange={handleSearchEmploye}
                                value={employeSearchValue ? employeSearchValue : ''}
                            />
                        </div>

                        <hr className='mt-2 mb-2'/>
                        <span className='text-[14px] px-3 text-gray-600 hover:text-rose-700 cursor-pointer text-right block' onClick={() => handleSelectAll(true)}>Tout {allEmployeeSelected.active ? 'déselectionner' : 'sélectionner'}</span>
                        
                        {listeEmployes.active &&
                            listeEmployes.active
                            // Filtre pour trouver un employé si il y'a une recherche d'employé
                            .filter(employe => (employeSearchValue) ? employe.first_name.toLowerCase().includes(employeSearchValue.toLowerCase()) || employe.last_name.toLowerCase().includes(employeSearchValue.toLowerCase()) : true)
                            .map((employe) => (
                                <DropdownListCheckbox
                                    label={`${employe.last_name} ${employe.first_name}`}
                                    value={employe.id}
                                    name={`employee_id_${employe.id}`}
                                    closeOnCLick={false}
                                    key={employe.id}
                                    onChange={handleChangeEmployeFiltre}
                                    checked={selectedEmployes?.includes(employe.id)}
                                    labelClassName="text-xs"
                                />
                            ))
                        }
                    </div>
              </Dropdown>


              {/* DROPDOWN Projets prévisionnels */}
              <Dropdown
                buttonTitle="CA"
                buttonClass="inline-flex items-center text-white rounded-lg p-2.5 bg-darkblue-600 hover:bg-darkblue-700 text-xs"
                menuClass="!mt-2 shadow-md"
                className="!mt-0"
              >
                <DropdownListCheckbox
                  label={"Tous les Projets"}
                  value={"show_all"}
                  type="radio"
                  onChange={handleChangeForecast}
                  checked={selectedForecast === "show_all"}
                  labelClassName="text-xs"
                />
                <DropdownListCheckbox
                  label={"CA Vendus"}
                  value={0}
                  type="radio"
                  onChange={handleChangeForecast}
                  checked={selectedForecast === "0"}
                  labelClassName="text-xs"
                />
                <DropdownListCheckbox
                  label={"CA Prévisionnels"}
                  value={1}
                  name={"previsionnel"}
                  type="radio"
                  onChange={handleChangeForecast}
                  checked={selectedForecast === "1"}
                  labelClassName="text-xs"
                />
              </Dropdown>

              {/* DROPDOWN Projets Récurrent */}
              <Dropdown
                buttonTitle="Récurrent"
                buttonClass="inline-flex items-center text-white rounded-lg p-2.5 bg-darkblue-600 hover:bg-darkblue-700 text-xs"
                menuClass="!mt-2 shadow-md"
                className="!mt-0"
              >
                <DropdownListCheckbox
                  label={"Tous les Projets"}
                  value={"show_all"}
                  name={"all"}
                  type="radio"
                  onChange={handleChangeRecurrent}
                  checked={selectedRecurrent === "show_all"}
                  labelClassName="text-xs"
                />

                <DropdownListCheckbox
                  label={"Projets One Shot "}
                  value={0}
                  name={"oneshot"}
                  type="radio"
                  onChange={handleChangeRecurrent}
                  checked={selectedRecurrent === "0"}
                  labelClassName="text-xs"
                />

                <DropdownListCheckbox
                  label={"Projets Mensuel"}
                  value={1}
                  name={"monthly"}
                  type="radio"
                  onChange={handleChangeRecurrent}
                  checked={selectedRecurrent === "1"}
                  labelClassName="text-xs"
                />
              </Dropdown>

              {/* FILTRE PAR DATE */}
              <div className="space-y-2 flex gap-3 items-center !mt-0">
                <div className="flex !mb-0">
                  <span className="bg-darkblue-100 p-2 text-white rounded-tl-md rounded-bl-md select-none text-xs flex items-center">
                    Du
                  </span>
                  <InputDate
                    setSelectedDate={(data) =>
                      setSelectedDates((crtDate) => ({
                        ...crtDate,
                        debut: data,
                      }))
                    }
                    onChange={() => setChangedDate(true)}
                    dashboard={true}
                    defaultDate={selectedDateDebut}
                    reinitialize={reinitializeDate}
                  />
                </div>
                <div className="flex !mt-0">
                  <span className="bg-darkblue-100 p-2 text-white rounded-tl-md rounded-bl-md select-none text-xs flex items-center">
                    Au
                  </span>
                  <InputDate
                    setSelectedDate={(data) =>
                      setSelectedDates((crtDate) => ({ ...crtDate, fin: data }))
                    }
                    onChange={() => setChangedDate(true)}
                    dashboard={true}
                    defaultDate={selectedDateFin}
                    reinitialize={reinitializeDate}
                  />
                </div>
              </div>

              <div className="flex gap-3 !mt-0 flex-1">
                {/* VOIR LES 3 PROCHAINS MOIS */}
                <div className="">
                  <span
                    onClick={() => {
                      voirProchainsMois();
                    }}
                    className="inline-flex items-center text-white rounded-lg p-2.5 bg-darkblue-600 hover:bg-darkblue-700 text-xs hover:cursor-pointer select-none"
                  >
                    3 prochains mois
                  </span>
                </div>

                {/* Voir le mois actuel */}
                <div className="">
                  <span
                    onClick={() => {
                      voirMoisActuel();
                    }}
                    className="inline-flex items-center text-white rounded-lg p-2.5 bg-darkblue-600 hover:bg-darkblue-700 text-xs hover:cursor-pointer select-none"
                  >
                    Mois actuel
                  </span>
                </div>

                {/* Voir le prochain mois */}
                <div className="">
                  <span
                    onClick={() => {
                      voirMoisProchain();
                    }}
                    className="inline-flex items-center text-white rounded-lg p-2.5 bg-darkblue-600 hover:bg-darkblue-700 text-xs hover:cursor-pointer select-none"
                  >
                    Prochain mois
                  </span>
                </div>

                {/* Filtrer par nom */}
                <input
                  type="text"
                  placeholder="Rechercher..."
                  value={searchedValue}
                  className="montserrat rounded-lg focus:border-2 border-rose focus-visible:outline-0 p-2 text-xs !mt-0 border-2 border-transparent drop-shadow-none focus-visible:drop-shadow ease-linear transition-all duration-150"
                  onChange={handleFiltreParNom}
                />

                {/* Réinitialiser les filtres */}
                <div className="ml-auto w-fit block">
                <span
                  className="uppercase text-[12px] mt-4 cursor-pointer hover:underline mr-4"
                  onClick={handleResetFilter}
                >
                  Réinitialiser les filtres
                </span>
                <button className="inline-flex items-center text-white font-bold uppercase cursor-pointer rounded-lg p-2.5 bg-darkblue-600 hover:bg-darkblue-700 text-xs"
                onClick={handleSubmitFilter}>Filtrer</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {user.role.name === "admin" && (
          <div className="w-3/12 justify-start px-4 gap-8 ml-4">
            <div>
              <h3 className="uppercase text-darkblue-300 text-[10px] font-bold mb-1">
                CA Vendu et prévisionnel
              </h3>
              <div className="gap-2 flex flex-wrap">
                <div className="flex text-xs">
                  <span className=" bg-rose-100 p-2 text-white rounded-tl-md rounded-bl-md select-none ">
                    PV
                  </span>
                  <span className="bg-white rounded-tr-md rounded-br-md p-2">
                    {isLoading || isNaN(prixVenteTotal)  ? (
                      <NumbersLoarder />
                    ) : prixVenteTotal ? (
                      numberWithSpaces(prixVenteTotal.toFixed(2))
                    ) : (
                      0 + " €"
                    )}
                  </span>
                </div>
                <div className="flex text-xs">
                  <span className=" bg-rose-100 p-2 text-white rounded-tl-md rounded-bl-md select-none ">
                    ROI
                  </span>
                  {isLoading || isNaN(ROITotal) ? (
                    <span className="bg-white rounded-tr-md rounded-br-md p-2">
                      {" "}
                      <NumbersLoarder />
                    </span>
                  ) : ROITotal < 35 ? (
                    <span className="text-green-500 bg-white rounded-tr-md rounded-br-md p-2">
                      {parseFloat(ROITotal).toFixed(2)} %
                    </span>
                  ) : (
                    <span className="text-red-500 bg-white rounded-tr-md rounded-br-md p-2">
                      {parseFloat(ROITotal).toFixed(2)} %
                    </span>
                  )}
                </div>
              </div>
            </div>

            {/* CA Vendu */}
            <div className="mt-2">
              <h3 className="uppercase text-darkblue-300 text-[10px] font-bold mb-1">
                CA Vendu
              </h3>
              <div className="gap-2 flex flex-wrap">
                <div className="flex text-xs">
                  <span className=" bg-rose-100 p-2 text-white rounded-tl-md rounded-bl-md select-none ">
                    PV
                  </span>
                  <span className="bg-white rounded-tr-md rounded-br-md p-2">
                    {isLoading || isNaN(prixVenteTotalVendu) ? (
                      <NumbersLoarder />
                    ) : prixVenteTotalVendu ? (
                      numberWithSpaces(prixVenteTotalVendu.toFixed(2))
                    ) : (
                      0 + " €"
                    )}
                  </span>
                </div>
                <div className="flex text-xs">
                  <span className=" bg-rose-100 p-2 text-white rounded-tl-md rounded-bl-md select-none ">
                    ROI
                  </span>
                  {isLoading || isNaN(ROITotalVendu) ? (
                    <span className="bg-white rounded-tr-md rounded-br-md p-2">
                      {" "}
                      <NumbersLoarder />
                    </span>
                  ) : ROITotalVendu < 35 ? (
                    <span className="text-green-500 bg-white rounded-tr-md rounded-br-md p-2">
                      {parseFloat(ROITotalVendu).toFixed(2)} %
                    </span>
                  ) : (
                    <span className="text-red-500 bg-white rounded-tr-md rounded-br-md p-2">
                      {parseFloat(ROITotalVendu).toFixed(2)} %
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Table des données des projets */}
      <div className="w-full">
        <TableProjets
          mockData={clients}
          header={tableHeader}
          search={false}
          displayClients={displayClients}
          displayCoutFixe={displayCoutFixe}
          selectedCategorie={selectedCategorie}
          forecast={selectedForecast}
          dateFin={formatDateFin}
          dateDebut={formatDateDebut}
          recurrent={selectedRecurrent}
          loader={tableLoading}
          totalHeuresEmploye={totalHeuresEmploye}
        />
      </div>
    </div>
  );
};

export default Dashboard;
