import React from "react";
import Modal from "../../components/Modal/Modal";
import ModalHeader from "../../components/Modal/ModalHeader";
import ModalBody from "../../components/Modal/ModalBody";
import Form from "../../components/Form/Form";
import { slugify } from "../../components/Utils/Slug";

const ActionCategories = ({
  categories,
  showAddModal,
  setShowAddModal,
  handleAfterSubmitForm,
}) => {

  /** FORMULAIRE */
  const initialValues = {
    name: categories ? categories.name : undefined,
    label: categories ? categories.label : undefined
  }

  const fields = [
    {group: [
        { name: 'label', label: 'Nom', updateWhenInputChange: {name: 'name', value: ({value}) => slugify(value)}},
        { name: 'name', type: 'hidden' },
    ]}
  ]

  return (
    <Modal
      show={showAddModal}
      onClose={() => setShowAddModal(false)}
      modalContainerclassName="p-4 sm:p-5"
    >
      <ModalHeader
        title={`${categories ? "Modifier" : "Ajouter"} une catégorie`}
        modalHeaderContainerclassName="p-0 pb-4"
      />

      <ModalBody modalBodyContainerclassName="px-0 pt-4 pb-0">
        <Form
          buttonTitle={categories ? "Modifier" : "Ajouter"}
          method={categories ? "put" : "post"}
          apiUrl={categories ? `categories/${categories.id}` : `categories`}
          fields={fields}
          initialValues={initialValues}
          onSubmit={handleAfterSubmitForm}
        />
      </ModalBody>
    </Modal>
  );
};

export default ActionCategories;
