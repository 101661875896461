import React from "react";

const DropdownListCheckbox = ({label, value, name, type = "checkbox", count = false, inputCheckedColor = 'darkblue', onChange, checked = false, setIsOpen, closeOnCLick = true ,id, classNameContainer = '', labelClassName = ''}) => {

  return (
    <div className={classNameContainer ? classNameContainer : `flex items-center justify-between px-6 py-2`} key={id}>
      <div className="flex items-center">
        <input
          id={id ? id : (name ? name : value)}
          name={name}
          type={type}
          value={value}
          onChange={onChange}
          onClick={() => (closeOnCLick) ? setIsOpen(false) : false}
          checked={checked}
          className={`w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:outline-none text-${inputCheckedColor}-500 cursor-pointer`}
        />

        <label
          htmlFor={name ? name : value}
          className={`${labelClassName} ml-2 text-sm font-medium text-gray-900 cursor-pointer`}
        >
          {label}
        </label>
      </div>
      {count && <div className="text-gray-400 ml-6">{count}</div>}
    </div>
  );
};

export default DropdownListCheckbox;
