import React, { useContext, useEffect } from "react";
import { useMemo } from "react";
import { useTable, useSortBy, useGlobalFilter } from "react-table";
import { GlobalFilter } from "../../../components/Table/GlobalFilter";
import RowProjet from "./RowProjet";
import ActionProjets from "../ActionProjets";
import AddProjets from "../Form/AddProjets";
import NumbersLoarder from "../../../components/Loader/NumbersLoarder";
import { NotificationContext } from "../../../contexts/NotificationContext";
import { useStateContext } from "../../../contexts/ContextProvider";
import { CalculTotalROI } from "../../../components/Utils/CalculROI";
import AddProjetWithClient from "../Form/AddProjetWithClient";

const TableProjets = ({
  mockData,
  header,
  className,
  TheadClassName = false,
  search,
  action,
  children,
  displayClients,
  displayCoutFixe,
  totalHeuresEmploye,
  selectedEmployes,
  loader,
}) => {
  const { user } = useStateContext();
  const { addNotification } = useContext(NotificationContext);

  const [showModal, setShowModal] = React.useState(false);
  const [showAddModal, setShowAddModal] = React.useState(false);
  const [showProjectAddModal, setShowProjectAddModal] = React.useState(false);
  const [modalData, setModalData] = React.useState({});
  const [isClientOpen, setIsClientOpen] = React.useState([]);
  const [headerTab, setHeaderTab] = React.useState(header);

  useEffect(() => {
    if (totalHeuresEmploye === 0) {
      setHeaderTab((lastHeader) => lastHeader.filter((head) => head.accessor != 'totalHeuresEmployeByClient'))
    } else {
      setHeaderTab(header);
    }
  }, [totalHeuresEmploye])

  const columns = headerTab;

  const data = useMemo(() => [...mockData], [mockData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    state: { globalFilter },
  } = useTable(
    {
      columns,
      data
    },
    useGlobalFilter,
    useSortBy
  );

  // Referme le modal a l'envoie du formulaire, affiche les erreurs et modifie les données
  const handleAfterSubmitForm = ({ success, message, data }) => {
    if (!success) addNotification({ message: message.errorMsg, list: message.errorList }, 'danger')
    if (success) {
      addNotification(message, 'success')
      setShowModal(false)
      setShowProjectAddModal(false)
      if (data?.edit_customer_data) {
        const customer = mockData.filter((customer) => customer.id === parseInt(data.customer_id))[0]

        if (customer) {
          const projectIndex = customer.projects.findIndex(project => project.id === data.project_id);

          if (data.type) {
            const projectData = customer.projects[projectIndex][data.type];
            const typeId = projectData.findIndex(type => type.id === data.id)
            if (data.is_deleted) projectData.splice(typeId, 1);
          } else {
            // Supprime le projet de la liste du client
            if (data.is_deleted) customer.projects.splice(customer.projects.findIndex(project => project.id === data.project_id), 1);
            // Modifie le projet
            else customer.projects[projectIndex] = data.projet;

            // Calcul le total PV du client
            customer.sell_price = customer.projects.reduce((accumulator, currentProject) => {
              return accumulator + parseFloat(currentProject.sell_price)
            }, 0);
          }

          customer.roi = CalculTotalROI(customer)

          // Modifie les valeurs du ROI et PV dans le tableau
          const editRow = rows.filter((row) => row.original.id === parseInt(data.customer_id))[0];
          editRow.values.roi = customer.roi;
          editRow.values.sell_price = customer.sell_price;
        }
      } else if(data?.add_customer_data){
        
        const customer = mockData.filter((customer) => customer.id === parseInt(data.customer_id))[0]

        if (customer) {

          customer.projects.unshift(data.projet);
            // Calcul le total PV du client
            customer.sell_price = customer.projects.reduce((accumulator, currentProject) => {
              return accumulator + parseFloat(currentProject.sell_price)
            }, 0);

          customer.roi = CalculTotalROI(customer)

          // Modifie les valeurs du ROI et PV dans le tableau
          const editRow = rows.filter((row) => row.original.id === parseInt(data.customer_id))[0];
          editRow.values.roi = customer.roi;
          editRow.values.sell_price = customer.sell_price;
        }
      } else {
        displayClients();
      }

      displayCoutFixe();
    }
  }

  return (
    <>
      <div className="mb-4 flex flex-start items-center">


        {/* Bouton ajouter un projet */}
        {(user.role.name === 'admin') && (
          <button
            className={`fixed bottom-4 right-4 z-40 inline-flex items-center text-white focus:outline-none focus:ring-4 font-medium rounded-full text-sm px-2 py-2 bg-darkblue-600 hover:bg-darkblue-700 focus:ring-darkblue-700 border-darkblue-700`}
            onClick={() => {
              setShowAddModal(true);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
        )}

        {search ? (
          <GlobalFilter
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            placeholder="Rechercher un client ..."
          />
        ) : (
          ""
        )}
        {React.Children.map(children, (child) => React.cloneElement(child))}
      </div>

      {/* apply the table props */}
      <div className="relative overflow-x-auto shadow-sm sm:rounded-lg">
        <table
          {...getTableProps()}
          className={`w-full text-sm text-left text-gray-500 relative ${className}`}
        >
          <thead
            className={TheadClassName ? TheadClassName : `text-base text-white uppercase bg-darkblue-700 montserrat font-bold select-none ${TheadClassName}`}
          >
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr className="border-b" {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => {

                      // COlonne total heures d'un employés
                      if (column.id === 'totalHeuresEmployeByClient' && totalHeuresEmploye > 0) return (
                        <th
                          scope="col"
                          className={column.className ? `${column.className} relative  w-fit` : "text-left p-3 px-5 relative"}
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          title=""
                        >

                          {totalHeuresEmploye} H
                          <span className="absolute top-1/2 -translate-y-1/2 ml-1">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <svg
                                  className="w-2.5 fill-current"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 320 512"
                                >
                                  <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" />
                                </svg>
                              ) : (
                                <svg
                                  className="w-2.5 fill-current"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 320 512"
                                >
                                  <path d="M182.6 137.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z" />
                                </svg>
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </th>
                      )

                      // Si l'accessor contient un Header on l'affiche
                      if (typeof column.Header === "string") return (
                        // Apply the header cell props
                        <th
                          scope="col"
                          className={column.className ? `${column.className} relative` : "text-left p-3 px-5 relative"}
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          title=""
                        >

                          {column.render("Header")}
                          <span className="absolute top-1/2 -translate-y-1/2 ml-1">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <svg
                                  className="w-2.5 fill-current"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 320 512"
                                >
                                  <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" />
                                </svg>
                              ) : (
                                <svg
                                  className="w-2.5 fill-current"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 320 512"
                                >
                                  <path d="M182.6 137.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z" />
                                </svg>
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </th>
                      )
                    })
                  }
                  {action ? (
                    <th scope="col" className="text-left p-3 px-5"></th>
                  ) : (
                    ""
                  )}
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          {loader ? <tbody ><tr><td className="py-6 relative" colSpan={4}><div className=" absolute left-2/4 top-3"><NumbersLoarder /></div></td></tr></tbody> :

            <tbody
              className="montserrat font-regular text-sm"
              {...getTableBodyProps()}
            >
              {rows.length > 0 ? (
                rows.map((row, i) => {

                  prepareRow(row);
                  return (
                    <RowProjet
                      key={i}
                      row={row}
                      projets={row.original.projects}
                      mockData={mockData}
                      isOpen={isClientOpen.includes(row.id) ? true : false}
                      openList={isClientOpen}
                      setIsOpen={setIsClientOpen}
                      setShowModal={setShowModal}
                      setModalData={setModalData}
                      displayCoutFixe={displayCoutFixe}
                      setShowProjectAddModal={setShowProjectAddModal}
                    />
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan={header.length + 1}
                    align="center"
                    className="text-black text-lg text-center w-full p-3 px-5 bg-white"
                  >
                    Aucun résultat ne correspond à votre recherche
                  </td>
                </tr>
              )
              }
            </tbody>}

        </table>
      </div>

      {/* Modal action projets */}

      <ActionProjets
        showModal={showModal}
        setShowModal={setShowModal}
        modalData={modalData}
        handleAfterSubmitForm={handleAfterSubmitForm}
      />

      {showAddModal && (
        <AddProjets
          showModal={showAddModal}
          setShowModal={setShowAddModal}
          displayClients={displayClients}
        />
      )}

      {showProjectAddModal && (
        <AddProjetWithClient
          showModal={showProjectAddModal}
          setShowModal={setShowProjectAddModal}
          projetValues={modalData}
          handleAfterSubmitForm={handleAfterSubmitForm}
        />
      )}
    </>
  );
};

export default TableProjets;
