import React, { useEffect } from "react";
import { useMemo } from "react";
import { useTable, useSortBy, useGlobalFilter } from "react-table";
import { GlobalFilter } from "./GlobalFilter";
import ModalBody from "../Modal/ModalBody";
import ModalFooter from "../Modal/ModalFooter";
import Modal from "../Modal/Modal";
import ModalSupprimer from "../Modal/ModalSupprimer";

const Table = ({
  mockData,
  header,
  className,
  TheadClassName = false,
  search,
  action,
  afficherSupprimer,
  afficherEditer,
  editer,
  children,

  handleAfterSubmitForm,

  // Modal supprimer
  apiUrl,
  setSuccess,
}) => {
  const [showModal, setShowModal] = React.useState(false);
  const [crtDeleteId, setCrtDeleteId] = React.useState(false);
  const columns = header;
  const data = useMemo(() => [...mockData], [mockData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    state: { globalFilter },
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy
  );

  return (
    <>
      <div className="mb-4 flex items-center">
        {search ? (
          <GlobalFilter
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        ) : (
          ""
        )}
        {React.Children.map(children, (child) => React.cloneElement(child))}
      </div>

      {/* apply the table props */}
      <div className="relative overflow-x-auto shadow-sm sm:rounded-lg">
      <table
        {...getTableProps()}
        className={`w-full text-sm text-left text-gray-500 relative ${className}`}
      >
        <thead
          className={TheadClassName ? TheadClassName : `text-base text-white uppercase bg-darkblue-700 montserrat font-bold select-none ${TheadClassName}`}
        >
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr className="border-b" {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => {
                  return (
                    // Apply the header cell props
                    <th
                      scope="col"
                      className={column.className ? `${column.className} relative` : "text-left p-3 px-5 relative"}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      title=""
                    >
                      {column.render("Header")}
                      <span className="absolute top-1/2 -translate-y-1/2 ml-1">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <svg
                              className="w-2.5 fill-current"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 320 512"
                            >
                              <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" />
                            </svg>
                          ) : (
                            <svg
                              className="w-2.5 fill-current"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 320 512"
                            >
                              <path d="M182.6 137.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z" />
                            </svg>
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </th>
                  )})
                }
                {action ? (
                  <th scope="col" className="text-left p-3 px-5"></th>
                ) : (
                  ""
                )}
              </tr>
            ))
          }
        </thead>
        {/* Apply the table body props */}
        <tbody
          className="montserrat font-regular text-sm"
          {...getTableBodyProps()}
        >
          {
            // On verifie si il ya des lignes dans le tableau 'rows'
            rows.length > 0 ? (
              // Si il y des lignes :

              rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    className="bg-white border-b hover:bg-gray-100"
                    {...row.getRowProps()}
                    key={i}
                  >
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <td className={cell.column.className ? cell.column.className : "p-3 px-5 whitespace-nowrap"} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })
                    }
                    {action ? (
                      <td className={row.cells[row.id]?.column.className ? `${row.cells[row.id].column.className} flex justify-end` : "p-2 text-uppercase flex justify-end"}>
                        {afficherEditer ? (
                          <button
                            onClick={() => {
                              editer(row.original.id);
                            }}
                            className="cursor-pointer outline-none focus:outline-none editer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                              />
                            </svg>
                          </button>
                        ) : (
                          ""
                        )}
                        {afficherSupprimer ? (
                            <button
                              className="mx-3 outline-none focus:outline-none ease-linear transition-all duration-150 supprimer"
                              type="button"
                              onClick={() => {
                                setShowModal(true);
                                setCrtDeleteId(row.original.id);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                />
                              </svg>
                            </button>
                        ) : (
                          ""
                        )}
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan={header.length + 1}
                  align="center"
                  className="text-black text-lg text-center w-full p-3 px-5 bg-white"
                >
                  Aucun résultat ne correspond à votre recherche
                </td>
              </tr>
            )
          }
        </tbody>
      </table>
      </div>

      {/* Modal supprimer */}
      <ModalSupprimer 
        showModal={showModal}
        setShowModal={setShowModal}
        apiUrl={apiUrl}
        id={crtDeleteId}
        setSuccess={setSuccess}
        handleAfterSubmitForm={handleAfterSubmitForm}
      />
    </>
  );
};

export default Table;
