import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Header from "../components/Header";
import { useStateContext } from "../contexts/ContextProvider";

const DashboardLayout = ({ routes }) => {
  const { user, authToken } = useStateContext();
  const pathname = useLocation().pathname;
  // Récupère la route actuelle, si la route fait partie d'un layout alors on récupère le layout.
  const crtRoute = routes.find((route) => {
    if (route.children)
      return route.children.find((child) => {
        const formatRoutePath =
          route.path +
          (child.path.charAt(0) === "/" || child.path === "" ? "" : "/") +
          child.path;
        const formatPathname =
          pathname.slice(-1) === "/"
            ? pathname.substring(0, pathname.length - 1)
            : pathname;
        return formatRoutePath === formatPathname;
      });
    return route.path === pathname;
  });

  // Si la route fait partie d'un layout, alors on récupère la route actuelle, sinon on récupère le nom de la route récupérer au dessus.
  const routeName = (crtRoute.children == null)
      ? crtRoute.name // Route pas dans un layout
      : crtRoute.children.find((route) => { // Récupère toutes les routes du layout
          const formatPathname = pathname.slice(-1) === "/" ? pathname.substring(0, pathname.length - 1) : pathname; // Enlève le / à la fin de l'url
          return crtRoute.path + (route.path ? `/${route.path}` : route.path) === formatPathname; // Compare l'url a la route de l'élément appelé
      }).name;

  if (!authToken) return <Navigate to="/login" />;

  if (user) {
    return user.role.permissions.some(
      (permission) => routeName === permission.name
    ) || routeName === process.env.REACT_APP_DEFAULT_DASHBOARD_ROUTE_NAME ? (
      <div>
        <Header user={user} />
        <main className="container mx-auto p-8 xl:px-5">          
          <Outlet />
        </main>
      </div>
    ) : (
      <Navigate to="/" replace />
    );
  }
};

export default DashboardLayout;