import React, { useEffect } from "react";
import ModalHeader from "./ModalHeader";

const Modal = ({ show, onClose, children, modalContainerClass = "" }) => {
  // Ferme le popup si on appuie sur la touche echap
  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      onClose();
    }
  };

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, []);

  const renderSwitch = (component) => {
    const { type } = component;
  
    switch (type) {
      case ModalHeader:
        // Si le composant est de type ModalHeader, alors on retourne aussi la fonction onClose qui permet de fermer le popup
        return React.cloneElement(component, {
          onClose: () => onClose(),
        });
      default:
        return component;
    }
  };

  return show ? (
    <div
      tabIndex="-1"
      aria-hidden="true"
      className={`fixed ${
        show ? "" : "hidden"
      } top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full justify-center items-center flex bg-gray-900 bg-opacity-50`}
    >
      <div
        className={`relative w-full h-auto max-h-[95vh] max-w-2xl  overflow-auto bg-white rounded-lg shadow ${modalContainerClass}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {show && (
          <div className="relative">
            {React.Children.map(children, (child) => renderSwitch(child))}
          </div>
        )}
      </div>
    </div>
  ) : ''
};

export default Modal;
