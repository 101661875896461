import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import AdminSidebar from "../components/AdminSidebar";

const AdministrationLayout = () => {
  //   Récupère la route actuelle
  const pathname = useLocation().pathname;

  return (
    <div id="administration" className="flex gap-8">
      {pathname.startsWith("/administration/") && (
        <AdminSidebar />
      )}

      <div className={`${pathname.startsWith("/administration/") ? 'w-9/12' : 'w-full'}`}>
        <Outlet />
      </div>
    </div>
  );
};

export default AdministrationLayout;