import React, { useCallback, useEffect, useRef, useState } from "react";
import { formatDate, isDateValue, parseDate } from "../Utils/Dates";

const Input = ({
  type,
  name,
  label,
  value,
  options,
  disabled,
  changeFormDataValue,
  onChange,
  containerClass,
  isMultiple = false,
  min = false,
  hasError = false,
  updateWhenInputChange = false
}) => {
  const hiddenInputSelectRef = useRef(null);
  const [inputValue, setInputValue] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);

  // list-checkbox constantes
  const [selectedListCheckbox, setSelectedListCheckbox] = useState(value);
  const [isCheckedAll, setIsCheckedAll] = useState(false);

  // Afficher/Cacher le mot de passe
  const [passwordInputisShown, setPasswordInputIsSHown] = useState(false);

  const handleChange = (e) => {
    if(e.target){

      const { value, checked } = e.target;
      const inputType = e.target.type;
      const inputName = e.target.name;

      if (onChange)
        onChange({
          value: value,
          checked: checked,
          name: inputName,
          type: inputType,
          isMultiple: isMultiple
        });
      
      if (type === 'list-checkbox'){
        var newCheckedList = selectedListCheckbox || [];

        if(checked)
          newCheckedList = [...newCheckedList, parseInt(value) ];
        else
          newCheckedList.splice(newCheckedList.indexOf(parseInt(value)), 1)

        setSelectedListCheckbox(newCheckedList)
        return changeFormDataValue(name, newCheckedList)
        
      }
      
      if (inputType === "checkbox" || inputType === "radio" || inputType === "toggle")
        return changeFormDataValue(inputName, checked, isMultiple);

      // Permet de modifier un autre champs en même temps avec une fonction pour gérer 
      // la valeur à mettre
      if(updateWhenInputChange)
        changeFormDataValue(updateWhenInputChange.name, updateWhenInputChange.value({value: value}), isMultiple);


      return changeFormDataValue(inputName, value, isMultiple);
    }
  };

  const getMonthsOptions = () => {
    const months = [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ];
    return months.map((month, index) => ({ label: month, value: index + 1 }));
  };

  const getYearsOptions = () => {
    const annees = []; // Initialisation du tableau annees
    var theyear = new Date();
    for (let i = -5; i < 5; i++) {
      var year = theyear.getFullYear() + i; // On calcul l'année grace a l'année actuelle et i
      annees.push({ value: year });
    }

    return annees; // renvoie la liste des opttions
  };

  // Autocompletion 
  useEffect(() => {
    if (selectedOption) {
      handleChange({ target: hiddenInputSelectRef.current });
    }
  }, [selectedOption]);

  // list-checkbox
  const selectAllPermissions = () => {
    setIsCheckedAll(!isCheckedAll);
    if(!isCheckedAll === true){
      options.map((option, i) => {
        changeFormDataValue(name, option.id, i + 1);
      })
    }else{
      changeFormDataValue(name, undefined, false);
    }
  }

  useEffect(() => {
    if(type === 'list-checkbox' && (options.length > 0 && value.length === options.length)){
      setIsCheckedAll(true)
    }
  }, [options])

  switch (type) {
    case "select":
      return (
        <div className={containerClass}>
          <label
            htmlFor={name}
            className={`block mb-2 text-sm font-medium text-gray-900`}
          >
            {label}
          </label>
          <select
            name={name}
            id={name}
            value={value}
            onChange={handleChange}
            className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-rose-500 focus:border-rose-500 block w-full p-2.5 ${hasError ? "border-red-400 border-2" : "border-gray-300"}`}
            disabled={disabled}
          >
            <option>-- Sélectionner --</option>
            {options.length > 0 &&
              options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
          </select>
        </div>
      );
    case "autocompletion":
      const defaultEmployeName = value
        ? options.filter((employee) => employee.value === parseInt(value))[0]?.label
        : "";

      const handleInputChange = (event) => {
        const value = event.target.value;
        setInputValue(value);

        const filtered = options.filter((option) =>
          option.label.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredOptions(filtered);
        setShowOptions(true);
      };

      const handleOptionClick = (option) => {
        setInputValue(option.label);
        setSelectedOption(option);
        setFilteredOptions([]);
        setShowOptions(false);
      };

      const handleKeyDown = (event) => {
        if (event.key === "Tab" && filteredOptions.length > 0) {
          event.preventDefault();
          setInputValue(filteredOptions[0].label);
          setSelectedOption(filteredOptions[0]);
          setFilteredOptions([]);
          setShowOptions(false);
        }
      };

      return (
        <div className={`${containerClass} relative`}>
          {label && (
            <label
              htmlFor={name}
              className={`block mb-2 text-sm font-medium text-gray-900`}
            >
              {label}
            </label>
          )}
          <input
            type="text"
            value={inputValue || inputValue === "" ? inputValue : defaultEmployeName || ""}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-rose-500 focus:border-rose-500 block w-full p-2.5 relative z-[1] ${hasError ? "border-red-400 border-2" : "border-gray-300"}`}
          />
          {showOptions && (
            <div className="absolute z-10 mt-2 w-full bg-white border rounded shadow">
              {filteredOptions.map((option) => (
                <div
                  key={option.value}
                  onClick={() => handleOptionClick(option)}
                  className="px-4 py-2 cursor-pointer hover:bg-blue-100"
                >
                  {option.label}
                </div>
              ))}
            </div>
          )}
          <input
            type="hidden"
            value={selectedOption ? selectedOption.value : value ? value : ""}
            name={name}
            ref={hiddenInputSelectRef}
          />
        </div>
      );
    case "checkbox":
      return (
        <div className="flex p-2 pl-3">
          <div className="flex items-center h-5">
            <input
              type="checkbox"
              name={name}
              id={name}
              checked={value}
              onChange={handleChange}
              className={`w-4 h-4 text-rose-600 bg-gray-100 rounded accent-rose-500 focus:ring-0`}
            />
          </div>
          <div className="ml-2 text-sm">
            <label
              htmlFor={name}
              className={`font-medium text-gray-900 cursor-pointer`}
            >
              {label}
            </label>
          </div>
        </div>
      );
    case "radio":
      return (
        <div className="flex p-2 pl-3">
          <div className="flex items-center h-5">
            <input
              type="radio"
              name={name}
              id={name}
              value={value}
              checked={value}
              onChange={handleChange}
              className={`w-4 h-4 text-rose-600 bg-gray-100 rounded accent-rose-500 focus:ring-0`}
            />
          </div>
          <div className="ml-2 text-sm">
            <label
              htmlFor={name}
              className={`font-medium text-gray-900 cursor-pointer`}
            >
              {label}
            </label>
          </div>
        </div>
      );
    case "toggle":
      return (
        <>
          <label
            className="relative inline-flex items-center cursor-pointer"
            htmlFor={name}
          >
            <input
              type="checkbox"
              name={name}
              id={name}
              checked={value}
              onChange={handleChange}
              data-type="toggle"
              className="sr-only peer"
            />
            <div
              className={`w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-darkblue-600`}
            ></div>
            <span className={`ml-3 text-sm font-medium text-gray-900`}>
              {label}
            </span>
          </label>
        </>
      );
    case "date":
      // Si il y'a déjà une valeur on la reprend sinon on prend la date du jour.
      const selectedDate = value
        ? // Si la valeur est une date
          isDateValue(value)
          ? value
          : parseDate(value, "yyyy-mm-dd")
        : // Si pas de valeur on prend la date du jour
          new Date();

      selectedDate.setDate(1);
      const selectedMonth = selectedDate.getMonth() + 1;
      const selectedYear = selectedDate.getFullYear();

      return (
        <div>
          <label
            className={`block mb-2 text-sm font-medium text-gray-900`}
            htmlFor={name}
          >
            {label}
          </label>
          <div className={"flex space-x-4"}>
            <select
              name={`${name}_month`}
              value={selectedMonth}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-rose-500 focus:border-rose-500 block w-full p-2.5"
              onChange={(e) => {
                const month = parseInt(e.target.value);
                const selectedDate = new Date(selectedYear, month - 1, 1);
                changeFormDataValue(
                  name,
                  formatDate(selectedDate, "yyyy-mm-dd")
                );
              }}
            >
              {getMonthsOptions().map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <select
              name={`${name}_year`}
              value={selectedYear}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-rose-500 focus:border-rose-500 block w-full p-2.5"
              onChange={(e) => {
                const year = parseInt(e.target.value);
                const selectedDate = new Date(year, selectedMonth - 1, 1);
                changeFormDataValue(
                  name,
                  formatDate(selectedDate, "yyyy-mm-dd")
                );
              }}
            >
              {getYearsOptions().map((option) => (
                <option key={option.value} value={option.value}>
                  {option.value}
                </option>
              ))}
            </select>
          </div>
        </div>
      );
    case "list-checkbox":
      return (
        <div className="form-group w-full">
          <div className="flex items-center justify-between mb-4">
            <h4>Permissions</h4>

            <div className="checkbox-input__container">
              <div className="input-content">
                <span onClick={selectAllPermissions} className="cursor-pointer">
                  Tout {(isCheckedAll) ? 'déselectionner' : 'sélectionner'}
                </span>
              </div>
            </div>
          </div>

          <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg sm:flex sm:flex-wrap">
            {options.map((permission, i) => (
              <li key={i} className={`w-1/4 flex`}>
                <div className="flex p-2 pl-3">
                  <div className="flex items-center h-5">
                    <input
                      type="checkbox"
                      name={permission.name}
                      id={permission.id}
                      onChange={handleChange}
                      value={permission.id}
                      checked={value.includes(permission.id)}
                      className={`w-4 h-4 text-rose-600 bg-gray-100 rounded accent-rose-500 focus:ring-0 ${hasError ? "border-red-400 border-2" : "border-gray-300"} `}
                    />
                  </div>
                  <div className="ml-2 text-sm">
                    <label
                      htmlFor={permission.id}
                      className={`font-medium text-gray-900 cursor-pointer`}
                    >
                      {permission.name}
                    </label>
                  </div>
                </div>
                
              </li>
            ))}
          </ul>
        </div>
      );
    case "number":
      return (
        <div className={containerClass}>
            {label && (
              <label
                htmlFor={name}
                className={`block mb-2 text-sm font-medium text-gray-900`}
              >
                {label}
              </label>
            )}
            <input
              type="number"
              name={name}
              id={name}
              value={value}
              onChange={handleChange}
              disabled={disabled}
              className={`bg-gray-50 border border-gray-300 text-sm rounded-lg focus:outline-rose-500 focus:border-rose-500 block w-full p-2.5 ${
                disabled ? "text-gray-500 cursor-not-allowed" : "text-gray-900"
              } ${hasError ? "border-red-400 border-2" : "border-gray-300"}`}
              min={min ? min : undefined}
            />
        </div>
      );
    case "password":
      const togglePassword = () => {
        setPasswordInputIsSHown((isShown) => !isShown);
      };

      return (
        <div className={`relative ${containerClass}`}>
          {label && (
            <label
              htmlFor={name}
              className={`block mb-2 text-sm font-medium text-gray-900`}
            >
              {label}
            </label>
          )}
          <input
            type={passwordInputisShown ? "text" : "password"}
            name={name}
            id={name}
            value={value}
            onChange={handleChange}
            disabled={disabled}
            className={`bg-gray-50 border text-sm rounded-lg focus:outline-rose-500 focus:border-rose-500 block w-full p-2.5 ${disabled ? "text-gray-500 cursor-not-allowed" : "text-gray-900"}
              ${hasError ? "border-red-400 border-2" : "border-gray-300"}`}
          />
          {passwordInputisShown 
            ? (
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 hover:text-rose-500 cursor-pointer absolute bottom-2 right-2" onClick={togglePassword}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 hover:text-rose-500 cursor-pointer absolute bottom-2 right-2" onClick={togglePassword}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
            )
          }
          
        </div>
      )
    default:
      return (
        <div className={containerClass}>
          {label && (
            <label
              htmlFor={name}
              className={`block mb-2 text-sm font-medium text-gray-900`}
            >
              {label}
            </label>
          )}
          <input
            type={type || "text"}
            name={name}
            id={name}
            value={value}
            onChange={handleChange}
            disabled={disabled}
            className={`bg-gray-50 border text-sm rounded-lg focus:outline-rose-500 focus:border-rose-500 block w-full p-2.5 ${disabled ? "text-gray-500 cursor-not-allowed" : "text-gray-900"}
              ${hasError ? "border-red-400 border-2" : "border-gray-300"}`}
          />
        </div>
      );
  }
};

export default Input;
