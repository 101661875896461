const parseDate = (input, stringFormat, finalFormat = false) => {
  stringFormat = stringFormat || 'yyyy-mm-dd'; // default format
  var parts = input.match(/(\d+)/g), 
      i = 0, fmt = {};
  // extract date-part indexes from the format
  stringFormat.replace(/(yyyy|dd|mm)/g, function(part) { fmt[part] = i++; });

  const date = new Date(parts[fmt['yyyy']], parts[fmt['mm']]-1, parts[fmt['dd']])

  return (finalFormat) ? formatDate(date, finalFormat) : date;
}

const formatDate = (input, format) => {
  if(input){
    const year = input.getFullYear().toString();
    let month = (input.getMonth() + 1).toString();
    let day = input.getDate().toString();

    // Ajouter un zéro devant le mois et le jour si nécessaire
    if (month.length === 1) {
      month = '0' + month;
    }
    if (day.length === 1) {
      day = '0' + day;
    }

    // Remplacer les parties de format avec les valeurs de la date
    format = format.replace('yyyy', year);
    format = format.replace('mm', month);
    format = format.replace('dd', day);

    return format;
  }
}

const isDateValue = (value) => {
if(!value) return false;

if(isNaN(value)) return false;
return value instanceof Date;
};

const getMonthDiff = (d1, d2) => {
  var months;

  if(!isDateValue(d1)) d1 = parseDate(d1);
  if(!isDateValue(d2)) d2 = parseDate(d2);

  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}

const getYearDiff =(d1, d2) => {
  if(!isDateValue(d1)) d1 = parseDate(d1);
  if(!isDateValue(d2)) d2 = parseDate(d2);

  const year1 = d1.getFullYear();
  const year2 = d2.getFullYear();

  return year2 - year1;
}

const getYearOfFormatedDate = (date) => {
  if(!isDateValue(date))
    date = parseDate(date)

  return date.getFullYear();
}

const getMonthBeforeEndOfYear = (date) =>{
  const today = date ? new Date(date) : new Date();
  const month = today.getMonth() + 1;
  const monthsLeft = [];
  
  for (let i = month; i < 12; i++) {
    monthsLeft.push(i + 1);
  }

  return monthsLeft
}

export {parseDate, formatDate, isDateValue, getMonthDiff, getYearOfFormatedDate, getMonthBeforeEndOfYear, getYearDiff}