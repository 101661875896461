import React, { useEffect, useState } from 'react';
import Form from "../../components/Form/Form";
import Input from '../../components/Form/Input';
import Modal from '../../components/Modal/Modal';
import ModalBody from '../../components/Modal/ModalBody';
import ModalHeader from '../../components/Modal/ModalHeader';

const AddRole = ({showAddModal, setShowAddModal, permissions, role = false, handleAfterSubmitForm}) => {
    
    /** FORMULAIRE */
    const initialValues = {
     name: role ? role.name : undefined,
     permissions: role ? role.permissions.map((permission) => permission.id ) : undefined
    }

    const fields = [
      { name: 'name', label: 'Nom' },
      { name: 'permissions', label: 'Permissions', type: 'list-checkbox', options: permissions, isMultiple: true }
    ]

    return (
        <Modal
        show={showAddModal}
        onClose={() => setShowAddModal(false)}
        modalContainerclassName="p-4 sm:p-5"
      >
        <ModalHeader
          title={`${role ? "Modifier" : "Ajouter"} un role`}
          modalHeaderContainerclassName="p-0 pb-4"
        />

        <ModalBody modalBodyContainerclassName="px-0 pt-4 pb-0">
          <Form
            buttonTitle={(role) ? "Modifier" : "Ajouter"}
            method={(role) ? "put" : "post"}
            apiUrl={(role) ? `roles/${role.id}` : `roles`}
            fields={fields}
            initialValues={initialValues}
            onSubmit={handleAfterSubmitForm}
            
          >
          </Form>
        </ModalBody>
      </Modal>
    );
};

export default AddRole;