import React, { useState, useEffect } from 'react';

const Notification = ({ message, duration = 5000, onClose, type }) => {
  const [progress, setProgress] = useState(100);
  const notificationsColors = {
    danger: {container: "text-red-800 bg-red-50", closeBtn: "bg-red-50 text-red-500 focus:ring-red-400 hover:bg-red-200", progressBar: 'bg-red-500'},
    warning: {container: "text-yellow-800 bg-yellow-50", closeBtn: "bg-yellow-50 text-yellow-500 focus:ring-yellow-400 hover:bg-yellow-200", progressBar: 'bg-yellow-500'},
    success: {container: "text-green-800 bg-green-50", closeBtn: "bg-green-50 text-green-500 focus:ring-green-400 hover:bg-green-200", progressBar: 'bg-green-500'},
    info: {container: "text-blue-800 bg-blue-50", closeBtn: "bg-blue-50 text-blue-500 focus:ring-blue-400 hover:bg-blue-200", progressBar: 'bg-blue-500'},
    darkblue: {container: "text-darkblue-800 bg-darkblue-50", closeBtn: "bg-darkblue-50 text-darkblue-500 focus:ring-darkblue-400 hover:bg-darkblue-200", progressBar: 'bg-darkblue-500'},
    rose: {container: "text-rose-800 bg-rose-50", closeBtn: "bg-rose-50 text-rose-500 focus:ring-rose-400 hover:bg-rose-200", progressBar: 'bg-rose-500'},
  };

  // Progress bar
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => prevProgress - 1);
    }, duration / 100);

    return () => {
      clearInterval(interval);
    };
  }, [duration]);

  // Supprime la notification quand le temps est fini
  useEffect(() => {
    if (progress === 0) {
      onClose();
    }
  }, [progress, onClose]);

  return (
    <div className={`relative flex mt-4 mr-4 w-auto p-4 shadow-lg rounded-lg overflow-hidden max-w-lg ${notificationsColors[type].container}`}>
      <svg aria-hidden="true" className="flex-shrink-0 w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
      <div className="ml-3 text-sm"> 
        <span className="font-medium">{message?.message || message}</span>
        {message.list && (
          <ul className="mt-1.5 ml-4 list-disc list-inside">
            {Object.keys(message.list).map((key) => (
              <li key={key}>{message.list[key][0]}</li>
            ))}
          </ul>
        )}
      </div>
      <button type="button" className={`mx-1.5 -my-1.5 rounded-lg focus:ring-2 p-1.5 inline-flex h-8 w-8 flex-1 ${notificationsColors[type].closeBtn}`} onClick={onClose}>
        <svg aria-hidden="true" className="w-5 h-5 ml-auto" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
        </svg>
      </button>

      <div
        className={`h-1.5 ${notificationsColors[type].progressBar} absolute left-0 bottom-0`}
        style={{ width: `${progress}%`, transition: `width ${duration/100}ms linear` }}
      ></div>
    </div>
  );
};

export default Notification;
