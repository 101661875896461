const CalculROI = (projet) => {
    // Calcul ROI projet
    const sommeCoutEmploye =
    projet.time_employees?.length > 0
      ? // Calcul si le projet à des employés
        projet.time_employees.reduce(
          (somme, { cost_per_hour, employee_hour }) => {
            return (
              somme +
              parseFloat(cost_per_hour) * parseFloat(employee_hour)
            );
          },
          0
        )
      : 0;

  const sommeCoutPresta =
    projet.benefits?.length > 0
      ? // Calcul si le projet à des prestations
        projet.benefits.reduce((somme, { cost }) => {
          return somme + parseFloat(cost);
        }, 0)
      : 0;

  const roi =
    projet.sell_price > 0 &&
    sommeCoutEmploye + sommeCoutPresta > 0
      ? (
          ((sommeCoutEmploye + sommeCoutPresta) /
            projet.sell_price) *
          100
        ).toFixed(2)
      : 0;

    return  roi;
};

const CalculTotalROI = (projets) => {
    // Calcul somme des couts des projets
    let sommeCout = 0;
    projets.projects.map((proj) => {
      const sommeCoutEmploye =
        proj.time_employees?.length > 0
          ? // Calcul si le projet à des employés
            proj.time_employees.reduce((somme, { cost_per_hour, employee_hour }) => {
              return (
                somme + parseFloat(cost_per_hour) * parseFloat(employee_hour)
              );
            }, 0)
          : 0;

      const sommeCoutPresta =
        proj.benefits?.length > 0
          ? // Calcul si le projet à des prestations
            proj.benefits.reduce((somme, { cost }) => {
              return somme + parseFloat(cost);
            }, 0)
          : 0;

      sommeCout = sommeCout + sommeCoutPresta + sommeCoutEmploye;

      return proj;
    });
    
    // Calcul du ROI
    return projets.sell_price > 0 && sommeCout > 0
                ? ((sommeCout / projets.sell_price) * 100).toFixed(2)
                : 0;
}

const numberWithSpaces = (x, decimals = false) => {
  if(decimals){
    x = x.toFixed(decimals)
  }
  
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
}

export {CalculROI, CalculTotalROI, numberWithSpaces};