import React from 'react'

const ModalBody = ({children, modalBodyContainerClass}) => {
  return (
    <div className={`p-6 space-y-6 ${modalBodyContainerClass}`}>
        {children}
    </div>
  )
}

export default ModalBody