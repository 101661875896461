import React from "react";
import Modal from "../../components/Modal/Modal";
import ModalHeader from "../../components/Modal/ModalHeader";
import ModalBody from "../../components/Modal/ModalBody";
import Form from "../../components/Form/Form";
import { slugify } from "../../components/Utils/Slug";

const ActionCategoriesCouts = ({
  showModal, setShowModal, handleAfterSubmitForm, categoriesCouts= false
}) => {

   /** FORMULAIRE */
   const initialValues = {
    name: categoriesCouts ? categoriesCouts.name : undefined,
    label: categoriesCouts ? categoriesCouts.label: undefined,
    is_spent: categoriesCouts ? parseInt(categoriesCouts.is_spent) : true
   }

   const fields = [
    { name: 'label', label: 'Nom', updateWhenInputChange: {name: 'name', value: ({value}) => slugify(value)}},
    { name: 'is_spent', label: 'Dépense', type: 'toggle' },
    { name: 'name', type: 'hidden' },
  ]
  
  return(
      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <ModalHeader
          title={`${categoriesCouts ? "Modifier" : "Ajouter"} une catégorie`}
          modalHeaderContainerclassName="p-0 pb-4"
        />
        
        <ModalBody modalBodyContainerclassName="px-0 pt-4 pb-0">
          <Form
            buttonTitle={(categoriesCouts) ? "Modifier" : "Ajouter"}
            method={(categoriesCouts) ?"put" : "post"}
            apiUrl={(categoriesCouts) ? `categories-couts/${categoriesCouts.id}` : `categories-couts`}
            fields={fields}
            initialValues={initialValues}
            onSubmit={handleAfterSubmitForm}
            
          />
        </ModalBody>
      </Modal>
    )
};

export default ActionCategoriesCouts;
