import React, { useEffect, useState } from 'react';

const InputDate = ({setSelectedDate, defaultDate, label, classLabel , dashboard = false, onChange = false, reinitialize = false, customSelectClass = ''}) => {

    // ------------ Implementation pour l'input de type date --------------------

    const [editValueM, setEditValueM] = useState(); // Définit la valeur du mois pour le Select
    const [editValueY, setEditValueY] = useState(); // Définit la valeur de l'année pour le Select
    const [selectValueM, setSelectValueM] = useState(""); // Valeur pour le mois
    const [selectValueY, setSelectValueY] = useState(""); // Valeur pour l'année

    useEffect(() => {
        theDate();
    }, [editValueM, editValueY])


    useEffect(() => {
        if(reinitialize) {
            dateSelected(reinitialize);
        }
    }, [reinitialize])


    useEffect(() => {
        // Définis une date par défaut
        dateSelected();
    }, [])


    
    var date = new Date(); // Récupère la date actuelle
    var currentmounth = date.getMonth() + 1;
    currentmounth = currentmounth < 10 ? `0${currentmounth}` : currentmounth;
    var currentyear = date.getFullYear(); // Récupère l'année de la data actuelle
    

    function annee() { // Méthode pour récuperer les 5 années précedentes et les 5 années suivantes
        const annees = [] // Initialisation du tableau annees
         var theyear = new Date();
            for (let i = -5; i < 5; i++) {
                var year = theyear.getFullYear() + i; // On calcul l'année grace a l'année actuelle et i
                annees.push(<option key={i + 5} value={year}>{year}</option>) // On ajoute une ligne au tableau annees
            }
        
        
        return annees // renvoie la liste des opttions
    }

    if(defaultDate){
        var recupDate = new Date(defaultDate.split("/").reverse().join("/"));
        var recupDateMouth = recupDate.getMonth() + 1;
        recupDateMouth = recupDateMouth < 10 ? `0${recupDateMouth}` : recupDateMouth;
        var recupDateYear = recupDate.getFullYear();
    }
    
    
    const handleDateChangeM = (event) => { // méthode pour changer le mois quand le select change
        const value = event.target.value;
        setSelectValueM(value);
        setEditValueM(value);

        if(onChange)
            onChange()
    }

    const handleDateChangeY = (event) => { // méthode pour changer l'année quand le select change
        const value = event.target.value;
        setSelectValueY(value);
        setEditValueY(value);

        if(onChange)
            onChange()
    }

    const dateSelected = (reinitialize = false) =>{
        if( (!selectValueM && !selectValueY ) || reinitialize){
            if(defaultDate){
                setEditValueM(recupDateMouth);
                setEditValueY(recupDateYear);
                setSelectValueY(recupDateYear);
                setSelectValueM(recupDateMouth);
            } else {
                setEditValueM(currentmounth);
                setEditValueY(currentyear);
                setSelectValueM(currentmounth);
                setSelectValueY(currentyear);
            }
        }
    }

    const theDate = () => { // Méthode pour remplir le champs de la Date
        if (!selectValueM && !selectValueY && !editValueM && !editValueY) { // Si nous n'avons rien changer :
            setSelectedDate(`01/${currentmounth}/${currentyear}`) // Met la date du jour
        }else if(!selectValueM && !selectValueY && editValueM && editValueY){
            setSelectedDate(`01/${editValueM}/${editValueY}`)
        } else if (selectValueM && !selectValueY) { // Si nous avons changer seulement le mois :
            setSelectedDate(`01/${selectValueM}/${currentyear}`) // Met la date du jour avec le mois séléctionné
        } else if (!selectValueM && selectValueY) { // Si nous avons changer seulement l'année :
            setSelectedDate(`01/${currentmounth}/${selectValueY}`) // Met la date du jour avec l'année séléctionné
        } else { // Sinon
            setSelectedDate(`01/${selectValueM}/${selectValueY}`) // Met la date séléctionné
        }

    }
    // ---------------------------------------------

    return (
        <div>
            {label && (
              <label
                className={`block mb-2 text-sm font-medium text-gray-900 ${classLabel}`}
              >
                {label}
              </label>
            )}

            <div className={ dashboard ? "flex" : "flex space-x-4"}>
            <select id="mois" onChange={handleDateChangeM} value={editValueM} 
                className={`bg-white border border-gray-300 text-gray-900 focus:outline-rose-500 focus:border-rose-500 block ${dashboard ? "text-xs w-fit  p-2" : "text-sm rounded-lg w-40 p-2.5"} ${customSelectClass}`}>
                <option value="01">Janvier</option>
                <option value="02">Février</option>
                <option value="03">Mars</option>
                <option value="04">Avril</option>
                <option value="05">Mai</option>
                <option value="06">Juin</option>
                <option value="07">Juillet</option>
                <option value="08">Août</option>
                <option value="09">Septembre</option>
                <option value="10">Octobre</option>
                <option value="11">Novembre</option>
                <option value="12">Décembre</option>
            </select>
            <select id="annee" onChange={handleDateChangeY} value={editValueY} 
            className={`bg-white border border-gray-300 text-gray-900 focus:outline-rose-500 focus:border-rose-500 block ${dashboard ? 'text-xs w-fit rounded-tr-lg rounded-br-lg  p-2' : 'text-sm rounded-lg w-full p-2.5'} ${customSelectClass}`}>                {annee()}
            </select>
            </div>
        </div>
    );
};

export default InputDate;