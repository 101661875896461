import React, { useEffect, useState } from "react";
import axiosClient from "../../../axios-client";
import Form from "../../../components/Form/Form";

const CopyProjet = ({ projetValues, handleAfterSubmitForm }) => {
  const [categories, setCategories] = useState([]);
  const [clients, setClients] = useState([]);
  const [employes, setEmployes] = useState([]);

  const getSelectFieldsData = async () => {
    await axiosClient.get("/categories").then((res) => {
      setCategories(
        res.data.categories.map((category) => ({
          label: category.label,
          value: category.id,
        }))
      );
    });

    await axiosClient.get("/clients").then((res) => {
      setClients(
        res.data.clients.map((client) => ({
          label: client.label.toUpperCase(),
          value: client.id,
        }))
      );
    });

    await axiosClient.get("/employes").then((res) => {
      const employeesActive = res.data.employes.filter((employee) => parseInt(employee.active));
      setEmployes(employeesActive.map(employe => {
        return {label: `${employe.first_name} ${employe.last_name}`, value: employe.id, cost: parseFloat(employe.hourly_price)};
      }));
    });
  };

  /** FORMULAIRE - DÉBUT */
  const initialValues = {
    label: projetValues.data.label,
    sell_price: projetValues.data.sell_price,
    project_date: projetValues.data.project_date,
    category_id: projetValues.data.category_id,
    monthly:  projetValues.data.monthly,
    forecast:  projetValues.data.forecast,
    customer_id: projetValues.data.customer_id,
    // Ajout prestations
    add_benefit: projetValues.data.benefits[0] ? 1 : undefined,
    label_benefit: projetValues.data.benefits.map((benefit) => benefit.label),
    cost_benefit: projetValues.data.benefits.map((benefit) => benefit.cost),
    // Ajout employés
    add_employee: projetValues.data.time_employees[0] ? 1 : undefined,
    employee_hour: projetValues.data.time_employees.map((employee) => employee.employee_hour),
    comment: projetValues.data.time_employees.map((employee) => employee.comment),
    employee_id: projetValues.data.time_employees.map((employee) => employee.employee_id)
  }

  const fields = [
    { group: [
      { name: "label", label: "Nom du projet" },
      { name: "sell_price", label: "Prix de vente", type: "number" },
      { name: "project_date", label: "Date", type: "date"},
      {
        name: "category_id",
        label: "Catégorie",
        type: "select",
        options: categories,
      },
    ], groupClassName: "grid gap-4 sm:grid-cols-2 mt-0 mb-6"},
    { group: [
      {name: 'monthly', label: 'Projet mensuel', type: 'toggle'},
      {name: 'forecast', label: 'Projet prévisionnel', type: 'toggle'},
      {name: 'recurrence', label: 'Récurrence du projet', type: 'number'},
    ]},

    { group: [
      { name: "customer_id", label: "Client", type: "select", options: clients, width: "full"}
    ], groupClassName: "pb-2 border-b border-gray-200 flex flex-wrap gap-4"},

    // Ajouter des prestations
    {name: 'add_benefit', label: 'Ajouter une charge', type: 'repeater', inputs: [
      { name: 'label_benefit', label: 'Libelle', isMultiple: true, containerClass: 'w-1/2'},
      { name: 'cost_benefit', label: 'Coût', type: 'number', isMultiple: true, containerClass: 'w-1/2'},
    ], className: "pb-2 border-b border-gray-200 !mt-2"},

    // Ajouter des employés
    {name: 'add_employee', label: 'Ajouter un employé', type: 'repeater', inputs: [
      { name: 'employee_id', label: 'Employé', isMultiple: true, type: 'autocompletion', containerClass: 'w-1/2', options: employes},
      { name: 'employee_hour', label: 'Heure', type: 'number', isMultiple: true, containerClass: 'w-1/4'},
      { type: 'dynamic', value: (formData, i) => {
        // Génère le coût par employé lorsque l'on sélectionne
        if(formData.employee_id && formData.employee_hour){
          const employeeHourlyCost = employes.filter(employee => employee.value === parseInt(formData.employee_id[i]));
          const employeeCost = employeeHourlyCost[0]?.cost * formData.employee_hour[i];

          if(employeeCost && employeeCost > 0)
            return (
              <span className="w-1/5 mt-8">{parseFloat(employeeCost).toFixed(2)} €</span>
            )
        }
      }},
      { name: 'comment', label: 'Commentaire', isMultiple: true, containerClass: 'w-1/2'},
    ], className: "pb-2 border-b border-gray-200 !mt-2"},
  ];
  /** FORMULAIRE - FIN */

  // Initialise la liste des catégories, clients et employés
  useEffect(() => {
    getSelectFieldsData();
  }, [])
  
  return (
    <Form
        method="post"
        apiUrl="projets"
        fields={fields}
        initialValues={initialValues}
        onSubmit={handleAfterSubmitForm}
        buttonTitle="Copier"
    />
  )
};

export default CopyProjet;
