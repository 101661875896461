import React, { useEffect, useState } from 'react'
import Dropdown from '../../components/Dropdown/Dropdown'
import axiosClient from '../../axios-client';
import DropdownListCheckbox from '../../components/Dropdown/DropdownListCheckbox';
import InputDate from '../../components/Form/InputDate';
import { formatDate, parseDate } from '../../components/Utils/Dates';

const Filters = ({ setFilters, filters, setAfficherTableauGlobal, tableauGlobal, handleSubmitFilter }) => {
    const [resetFilters, setResetFilters] = useState(false);

    // Récupère et stocke la liste des employés
    const [listeEmployes, setListeEmployes] = useState({});
    const displayEmployes = () => {
        axiosClient
            .get(`/employes`, {
                params: {
                    order: 'asc'
                }
            })
            .then((res) => {
                setListeEmployes({ active: res.data.employes.filter((employe) => parseInt(employe.active) && employe.role.name != 'admin'), inactive: res.data.employes.filter((employe) => !parseInt(employe.active)) })
            });
    };

    const [poles, setPoles] = useState([])
    const displayPoles = async () => {
        await axiosClient.get('/poles').then((res) => {
            setPoles(res.data.poles);
        })
    };

    useEffect(() => {
        displayPoles();
        displayEmployes();
    }, [])

    const afficherTableauGlobal = () => {
        setAfficherTableauGlobal(!tableauGlobal)
    }

    // Méthode permettant de faire une recherche d'un employé parmis la liste
    const [employeSearchValue, setEmployeSearchValue] = useState(false);
    const handleSearchEmploye = async (event) => {
        const { value } = event.target
        if (value)
            await setEmployeSearchValue(value);
        else
            await setEmployeSearchValue(false);
    }

    const handleChangeEmployeFiltre = async (event) => {
        const { value, checked } = event.target;
        await setFilters((crtFilter) => {
            const selectedIds = crtFilter.employes || [];
            const newSelectedIds = checked
                ? [...selectedIds, parseInt(value)]
                : selectedIds.filter((selectedId) => selectedId !== parseInt(value))
                ;

            return { ...crtFilter, employes: newSelectedIds }
        })
    }

    // Méthode pour sélectionner/deselectionner tous les employés
    const [allEmployeeSelected, setAllEmployeeSelected] = useState({ active: false, inactive: false });
    const handleSelectAll = async (isActiveEmployee) => {
        const btnSelect = isActiveEmployee ? 'active' : 'inactive';

        if (allEmployeeSelected[btnSelect]) {
            await listeEmployes[btnSelect].map(employe => handleChangeEmployeFiltre({ target: { value: employe.id, checked: false } }))
            await setAllEmployeeSelected((data) => ({ ...data, [btnSelect]: false }));
        } else {
            await listeEmployes[btnSelect].map(employe => handleChangeEmployeFiltre({ target: { value: employe.id, checked: true } }))
            await setAllEmployeeSelected((data) => ({ ...data, [btnSelect]: true }));
        }
    }

    // Méthode pour filtre la date
    const [selectedDate, setSelectedDate] = useState({});
    const [changedDate, setChangedDate] = useState(false);
    const [reinitializeDate, setReinitializeDate] = useState(false);

    // Change le filtre date
    const handleChangeDate = async () => {
        await setFilters((crtFilter) => {
            const dateDebut = parseDate(selectedDate.debut, 'dd/mm/yyyy', 'yyyy-mm-dd')
            const dateFin = parseDate(selectedDate.fin, 'dd/mm/yyyy', 'yyyy-mm-dd')
            return { ...crtFilter, dates: { debut: dateDebut, fin: dateFin } }
        })
    }

    // Quand la date est mise à jour par le composant InputDate
    useEffect(() => {
        if (changedDate) {
            handleChangeDate();
            setChangedDate(false);
            setReinitializeDate(false);
        }
    }, [selectedDate])

    useEffect(() => {
        if (reinitializeDate)
            setReinitializeDate(false)
    }, [reinitializeDate])

    // Méthode pour les boutons d'affichage par mois
    const handleShowNextMonth = (monthToAdd, oneMonth = false) => {
        const todayDate = new Date();
        todayDate.setDate(1);

        const nextDate = new Date(todayDate);
        nextDate.setMonth(nextDate.getMonth() + monthToAdd);

        const startDate = formatDate(oneMonth ? nextDate : todayDate, 'yyyy-mm-01');
        const endDate = formatDate(nextDate, 'yyyy-mm-01');

        setFilters((crtFilter) => {
            return { ...crtFilter, dates: { debut: startDate, fin: endDate } }
        })

        setReinitializeDate(true);
    }

    // Méthode pour filtrer par type de projet (Prévisionnel/vendus)
    const handleChangeFiltrePrevisionnel = async (event) => {
        const { value } = event.target;
        await setFilters(crtFilter => ({ ...crtFilter, projetsType: value }))
    }
    const handleChangeFiltrePole = async (event) => {
        const { value } = event.target;
        await setFilters(crtFilter => ({ ...crtFilter, pole: value }))
    }

    // Reset les filtres
    const handleResetFilter = async () => {
        const todayDate = new Date();
        todayDate.setDate(1);
        const sixMonthsLater = new Date(todayDate);
        sixMonthsLater.setMonth(sixMonthsLater.getMonth() + 3);
        setFilters(() => ({ dates: { debut: formatDate(todayDate, 'yyyy-mm-01'), fin: formatDate(sixMonthsLater, 'yyyy-mm-01') }, projetsType: 'all', pole: 'all' }))
        setReinitializeDate(true);

        setResetFilters(true);
    }

    useEffect(() => {
        if(resetFilters){
          handleSubmitFilter();
          setResetFilters(false)
        }
      }, [resetFilters])


    return (
        <div className="w-fit p-4 pt-3 mb-4 rounded-lg bg-gray-200 relative">
            <span className="uppercase text-muted text-[12px] font-bold ">
                Filtrer par
            </span>


            <div className="flex justify-between align-center mt-2 mb-4">
                <div className="flex space-x-4 items-center">
                    {/* Dropdown employés */}
                    <Dropdown
                        buttonTitle="Employés"
                        buttonClass="inline-flex items-center text-white rounded-lg p-2.5 bg-darkblue-600 hover:bg-darkblue-700 text-sm"
                        menuClass="!mt-2 shadow-md pt-4 max-h-[25rem] overflow-auto"
                        closeOnClick={false}
                    >
                        <div>
                            <div className='px-3 mb-2'>
                                <input type="text"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-rose-500 focus:border-rose-500 block w-full px-2.5 py-1.5"
                                    placeholder='Rechercher un employé'
                                    onChange={handleSearchEmploye}
                                    value={employeSearchValue ? employeSearchValue : ''}
                                />
                            </div>

                            <hr className='mt-2 mb-2' />
                            <div className='flex justify-between items-center'>
                                <span className='ml-auto text-[14px] px-3 text-gray-600 hover:text-rose-700 cursor-pointer' onClick={() => handleSelectAll(true)}>Tout {allEmployeeSelected.active ? 'déselectionner' : 'sélectionner'}</span>
                            </div>



                            {listeEmployes.active &&
                                listeEmployes.active
                                    // Filtre pour trouver un employé si il y'a une recherche d'employé
                                    .filter(employe => (employeSearchValue) ? employe.first_name.toLowerCase().includes(employeSearchValue.toLowerCase()) || employe.last_name.toLowerCase().includes(employeSearchValue.toLowerCase()) : true)
                                    .map((employe) => (
                                        <DropdownListCheckbox
                                            label={`${employe.last_name} ${employe.first_name}`}
                                            value={employe.id}
                                            name={`employee_id_${employe.id}`}
                                            closeOnCLick={false}
                                            key={employe.id}
                                            onChange={handleChangeEmployeFiltre}
                                            checked={filters.employes?.includes(employe.id)}
                                        />
                                    ))
                            }
                        </div>
                    </Dropdown>

                    {/* Filtre Pôle */}
                    <Dropdown
                        buttonTitle="Pôles"
                        buttonClass="inline-flex items-center text-white rounded-lg p-2.5 bg-darkblue-600 hover:bg-darkblue-700 text-sm"
                        menuClass="!mt-2 shadow-md p-4"
                        closeOnClick={false}
                    >

                        <DropdownListCheckbox
                            label="Tout afficher"
                            type="radio"
                            onChange={handleChangeFiltrePole}
                            checked={filters.pole === 'all'}
                            value='all'
                            classNameContainer="px-0 flex items-center justify-between py-1"
                        />
                            {poles
                                    .map((pole) => (
                                        <DropdownListCheckbox
                                            label={`${pole.label}`}
                                            value={pole.name}
                                            key={pole.id}
                                            type="radio"
                                            onChange={handleChangeFiltrePole}
                                            checked={filters.pole === pole.name}
                                            classNameContainer="px-0 flex items-center justify-between py-1"
                                        />

                                    ))
                            }

                    </Dropdown>

                    {/* Filtre dates */}

                    <div className="flex justify-between gap-2">
                        <div className="flex">
                            <span className="bg-darkblue-100 p-2 text-white rounded-tl-md rounded-bl-md select-none text-sm flex items-center">Du</span>
                            <InputDate
                                setSelectedDate={(data) => setSelectedDate((crtDate) => ({ ...crtDate, debut: data }))}
                                onChange={() => setChangedDate(true)}
                                defaultDate={filters.dates.debut}
                                dashboard={true}
                                reinitialize={reinitializeDate}
                            />
                        </div>
                        <div className="flex">
                            <span className=" bg-darkblue-100 p-2 text-white rounded-tl-md rounded-bl-md select-none text-sm flex items-center">Au</span>
                            <InputDate
                                setSelectedDate={(data) => setSelectedDate((crtDate) => ({ ...crtDate, fin: data }))}
                                onChange={() => setChangedDate(true)}
                                defaultDate={filters.dates.fin}
                                dashboard={true}
                                reinitialize={reinitializeDate}
                            />
                        </div>
                    </div>

                    {/* Dropdown type projets (prévisonnel/vendus) */}
                    <Dropdown
                        buttonTitle="CA"
                        buttonClass="inline-flex items-center text-white rounded-lg p-2.5 bg-darkblue-600 hover:bg-darkblue-700 text-sm"
                        menuClass="!mt-2 shadow-md p-4"
                    >
                        <DropdownListCheckbox
                            label="Tout afficher"
                            classNameContainer="px-0 flex items-center justify-between py-1"
                            value="all"
                            type="radio"
                            onChange={handleChangeFiltrePrevisionnel}
                            checked={filters.projetsType === "all"}
                        />

                        <DropdownListCheckbox
                            label="CA vendus"
                            classNameContainer="px-0 flex items-center justify-between py-1"
                            value="vendu"
                            type="radio"
                            onChange={handleChangeFiltrePrevisionnel}
                            checked={filters.projetsType === "vendu"}
                        />

                        <DropdownListCheckbox
                            label="CA prévi"
                            classNameContainer="px-0 flex items-center justify-between py-1"
                            value="previsionnel"
                            type="radio"
                            onChange={handleChangeFiltrePrevisionnel}
                            checked={filters.projetsType === "previsionnel"}
                        />
                    </Dropdown>
                </div>

                <div className='flex space-x-2'>
                    
                </div>
            </div>

            <div className='flex space-x-2'>
                <button className={`inline-flex items-center text-white rounded-lg p-2.5 bg-rose-600 hover:bg-rose-700 text-sm`}
                    onClick={() => handleShowNextMonth(0, true)}>
                    Afficher le mois actuel
                </button>
                <button className={`inline-flex items-center text-white rounded-lg p-2.5 bg-rose-600 hover:bg-rose-700 text-sm`}
                    onClick={() => handleShowNextMonth(1, true)}>
                    Afficher le prochain mois
                </button>
                <button className={`inline-flex items-center text-white rounded-lg p-2.5 bg-rose-600 hover:bg-rose-700 text-sm`}
                    onClick={() => handleShowNextMonth(3)}>
                    Afficher les 3 prochains mois
                </button>

                    
                    <button className="inline-flex !ml-16 items-center text-white uppercase rounded-lg p-2.5 bg-darkblue-600 hover:bg-darkblue-700 text-xs"
                    onClick={handleSubmitFilter}>Filtrer</button>

                    <button className="inline-flex items-center text-white rounded-lg p-2.5 bg-yellow-500 hover:bg-yellow-600 text-xs" onClick={afficherTableauGlobal}>
                        {tableauGlobal ? 'Tableau' : 'Global'}
                    </button>

                    <span
                    className="self-end uppercase text-[12px] !ml-10 !mr-0 w-fit block cursor-pointer hover:underline flex-0 basis-auto text-right"
                    onClick={handleResetFilter}
                    >
                    Réinitialiser les filtres
                    </span>

            </div>

        </div>
    )
}

export default Filters