import React, { useContext, useEffect, useRef, useState } from "react";
import { CalculROI, CalculTotalROI } from "../../../components/Utils/CalculROI";
import { useStateContext } from "../../../contexts/ContextProvider";
import { NotificationContext } from "../../../contexts/NotificationContext";
import axiosClient from "../../../axios-client";

const RowProjet = ({
// Gestion affichage des projets
  row,
  projets,
  mockData,
  displayCoutFixe,

//   Gestion ouverture/fermeture des projets
  setIsOpen,
  isOpen = false,
  openList = [],

// Gestion ouverture/fermeture du modal d'action
    setShowModal,
    setModalData,
    setShowProjectAddModal
}) => {
  const {user} = useStateContext();
  const { addNotification } = useContext(NotificationContext);

  // Ajout des champs pour l'ajout de prestations/employés
  const [employes, setEmployes] = useState([]);
  const defaultPrestationContent = {label: '', cost: 1}
  const defaultEmployeContent = {employee_hour: 1, comment: ''}
  const [addFields, setAddFields] = useState();

  // Champs edit
  const [editFields, setEditFields] = useState();

  /** AUTO COMPLETION CHAMPS EMPLOYÉS */
  const hiddenEmployeeInputSelectRef = useRef(null);
  const [employeInputValue, setEmployeInputValue] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [employeesListFiltered, setEmployeesListFiltered] = useState([]);
  const [showEmployeesList, setShowEmployeesList] = useState(false);

  // Edit field
  const hiddenEditEmployeeInputSelectRef = useRef(null);
  const [employeEditInputValue, setEmployeEditInputValue] = useState("");
  const [selectedEditEmployee, setSelectedEditEmployee] = useState(null);
  const [employeesEditListFiltered, setEmployeesEditListFiltered] = useState([]);
  const [showEditEmployeesList, setShowEditEmployeesList] = useState(false);

  const handleSubmitEdit = async (type, projectId, openOtherField = false) => {
    await axiosClient({
      url: (type == 'benefits' ? `/prestations/${editFields.id}` : `/temps/${editFields.id}`),
      method: 'PUT',
      data: editFields.value
    }).then(({data}) => {
      if(data.success){
        addNotification(data.message, 'success')
        
        if(!openOtherField) setEditFields(false);

        let selectedProject = projets.filter((projet) => {
            return projet.id === parseInt(projectId);
        })

        const newSelectedProject = selectedProject[0][type].map((projectData) => {
          if(projectData.id === parseInt(editFields.id)){
            projectData = data[type];
          }
          return projectData;
        })

        selectedProject[0][type] = newSelectedProject;

        // Modifie les valeurs du ROI et PV dans le tableau
        const customer = mockData.filter((customer) => customer.id === parseInt(data.data.customer_id))[0]
        row.values.roi = CalculTotalROI(customer);

        if(type === 'benefits') displayCoutFixe();
      }else{
        addNotification({message: data.message, list: data.data}, 'danger')
      }
    })
  }

  const getAllEmploye = async () => {

    const response = await axiosClient({
      url: "/employes",
      method: 'get',
    });

    if (response.data.success) {
      response.data.employes
        .map((employe) => {
          return employe.name = `${employe.first_name} ${employe.last_name}`;
        })
        .filter((employe) => employe.active);
        
      setEmployes(response.data.employes);
      return response.data.employes;
    }
  };

  
  // Change la valeur du champs correspondant dans la liste des champs
  const handleChange = (id, type, index, name, event, callback) => {
    const {value} = event?.target || event;
    const newFields = [...addFields[id][type]];
    newFields[index][name] = value;

    setAddFields((crtField) => ({...crtField, [id]: {...crtField[id], [type]: newFields}}))

    if(callback) callback(event)
  }


  // Ajoute un champ
  const handleAddField = (type, id) => {  
    if(type == 'time_employees') {
      if(employes.length == 0)
        getAllEmploye().then(employe => setAddFields((crtField) => {
          const newField = crtField || [];
          const fieldsType = newField[id] || {benefits: [], time_employees: []}

          return {...newField, [id]: {...fieldsType, [type]: [...fieldsType[type], defaultEmployeContent]}}
        }));
      else
        setAddFields((crtField) => {
          const newField = crtField || [];
          const fieldsType = newField[id] || {benefits: [], time_employees: []}

          return {...newField, [id]: {...fieldsType, [type]: [...fieldsType[type], defaultEmployeContent]}}
        })
    }else {

      setAddFields((crtField) => {
        const newField = crtField || [];
        const fieldsType = newField[id] || {benefits: [], time_employees: []}

        return {...newField, [id]: {...fieldsType, [type]: [...fieldsType[type], defaultPrestationContent]}}

      });
    }
  }
  
  // Retire un champ
  const handleRemoveField = (type, index, id, openOtherField = false) => {
    

    if(openOtherField){
      const newFields = {...addFields};
      delete newFields[id];

      setAddFields({...newFields})
    }
    else{
      const newFields = [...addFields[id][type]];
      newFields.splice(index, 1);
      setAddFields((crtField) => ({...crtField, [id]: {...crtField[id], [type]: newFields}}))
    }
    
    // Reset employee autocompletion dataa
    setEmployeInputValue("")
    setSelectedEmployee(null)
    setEmployeesListFiltered([])
    setShowEmployeesList(false)

    if(openOtherField)
    handleAddField(type, openOtherField)
  }

  // Envoie le formulaire lorsque le champs est validé
  const handleSubmitFieldForm = async (type, index, projectId, openOtherField = false) => {
    await axiosClient({
      url: (type == 'benefits' ? '/prestations' : '/temps'),
      method: 'post',
      data: {...addFields[projectId][type][index], project_id: projectId},
    }).then(({ data }) => {
      if(data.success){
        addNotification(data.message, 'success')

        let selectedProject = projets.filter((projet) => {
            return projet.id == projectId;
        })

        const crtTypeSelectedProject = selectedProject[0][type] || [];
        selectedProject[0][type] = [...crtTypeSelectedProject, {...data[type]}]

        // Modifie les valeurs du ROI et PV dans le tableau
        const customer = mockData.filter((customer) => customer.id === parseInt(data.data.customer_id))[0]
        row.values.roi = CalculTotalROI(customer);

        handleRemoveField(type, index, projectId, openOtherField)

        
        if(type === 'benefits') displayCoutFixe();
      }else{
        if(openOtherField) {
          if(!addFields?.[openOtherField]?.[type])
            addNotification({message: "L'employé n'a pas été ajouter"})

          handleRemoveField(type, index, projectId, openOtherField)
        }else{
          addNotification({message: data.message, list: data.data}, 'danger')
        }
      }
    });
  }

  // Fonction exécuter quand on clique sur un client 
  const clickOpenProjets = () => {
    // Si le tableau qui gère les projets ouvert ne contient pas l'id du client, alors on l'ajoute
    if (!openList.includes(row.id)) {
      setIsOpen((prevIds) => [...prevIds, row.id]);
    } else {
      setIsOpen(openList.filter((ids) => !ids.includes(row.id)));
    }
  };

  const openModalAction = (data) => {
    setShowModal(true);
    setModalData(data);
  }

  const handleInputChange = (event, edit = false) => {
    const value = event.target.value;
    const filtered = employes.filter((employe) => {
      const label = employe.last_name + ' ' + employe.first_name;
      return label.toLowerCase().includes(value.toLowerCase())
    });

    if(!edit){
      setEmployeInputValue(value);
      setEmployeesListFiltered(filtered.filter((employee) => parseInt(employee.active) && employee.role.name != 'admin'));
      setShowEmployeesList(true);
    }else{
      setEmployeEditInputValue(value);
      setEmployeesEditListFiltered(filtered.filter((employee) => parseInt(employee.active) && employee.role.name != 'admin'));
      setShowEditEmployeesList(true);
    }
  };

  const handleOptionClick = (option, edit = false) => {
    if(!edit){
      setEmployeInputValue(option.last_name + ' ' + option.first_name);
      setSelectedEmployee(option);
      setEmployeesListFiltered([]);
      setShowEmployeesList(false);
    }else{
      setEmployeEditInputValue(option.last_name + ' ' + option.first_name);
      setSelectedEditEmployee(option);
      setEmployeesEditListFiltered([]);
      setShowEditEmployeesList(false);
    }
  };

  const handleKeyDown = (event, edit = false) => {
    if (event.key === "Tab" && (edit ? employeesEditListFiltered.length : employeesListFiltered.length) > 0) {
      event.preventDefault();
      if(!edit){
        setEmployeInputValue(employeesListFiltered[0].last_name + ' ' + employeesListFiltered[0].first_name);
        setSelectedEmployee(employeesListFiltered[0]);
        setEmployeesListFiltered([]);
        setShowEmployeesList(false);
      }else{
        setEmployeEditInputValue(employeesEditListFiltered[0].last_name + ' ' + employeesEditListFiltered[0].first_name);
        setSelectedEditEmployee(employeesEditListFiltered[0]);
        setEmployeesEditListFiltered([]);
        setShowEditEmployeesList(false);
      }
    }
  };

  useEffect(() => {
    if (selectedEmployee) {
      const {projetid, index} = hiddenEmployeeInputSelectRef.current.dataset;

      handleChange(projetid, 'time_employees', index, 'employee_id', hiddenEmployeeInputSelectRef.current, () => {
        const {value} = hiddenEmployeeInputSelectRef.current;
        const employePrixHeure = employes.filter((employe) => employe.id == value)[0].hourly_price;

        return addFields[projetid].time_employees[index].cost_per_hour = employePrixHeure
      })
    }
  }, [selectedEmployee]);

  useEffect(() => {
    if (selectedEditEmployee) {
      const value = hiddenEditEmployeeInputSelectRef.current.value;

        setEditFields((editField) => {
          const employePrixHeure = employes.filter((employe) => employe.id === parseInt(value))[0];
          return {...editField, value: {...editField.value, employee_id: value, cost_per_hour: employePrixHeure.hourly_price, name: employePrixHeure.last_name + ' ' + employePrixHeure.first_name}}
        })
    }
  }, [selectedEditEmployee]);

  return (
    <>
      <tr
        className={`bg-white border-b hover:bg-gray-100 ${
          projets.length > 0 ? "cursor-pointer" : ""
        } `}
        {...row.getRowProps()}
        onClick={clickOpenProjets}
        tabIndex={0}
      >
        {/* Génère mes cellules avec les informations du client */}
        {row.cells.map((cell, i) => {
          // Format le nombre du prix de vente
          const sellPrice = parseFloat(cell.row.values.sell_price);
          const formatSellPrice = sellPrice.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');    

          // Colonne pour le bouton d'ajout rapide d'un projet
          if(cell.column.id === 'addProjet') return(
            <td
              className={`${
                cell.column.className ? cell.column.className : (cell.column.id != 'totalHeuresEmployeByClient') ? "uppercase" : ""
              } p-3 px-5
              ${
                cell.column.id === "roi"
                  ? cell.row.values.roi <= 37.0
                    ? "text-green-500"
                    : "text-red-500"
                  : ""
              } ${isOpen ? 'text-darkblue-100 font-bold' : ''}`}
              {...cell.getCellProps()}

            >
              {/* Bouton ajouter un projet */}
              {(user.role.name === 'admin' && isOpen) && (
                <div className="flex justify-center items-center" tabIndex={0}>
                  <button
                    className={`inline-flex items-center text-darkblue-100 focus:outline-none focus:ring-4 font-medium text-sm hover:text-rose-500 cursor-pointer`}
                    onClick={(e) => {
                      e.stopPropagation();
                      // openModalAction({title: 'Ajouter un projet', edit: true, type: 'addWithClient', apiUrl: `projets`, data: {customer_id: cell.row.original.id}})
                      setShowProjectAddModal(true);
                      setModalData({data: {add_project_customer:true ,customer_id: cell.row.original.id}});
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </button>
                </div>
                )}
            </td>
          );

          return (
            <td
              className={`${
                cell.column.className ? cell.column.className : (cell.column.id != 'totalHeuresEmployeByClient') ? "uppercase" : ""
              } p-3 px-5
              ${
                cell.column.id === "roi"
                  ? cell.row.values.roi <= 37.0
                    ? "text-green-500"
                    : "text-red-500"
                  : ""
              } ${isOpen ? 'text-darkblue-100 font-bold' : ''}`}
              {...cell.getCellProps()}
            >
              {cell.column.id === "sell_price" ? formatSellPrice : cell.render("Cell")}
              {cell.column.id === "sell_price" ? " €" : ""}
              {cell.column.id === "totalHeuresEmployeByClient" ? "h" : ""}
              {cell.column.id === "roi" ? " %" : ""}
            </td>
          );
        })}
      </tr>

      {/* Génère les projets du client */}
      {projets.length > 0 && isOpen && (
        <tr className="border-l-2 border-darkblue-700 bg-darkblue-700/[0.05]">
          <td colSpan={row.cells.length}>
            <div className="p-3 px-5">
            
              {projets.map((projet) => {
                // Format nombre
                const nf = new Intl.NumberFormat();

                // Format la date en MM/YYYY
                const { project_date } = projet;
                const date = new Date(project_date);

                if (!isNaN(date)) {
                  const dateM = date.getMonth();
                  const dateY = date.getFullYear();
                  const monthYearFormat = `${("0" + (dateM + 1)).slice(-2)}/${dateY}`;
                  projet.date_projet_format = monthYearFormat;
                }

                // Calcul ROI
                const roi = CalculROI(projet);

                return (
                  <div
                    key={projet.id}
                    className={`w-full mb-4 rounded-lg p-2.5 ${projet.forecast ? 'bg-darkblue-500/[.85] text-white' : 'bg-white' }`}
                  >
                    <table className={`w-full border-separate mb-4 pb-2 border-b ${projet.forecast ? 'border-gray-400' : 'border-gray-200' } table-fixed`}>
                      <thead>
                        <tr>
                          <th className="uppercase w-4/12 pr-2 relative">
                            {projet.monthly
                              ? (
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7 absolute left bg-white text-gray-500 rounded-full p-2 -left-2.5 -top-2.5 translate-y-[-50%] translate-x-[-50%] drop-shadow-md">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3" />
                                </svg>

                              ) : ''}
                            Désignation</th>
                          <th className="uppercase w-1/12 pr-2">Date</th>
                          <th className="uppercase w-2/12 pr-2">Catégorie</th>
                          {(user.role.name != 'user') && (
                            <th className="uppercase w-2/12 pr-2">Prix de vente</th>
                          )}
                          {(user.role.name === 'admin') && (
                            <th className="uppercase w-1/12 pr-2">ROI</th>
                          )}
                          <th className="w-2/12"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="">{projet.label}</td>
                          <td>{projet.date_projet_format}</td>
                          <td>{projet.category.label}</td>
                          <td>{(user.role.name === 'admin' || user.role.name === 'manager') ? nf.format(projet.sell_price) + ' €' : ''} </td>
                          <td
                            className={
                              roi <= 37.0 ? (projet.forecast ? "text-[#28e66e]" : "text-green-500") : (projet.forecast ? "text-[#ff2020]" : "text-red-500")
                            }
                          >
                            {user.role.name === 'admin' ? roi + ' %' : ''}
                          </td>
                          {(user.role.name === 'admin') && (
                          <td>
                            {/* Boutons action projet */}

                            {/* Edit */}
                            <button
                              onClick={() => {
                                openModalAction({title: 'Modifier un projet', edit: true, type: 'editProjet', apiUrl: `projets/${projet.id}`, projetId: projet.id, data: projet})
                              }}
                              className="cursor-pointer mr-1 outline-none focus:outline-none editer"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-4 h-4"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                />
                              </svg>
                            </button>

                            {/* Supprimer */}
                            <button
                              className="cursor-pointer mr-1 outline-none focus:outline-none supprimer"
                              type="button"
                              onClick={() => {
                                openModalAction({edit: false, delete: true, apiUrl: "projets", id: projet.id})
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-4 h-4"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                />
                              </svg>
                            </button>

                            {/* Dupliquer */}
                            <button type="button"
                              onClick={() => {
                                openModalAction({title: 'Copier un projet', edit: false, type: 'copyProjet', apiUrl: `projets`, projetId: projet.id, data: projet})
                              }}>
                                <svg 
                                  xmlns="http://www.w3.org/2000/svg" 
                                  fill="none" 
                                  viewBox="0 0 24 24" 
                                  strokeWidth={1.5} 
                                  stroke="currentColor" className="w-4 h-4">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 8.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v8.25A2.25 2.25 0 006 16.5h2.25m8.25-8.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-7.5A2.25 2.25 0 018.25 18v-1.5m8.25-8.25h-6a2.25 2.25 0 00-2.25 2.25v6" />
                                </svg>


                              </button>
                          </td>
                          )}
                        </tr>
                      </tbody>
                    </table>

                    {/* Table prestations */}
                    <table className={`w-full border-separate mb-4 pb-2 border-b ${projet.forecast ? 'border-gray-400' : 'border-gray-200' } table-fixed`}>
                      <thead>
                        <tr>
                          <th className="uppercase w-4/12 pr-2">Charge</th>
                          {(user.role.name === 'admin') && (
                            <th className="uppercase w-3/12 pr-2">Montant</th>
                          )}
                          {user.role.name === 'admin' && (
                          <th className="w-5/12 text-right text-xs uppercase font-normal"
                          onClick={() => {
                            handleAddField('benefits', projet.id)
                          }}>
                            <div className="inline-flex item-center flex-end hover:text-rose-500 cursor-pointer">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-4 h-4 mr-1"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M12 6v12m6-6H6"
                                />
                              </svg>
                              Ajouter une charge
                            </div>
                          </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                          {(user.role.name === 'admin' && addFields?.[projet.id]) && 
                            addFields[projet.id].benefits.map((field, i) => (
                              <tr key={`benifit-input-${i}`}>
                                <td>
                                  <input type="text" name="label" value={field.label} placeholder="Détail" onChange={(e) => handleChange(projet.id, 'benefits', i, 'label', e)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-rose-500 focus:border-rose-500 block w-full p-1 mb-2 text-sm"/>
                                </td>
                                <td>
                                  <input type="number" min={1} name="cost" value={field.cost} onChange={(e) => handleChange(projet.id, 'benefits', i, 'cost', e)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-rose-500 focus:border-rose-500 block w-full p-1 mb-2 text-sm"/>
                                </td>
                                <td>
                                  <div className="flex gap-2 mb-2">

                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-1 hover:text-rose-500 cursor-pointer" onClick={() => handleSubmitFieldForm('benefits', i, projet.id)}>
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                    </svg>

                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 hover:text-rose-500 cursor-pointer" onClick={() => handleRemoveField('benefits', i,  projet.id)}>
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                  </div>
                                </td>
                              </tr>
                            ))}

                        {projet.benefits.map((prestation, i) => (
                          <tr key={prestation.id}>
                            {editFields && editFields?.active && editFields?.id === prestation.id && editFields?.type === 'benefits'
                              ? 
                              <>
                                <td><input type="text" name="label" value={editFields.value.label || editFields.value.label === '' ? editFields.value.label : prestation.label} placeholder="Détail"  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-rose-500 focus:border-rose-500 block w-full p-1 mb-2 text-sm"
                                  onChange={(e) => {
                                    setEditFields((editField) => {
                                      return {...editField, value: {...editField.value, label: e.target.value}}
                                    })
                                  }}
                                /></td>
                                <td><input type="number" min={1} name="cost" value={editFields.value.cost ? editFields.value.cost : ''} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-rose-500 focus:border-rose-500 block w-full p-1 mb-2 text-sm" 
                                 onChange={(e) => {
                                  setEditFields((editField) => {
                                    return {...editField, value: {...editField.value, cost: parseFloat(e.target.value)}}
                                  })
                                }}/></td>
                                <td>
                                <div className="flex gap-2 mb-2">
                                  {/* Valider l'édit */}
                                  <svg xmlns="http://www.w3.org/2000/svxmlg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-1 hover:text-rose-500 cursor-pointer" onClick={() => handleSubmitEdit('benefits', prestation.project_id)}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                  </svg>

                                  {/* Annuler l'édit */}
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 hover:text-rose-500 cursor-pointer" onClick={() => setEditFields(false)}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                  </svg>
                                </div>
                                </td>
                              </>
                              :
                              <>
                                <td> {prestation.label}</td>
                            <td>{(user.role.name === 'admin') ? parseFloat(prestation.cost).toFixed(2) + ' €' : ''}</td>
                            <td>
                              {/* Edit */}
                              <button
                                onClick={() => {
                                  setEditFields({active: true, id: prestation.id, type: 'benefits', value: {project_id: prestation.project_id, cost: prestation.cost, label: prestation.label} });
                                }}
                                className="cursor-pointer mr-1 outline-none focus:outline-none editer"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-4 h-4"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                  />
                                </svg>
                              </button>

                              {/* Supprimer */}
                              <button
                                className="cursor-pointer mr-1 outline-none focus:outline-none supprimer"
                                type="button"
                                onClick={() => {
                                  openModalAction({edit: false, delete: true, apiUrl: "prestations", id: prestation.id})
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-4 h-4"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                  />
                                </svg>
                              </button>

                            </td>
                              </>
                            }
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    {/* Table employés */}
                    <table className="w-full border-separate table-fixed">
                      <thead>
                        <tr>
                          <th className="uppercase w-3/12 pr-2">Employé</th>
                          <th className="uppercase w-1/12 pr-2">Heure</th>
                          {(user.role.name === 'admin') && (
                            <th className="uppercase w-1/12 pr-2">Coût</th>
                          )}
                          <th className="uppercase w-2/12 pr-2">Commentaire</th>
                          {(user.role.name === 'admin') && (
                          <th className="w-5/12 text-right text-xs uppercase font-normal"
                          onClick={() => {

                            const haveAddProject = (addFields) ? Object.keys(addFields).filter((field) => {
                              if(addFields[field])
                                return true
                              
                              return false
                            }) : false;

                            if(haveAddProject.length > 0){
                              handleSubmitFieldForm('time_employees', 0, haveAddProject[0], projet.id)
                            }else{
                            if(!addFields?.[projet.id]?.time_employees || addFields?.[projet.id]?.time_employees.length === 0)
                              handleAddField('time_employees', projet.id)
                            }
                          }}>
                            <div className="inline-flex item-center flex-end hover:text-rose-500 cursor-pointer">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-4 h-4 mr-1"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M12 6v12m6-6H6"
                                />
                              </svg>
                              Ajouter un employé
                            </div>
                          </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {(user.role.name === 'admin' && addFields?.[projet.id] ) && 
                          addFields[projet.id].time_employees
                          .map((field, i) => {
                            const employeeCost = parseFloat(addFields[projet.id].time_employees[i].cost_per_hour * addFields[projet.id].time_employees[i].employee_hour).toFixed(2)

                            return (
                            <tr key={`employee-input-${i}`}>
                              <td className="relative">
                                <input type="text" 
                                onKeyDown={handleKeyDown}
                                onChange={handleInputChange}
                                value={employeInputValue ? employeInputValue : ""}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-rose-500 focus:border-rose-500 block w-full p-1 mb-2 relative z-[1]" />
                                
                                {showEmployeesList && (
                                  <div className="absolute z-10 mt-2 w-full bg-white border rounded shadow">
                                    {employeesListFiltered
                                    // .filter((employee) => parseInt(employee.active) && employee.role.name != 'admin')
                                    .map((option) => (
                                      <div
                                        key={option.id}
                                        onClick={() => handleOptionClick(option)}
                                        className="px-4 py-2 cursor-pointer hover:bg-blue-100 text-gray-500"
                                      >
                                        {option.last_name} {option.first_name}
                                      </div>
                                    ))}
                                  </div>
                                )}

                              <input
                                type="hidden"
                                value={selectedEmployee ? selectedEmployee.id : field.employee_id ? field.employee_id : ""}
                                name="employee_id"
                                ref={hiddenEmployeeInputSelectRef}
                                data-projetid={projet.id}
                                data-index={i}
                              />
                              </td>
                              <td>
                                <input 
                                  type="number"
                                  min={1} 
                                  name="employee_hour" 
                                  value={field.employee_hour} 
                                  onChange={(e) => handleChange(projet.id, 'time_employees', i, 'employee_hour', e, (e) => {
                                    const {value} = e.target;
                                    const employePrixHeure = employes.filter((employe) => employe.id == addFields[projet.id].time_employees[i].employee_id)[0].hourly_price;
                                    
                                    return addFields[projet.id].time_employees[i].cost_per_hour = employePrixHeure
                                  })
                                  } 
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-rose-500 focus:border-rose-500 block w-full p-1 mb-2 text-sm"/>
                              </td>
                              <td>
                                {!isNaN(employeeCost) && `${employeeCost}€`}
                              </td>
                              <td>
                                <input type="text" name="comment" value={field.comment} onChange={(e) => handleChange(projet.id, 'time_employees', i, 'comment', e)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-rose-500 focus:border-rose-500 block w-full p-1 mb-2 text-sm"/>
                              </td>
                              <td>
                                <div className="flex gap-2 mb-2">

                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-1 hover:text-rose-500 cursor-pointer" onClick={() => handleSubmitFieldForm('time_employees', i, projet.id)}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                  </svg>

                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 hover:text-rose-500 cursor-pointer" onClick={() => handleRemoveField('time_employees', i, projet.id)}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                  </svg>
                                </div>
                              </td>
                            </tr>
                        )})}
                        {projet.time_employees?.sort((a,b) => {
                            if (a.employee.first_name < b.employee.first_name) {
                              return -1;
                            }
                            if (a.employee.first_name > b.employee.first_name) {
                              return 1;
                            }
                            return 0;
                          }).map((employe) => (
                          <tr key={employe.id}>
                            
                            {editFields && editFields?.active && editFields?.id === employe.id && editFields?.type === 'time_employees' && user.role.name === 'admin'
                              ? // Edit fields
                                <>
                                  <td className="relative">
                                    <input type="text" 
                                      onKeyDown={(e) => handleKeyDown(e, true)}
                                      onChange={(e) => handleInputChange(e, true)}
                                      value={employeEditInputValue ? employeEditInputValue : showEditEmployeesList ? "" : editFields.value.name}
                                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-rose-500 focus:border-rose-500 block w-full p-1 mb-2 relative z-[1]" />
                                      
                                    {showEditEmployeesList && (
                                        <div className="absolute z-10 mt-2 w-full bg-white border rounded shadow">
                                          {employeesEditListFiltered
                                          // .filter((employee) => parseInt(employee.active) && employee.role.name != 'admin')
                                          .map((option) => (
                                            <div
                                              key={option.id}
                                              onClick={() => handleOptionClick(option, true)}
                                              className="px-4 py-2 cursor-pointer hover:bg-blue-100 text-gray-500"
                                            >
                                              {option.last_name} {option.first_name}
                                            </div>
                                          ))}
                                        </div>
                                    )}

                                    <input
                                      type="hidden"
                                      value={selectedEditEmployee ? selectedEditEmployee.id : editFields.value.employee_id ? editFields.value.employee_id : ""}
                                      name="employee_id"
                                      ref={hiddenEditEmployeeInputSelectRef}
                                    />
                                  </td>
                                  <td>
                                    <input 
                                      type="number"
                                      min={1} 
                                      name="employee_hour" 
                                      value={editFields.value.employee_hour ? editFields.value.employee_hour : ''} 
                                      onChange={(e) => {
                                        setEditFields((editField) => {
                                          return {...editField, value: {...editField.value, employee_hour: e.target.value}}
                                        })}}
                                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-rose-500 focus:border-rose-500 block w-full p-1 mb-2 text-sm"/>
                                  </td>
                                  <td>
                                    {parseFloat(editFields.value.cost_per_hour * editFields.value.employee_hour).toFixed(2)}€
                                  </td>
                                  <td>
                                    <input type="text" name="comment" value={editFields.value.comment ? editFields.value.comment : ''} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-rose-500 focus:border-rose-500 block w-full p-1 mb-2 text-sm"
                                    onChange={(e) => {
                                      setEditFields((editField) => {
                                        return {...editField, value: {...editField.value, comment: e.target.value}}
                                      })}}
                                    />
                                  </td>
                                  <td>
                                    <div className="flex gap-2 mb-2">

                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-1 hover:text-rose-500 cursor-pointer" onClick={() => handleSubmitEdit('time_employees', employe.project_id)}
                                      >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                      </svg>

                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 hover:text-rose-500 cursor-pointer" onClick={() => setEditFields(false)}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                      </svg>
                                    </div>
                                  </td>
                                </>
                              :
                                <>
                                  <td>
                                    {employe.employee.first_name} {employe.employee.last_name}
                                  </td>
                                  <td>
                                    {parseFloat(employe.employee_hour)}h
                                    


                                    {!isNaN(employe.employee_hour) || (employe.employee_hour.toString().split(".")[1] * 60).toString().slice(0, 2) == 0
                                      ? ""
                                      : (employe.employee_hour.toString().split(".")[1] * 60)
                                          .toString()
                                          .slice(0, 2)}
                                  </td>
                                  <td>
                                    {(user.role.name === 'admin') ? parseFloat(employe.cost_per_hour * employe.employee_hour).toFixed(2) + ' €' : ''}
                                  </td>
                                  <td>{employe.comment}</td>
                                  {(user.role.name === 'admin') && (
                                  <td>
                                    {/* Edit */}
                                    <button
                                      onClick={() => {
                                        setEmployeEditInputValue('')
                                        getAllEmploye()
                                        if(editFields?.active)
                                          handleSubmitEdit('time_employees', employe.project_id, true)
                                          
                                        setEditFields({active: true, id: employe.id, type: 'time_employees', value: {project_id: employe.project_id, cost_per_hour: employe.cost_per_hour, employee_id: employe.employee_id, employee_hour: employe.employee_hour, comment: employe.commen, name: employe.employee.last_name + ' ' + employe.employee.first_name} });
                                      }}
                                      className="cursor-pointer mr-1 outline-none focus:outline-none editer"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-4 h-4"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                        />
                                      </svg>
                                    </button>
                                    {/* Supprimer */}
                                    <button
                                      className="cursor-pointer outline-none focus:outline-none  supprimer"
                                      type="button"
                                      onClick={() => {openModalAction({edit: false, delete: true, apiUrl: "temps", id: employe.id})}}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-4 h-4"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                        />
                                      </svg>
                                    </button>
                                  </td>
                                  )}
                                </>
                            }
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                );
              })}
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

export default RowProjet;