import React, { createContext, useState } from 'react';
import Notification from '../components/Notification';

const NotificationContext = createContext();

const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const addNotification = (message, type = 'info', duration = 3000) => {
    const notification = {
      id: Date.now(),
      message,
      duration,
      type
    };

    setNotifications((prevNotifications) => [
      ...prevNotifications,
      notification,
    ]);

    setTimeout(() => {
      removeNotification(notification.id);
    }, duration);
  };

  const removeNotification = (id) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((notification) => notification.id !== id)
    );
  };

  return (
    <NotificationContext.Provider
      value={{ notifications, addNotification, removeNotification }}
    >
      <div className="flex flex-col fixed top-0 right-0 z-[9999]">
        {notifications.map((notification) => (
          <Notification
            key={notification.id}
            message={notification.message}
            duration={notification.duration}
            type={notification.type}
            onClose={() => removeNotification(notification.id)}
          />
        ))}
      </div>
      
      {children}
    </NotificationContext.Provider>
  );
};

export { NotificationContext, NotificationProvider };
