import React, { useCallback, useContext, useEffect, useReducer, useRef, useState } from "react";
import Modal from "../../../components/Modal/Modal";
import ModalHeader from "../../../components/Modal/ModalHeader";
import ModalBody from "../../../components/Modal/ModalBody";
import Form from "../../../components/Form/Form";
import axiosClient from "../../../axios-client";
import { NotificationContext } from "../../../contexts/NotificationContext";
import { formatDate } from "../../../components/Utils/Dates";
import { slugify } from "../../../components/Utils/Slug";

const AddProjets = ({ showModal, setShowModal, displayClients }) => {
  const { addNotification } = useContext(NotificationContext);

  const [disableCustomerSelect, setDisableCustomerSelect] = useState(false);
  const [categories, setCategories] = useState([]);
  const [clients, setClients] = useState([]);
  const [employes, setEmployes] = useState([]);

  const [sellPriceROI, setSellPriceROI] = useState(0);
  const [benefitsPriceROI, setBenefitsPriceROI] = useState([]);
  const [employesPriceROI, setEmployesPriceROI] = useState([]);
  const [changedEmployes, setChangedEmpoyes] = useState(false);
  const [calculROI, setCalculROI] = useState(0);

  const [customerHiddenName, setCustomerHiddenName] = useState();

  // Ajoute les valeurs du span dans le setEmployesPriceROI
  const employeeCostRef =  useCallback(node => {
    const index = node?.dataset.employeeindex || false;
    const employeeCost = node?.dataset.employeecost || false;

    if(changedEmployes)
      setChangedEmpoyes(false)

    if(employeeCost && index)
      setEmployesPriceROI((oldPrice) => ({...oldPrice, [index]: employeeCost}))

  }, [changedEmployes]);

  

  const today = new Date();
  today.setDate(1);

  const getSelectFieldsData = async() => {
    await axiosClient.get("/categories").then((res) => {
      setCategories(res.data.categories.map(category => ({
        label: category.label,
        value: category.id,
      })))
    });

    await axiosClient.get("/clients").then((res) => {
      setClients(res.data.clients.map(client => ({
        label: client.label.toUpperCase(),
        value: client.id,
      })))
    });

    await axiosClient.get("/employes").then((res) => {
      const employeesActive = res.data.employes.filter((employee) => employee.active);

      setEmployes(employeesActive.map(employe => {
        return {label: `${employe.first_name} ${employe.last_name}`, value: employe.id, cost: parseFloat(employe.hourly_price)};
      }));
    });
  };

  /** FORMULAIRE */
  const handleAfterSubmitForm = ({success, message, data, debug}) => {
    if(!success) addNotification({message: message.errorMsg, list: message.errorList}, 'danger')

    if(success){
      addNotification(message, 'success')
      setShowModal(false)
      displayClients();
    }
  };

  const initialValues = {
    project_date: formatDate(today, 'yyyy-mm-dd'),
    monthly: false,
    forecast: false,
    name_customer: customerHiddenName ? customerHiddenName : undefined
  }

  // Détermine les champs du formulaire
  const fields = [
    { group: [
      { name: "label", label: "Nom du projet" },
      { name: "sell_price", label: "Prix de vente", min: 0, type: "number", onChange: ({value}) => setSellPriceROI(parseFloat(value, 2))},
      { name: "project_date", label: "Date", type: "date"},
      {
        name: "category_id",
        label: "Catégorie",
        type: "select",
        options: categories,
      },
    ], groupClassName: "grid gap-4 sm:grid-cols-2 mt-0 mb-6"},
    { group: [
      {name: 'monthly', label: 'Projet mensuel', type: 'toggle'},
      {name: 'forecast', label: 'Projet prévisionnel', type: 'toggle'},
      {name: 'recurrence', label: 'Récurrence du projet', type: 'number'},
    ]},

    { group: [
      {
        name: "customer_id",
        label: "Client",
        type: "select",
        options: clients,
        width: "full",
        disabled: disableCustomerSelect
      },
      // Ajouter un client
      { name: "add_customer", label: "Ajouter un client", type: "toggle", width: "full", customClass: 'flex -mt-2', onChange: ({checked}) => {setDisableCustomerSelect(checked)}},
      { name: "name_customer", type: 'hidden',  dependentOn: "add_customer", customClass: 'hidden' },
      { name: "label_customer", label: "Nom du client", dependentOn: "add_customer", customClass: "flex-1 mb-4", onChange: ({value}) => {
        setCustomerHiddenName(slugify(value));
      } },
    ], groupClassName: "pb-2 border-b border-gray-200 flex flex-wrap gap-4"},

    // Ajouter des prestations
    {name: 'add_benefit', label: 'Ajouter une charge', type: 'repeater', inputs: [
      { name: 'label_benefit', label: 'Libelle', isMultiple: true, containerClass: 'w-1/2'},
      { name: 'cost_benefit', label: 'Coût', type: 'number', min: 0, isMultiple: true, containerClass: 'w-1/2', onChange: (({value, isMultiple}) => setBenefitsPriceROI((oldPrice) => ({...oldPrice, [isMultiple]: value})))},
    ], handleDeleteField: ((field) => setBenefitsPriceROI((oldPrice) => ({...oldPrice, [field]: 0}))), className: "pb-2 border-b border-gray-200 !mt-2"},

    // Ajouter des employés
    {name: 'add_employee', label: 'Ajouter un employé', type: 'repeater', inputs: [
      { name: 'employee_id', label: 'Employé', isMultiple: true, type: 'autocompletion', containerClass: 'w-1/2', options: employes, onChange: () => setChangedEmpoyes(true)},
      { name: 'employee_hour', label: 'Heure',  min: 0, type: 'number', isMultiple: true, containerClass: 'w-1/4', onChange: () => setChangedEmpoyes(true)},

      { type: 'dynamic', value: (formData, i) => {
        // Génère le coût par employé lorsque l'on sélectionne
        if(formData.employee_id && formData.employee_hour){
          const employeeHourlyCost = employes.filter(employee => employee.value === parseInt(formData.employee_id[i]));
          const employeeCost = employeeHourlyCost[0]?.cost * formData.employee_hour[i];

          if(employeeCost && employeeCost > 0){
            return (
              <span className="w-1/5 mt-8" key={`${formData.employee_id}-employee-cost`} ref={employeeCostRef} data-employeecost={employeeCost} data-employeeindex={i}>{parseFloat(employeeCost).toFixed(2)} €</span>
            )
          }
        }
      }},
      { name: 'comment', label: 'Commentaire', isMultiple: true, containerClass: 'w-1/2'},
    ], handleDeleteField: ((field) => setEmployesPriceROI((oldPrice) => ({...oldPrice, [field - 1]: 0}))), className: "pb-2 border-b border-gray-200 !mt-2"},
  ];

  // Initialise la liste des catégories, clients et employés
  useEffect(() => {
    getSelectFieldsData();
  }, [])

  useEffect(() => {
    // Calcul du ROI du projet lorsque l'on change les champs nécessaire
    const sumCostEmployees = Object.values(employesPriceROI).reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    const sumCostBenefits = Object.values(benefitsPriceROI).reduce((a, b) => parseFloat(a) + parseFloat(b), 0);

    var projectROI = ((sumCostEmployees + sumCostBenefits) / sellPriceROI) * 100;
    if(isNaN(projectROI))
      projectROI = 0;

    setCalculROI(parseFloat(projectROI).toFixed(2))
      
  }, [sellPriceROI, benefitsPriceROI, employesPriceROI])

  return (
    <Modal show={showModal} onClose={() => setShowModal(false)}>
      <ModalHeader
        title={<span>Ajouter un projet - ROI: <span className={calculROI <= 37.0 ? "text-green-500" : "text-red-500"}>{calculROI}%</span> </span>}
        modalHeaderContainerClass="p-0 pb-4 z-10"
      />

      <ModalBody modalBodyContainerclassName="px-0 pt-4 pb-0">
      <Form
        method="post"
        apiUrl="projets"
        fields={fields}
        initialValues={initialValues}
        onSubmit={handleAfterSubmitForm}
      />
      </ModalBody>
    </Modal>
  );
};

export default AddProjets;
