import React, { useState } from "react";

const Tableau = ({listeClients, listeDonnees, listeEmployes, afficherTableauGlobal, filtersDate}) => {

    // Modification du nombre d'heure moyen
    const [nombreHeureMoyen, setNombreHeureMoyen] = useState(124);
    const handleChangeNombreHeureMoyen = async (event) => {
        const { value } = event.target;
        if(value){
            setNombreHeureMoyen(value);
        }else{
            setNombreHeureMoyen(124);
        }
    }

    // Dates titre
    const formatDateTitle = (date) => {
        const titreDate = new Date(date);
        const month = titreDate.getMonth() + 1;
        const year = titreDate.getFullYear();
        const monthLabel = month < 10 ? "0" + month : month
        
        return `${monthLabel}/${year}`
    }
  return (
    <div>
        <div className="mb-4 flex">
            <span>Nombre d'heure moyen :</span>
            <input type="number" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-rose-500 focus:border-rose-500 block px-2.5 ml-2" min={1} max={300} value={nombreHeureMoyen} onChange={handleChangeNombreHeureMoyen} />
            <span>h</span>
        </div>

        {afficherTableauGlobal
            ? <h2 className="font-bold text-darkblue-700 text-2xl mb-4">Tableau Global du {formatDateTitle(filtersDate.debut)} au {formatDateTitle(filtersDate.fin)}</h2>
            : <h2 className="font-bold text-darkblue-700 text-2xl mb-4">Tableau du {formatDateTitle(filtersDate.debut)} au {formatDateTitle(filtersDate.fin)}</h2>}
        
        
        <div className="relative shadow-sm sm:rounded-lg z-0" id="tableManagement-wrapper">

            {/* Tableau par mois */}
            {!afficherTableauGlobal &&
                <table className="w-full text-left text-gray-500 relative text-center" id="tableManagement">
                    <thead className="text-xs uppercase montserrat select-none sticky-row z-10">
                        {/* Première ligne du tableau (Mois) */}
                        <tr className="white-border">
                            <th className="bg-rose-700 p-2 text-white font-bold sticky-col" colSpan={1}></th>
                            {listeDonnees.map((mois, i) => (
                                <th className={`bg-rose-700 p-2 text-white font-bold`} key={`${mois.id}_${i}`} colSpan={mois.employes.length}>{mois.nom}</th>
                            ))}
                        </tr>
                        {/* Ligne titre "liste des employés" */}
                        <tr className="white-border">
                            <th className="bg-rose-700 p-2 text-white font-bold sticky-col" colSpan={1}>Liste des clients</th>
                            {listeDonnees.map((mois, i) => (
                                <th className={`bg-rose-700 p-2 text-white font-bold`} key={`${mois.id}_${i}`} colSpan={mois.employes.length}>Liste des employés</th>
                            ))}
                        </tr>
                        
                        {/* Ligne liste des employés */}
                        <tr>
                            <th className="bg-[#E6E7E8] p-2 sticky-col" colSpan={1}></th>
                            {listeDonnees.map((mois, mI) => {
                                return mois.employes.map((employe, i) => {
                                    // Ajoute une bordure en fonction de l'emplacement de la case (permet de faire les séparation entre chaque mois)
                                    const cellBorderClass = mois.employes.length === (i + 1) ? '!border-r-4 !border-[#d9d9d9]' : (i === 0 && mI > 0 ? '!border-l-4 border-[#d9d9d9]' : '');
                                    return (
                                    <th className={`bg-[#E6E7E8] p-2 font-bold ${cellBorderClass}`} key={`${mois}_${employe.id}`}>{employe.first_name} {employe.last_name}</th>
                                )})
                            })}
                        </tr>
                    </thead>
                    <tbody className="montserrat font-normal text-sm relative ">
                        {/* Ligne total des heures */}
                        <tr className="hover:bg-darkblue-700 hover:text-white hover-line">
                            <td className="bg-[#E6E7E8] p-2 sticky-col">Total des heures</td>
                            {listeDonnees.map((mois, mI) => {
                                return mois.employes.map((employe, i) => {
                                    // Ajoute une bordure en fonction de l'emplacement de la case (permet de faire les séparation entre chaque mois)
                                    const cellBorderClass = mois.employes.length === (i + 1) ? '!border-r-4 !border-[#d9d9d9]' : (i === 0 && mI > 0 ? '!border-l-4 !border-[#d9d9d9]' : '');
                                    return (
                                        <td className={`bg-[#E6E7E8] p-2 font-normal text-black ${cellBorderClass}`} key={`${mois}_${employe.id}_totalHeures`}>{employe.totalHeures}</td>
                                    )
                                })
                            })}
                        </tr>

                        {/* Ligne taux d'occupation */}
                        <tr className="hover:bg-darkblue-700 hover:text-white hover-line">
                            <td className="bg-[#E6E7E8] p-2 sticky-col">Taux d'occupation</td>
                            {listeDonnees.map((mois, mI) => {
                                return mois.employes.map((employe, i) => {
                                    // Ajoute une bordure en fonction de l'emplacement de la case (permet de faire les séparation entre chaque mois)
                                    const cellBorderClass = mois.employes.length === (i + 1) ? '!border-r-4 !border-[#d9d9d9]' : (i === 0 && mI > 0 ? '!border-l-4 !border-[#d9d9d9]' : '');

                                    const tauxOccupation = employe.totalHeures / nombreHeureMoyen * 100;
                                    // Définis la couleur en fonction du taux d'occupation
                                    const tauxLevel = (tauxOccupation < 50) ? 'success' : (tauxOccupation >= 50 && tauxOccupation < 100) ? 'warning' : 'danger';
                                    const tauxDecimal = tauxOccupation.toFixed(2)
                                    
                                    return (
                                        (mois.empty)
                                            ? <td className={`bg-[#E6E7E8] p-2 font-normal success ${cellBorderClass}`} key={`${mois.id}_${employe.id}_tauxOccupation`}>0%</td>
                                            : <td className={`bg-[#E6E7E8] p-2 font-normal ${tauxLevel} ${cellBorderClass}`} key={`${mois.id}_${employe.id}_tauxOccupation`}>{(tauxDecimal > 0) ? tauxDecimal : 0}%</td>
                                    )
                                })
                            })}
                        </tr>

                        {/* Lignes pour chaque clients */}
                        {listeClients.map((client) => 
                            <tr key={client.id} className="hover:bg-darkblue-700 hover:text-white hover-line">
                                <td className="p-2 sticky-col bg-[#f9fafb] uppercase">{client.label}</td>
                                {listeDonnees.map((mois, mI) => {
                                    return mois.employes.map((employe, i) => {
                                        // Ajoute une bordure en fonction de l'emplacement de la case (permet de faire les séparation entre chaque mois)
                                        const cellBorderClass = mois.employes.length === (i + 1) ? '!border-r-4 !border-[#d9d9d9]' : (i === 0 && mI > 0 ? '!border-l-4 !border-[#d9d9d9]' : '');

                                        // Recherche toutes les heures du produits
                                        const haveEmployeHeures = mois.employes.filter((employe2, i) => {
                                            if(employe2.totalHeuresParClient){
                                                let filtreHeure = employe2.totalHeuresParClient.filter((heureClient) => {
                                                    return heureClient.id == client.id
                                                })
                                                return filtreHeure.length > 0 ? true : false
                                            }
                                            return false
                                        })

                                        // Si le client n'a pas de projet avec des temps d'employes
                                        if(haveEmployeHeures.length == 0) return <td className={`p-2 projet-vide`} key={`${mois.id}_${employe.id}_${client.id}`}>Vide</td>

                                        const clientHeure = employe.totalHeuresParClient?.filter((clientHeure) => clientHeure.id === client.id);
                                        return (clientHeure && clientHeure.length > 0) 
                                                // Si il y'a des employés pour le projet
                                                ? <td className={`p-2 ${cellBorderClass}`} key={`${mois.id}_${employe.id}_${client.id}`}>{clientHeure[0].totalHeures}</td>
                                                : <td className={`p-2 ${cellBorderClass} client-vide`} key={`${mois.id}_${employe.id}_${client.id}`}>0</td>
                                                  
                                    })
                                })}
                            </tr>
                        )}
                    </tbody>
                </table>
            }

            {/* Tableau global */}
            {afficherTableauGlobal &&
                <table className="w-full text-left text-gray-500 relative text-center" id="tableManagement">
                    <thead className="text-xs uppercase montserrat select-none sticky-row z-10">
                        <tr className="white-border">
                            <th className="bg-rose-700 p-2 text-white font-bold" colSpan={1}>Liste des employés</th>
                            <th className="bg-rose-700 p-2 text-white font-bold" colSpan={1}>Total des heures</th>
                            <th className="bg-rose-700 p-2 text-white font-bold" colSpan={1}>Taux d'occupation</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listeEmployes && listeEmployes.map((employe) => {
                            const tauxOccupation = employe.totalHeures / (nombreHeureMoyen * listeDonnees.length) * 100;
                            // Définis la couleur en fonction du taux d'occupation
                            const tauxLevel = (tauxOccupation < 50) ? 'success' : (tauxOccupation >= 50 && tauxOccupation < 100) ? 'warning' : 'danger';
                            const tauxDecimal = tauxOccupation.toFixed(2)

                            return (<tr key={employe.id}>
                                <td>{employe.first_name} {employe.last_name}</td>
                                <td>{employe.totalHeures}</td>
                                <td className={`bg-[#E6E7E8] p-2 font-normal ${tauxLevel}`}>{(tauxDecimal > 0) ? tauxDecimal : 0}%</td>
                            </tr>
                        )})}
                    </tbody>
                </table>
            }
        </div>
    </div>
  );
};

export default Tableau;
